import React, { useEffect, useState } from 'react';
import Modal from '../modal';
import styles from './share-modal.module.scss';
import { Input } from '../input';
import colors from '../../style-utils/colors.module.scss';

export const ShareModal = props => {
  const [open, setOpen] = useState(props.open);
  const [coppiedText, setCoppiedText] = useState('');

  let embedURL = '';
  if (props.url) {
    embedURL = `<iframe seamless width="800" height="600" style="width: 100%; height: 600px; border: 1px solid ${
      colors.grey
    };" src="${props.url.replace('/share/', '/embed/')}"></iframe>`;
  }

  useEffect(
    () => {
      setOpen(props.open);
    },
    [props.open],
  );

  const onCopy = text => {
    setCoppiedText(text);
  };

  const onClose = () => {
    setCoppiedText('');
    props.onClose && props.onClose();
  };

  return (
    <Modal open={open} title={`Share ${props.title}`} width={500} onClose={onClose}>
      <div className={styles.container}>
        <Input
          defaultValue={props.url}
          className={styles.input}
          label={`Share link to ${props.type}`}
          onShareText={onCopy}
          shareText={props.text}
          shareTitle={props.title}
          coppied={coppiedText === props.url}
        />
        <Input
          defaultValue={embedURL}
          className={styles.input}
          label={`Embed ${props.type} on website`}
          onCopyText={onCopy}
          coppied={coppiedText === embedURL}
        />
      </div>
    </Modal>
  );
};

ShareModal.defaultProps = {
  title: 'Share',
  type: 'ride',
};

export default ShareModal;
