import React, { useEffect, useRef, useState } from 'react';
import styles from './hourly-wind.module.scss';
import { format } from 'date-fns';
import {
  parseDarkSkyTimestamp,
  convertToUserPref,
  userPrefUnit,
  getRideHardness,
  getHardnessColor,
  getWindHardnessColor,
} from '../../utils';
import WindArrow from '../wind-arrow';
import { SETTINGS } from '../../config';
import classnames from 'classnames';
import colors from '../../style-utils/colors.module.scss';

const HourlyWind = props => {
  const getActiveHour = time => {
    if (time) {
      return format(time, 'HH:00');
    } else {
      return props.ride ? format(props.ride.start_date, 'HH:00') : '12:00';
    }
  };

  const container = useRef(null);
  const [activeHour, setActiveHour] = useState(null);
  const [weather, setWeather] = useState([]);

  useEffect(
    () => {
      if (!props.weather) {
        setWeather(Array(24).fill(0));
      } else {
        setWeather(props.weather.data.slice(0, 24));
        props.showActive !== false && setActiveHour(getActiveHour(props.time));
      }
    },
    [props.time, props.ride, props.weather],
  );

  useEffect(
    () => {
      scrollToActive();
    },
    [activeHour],
  );

  const scrollToActive = () => {
    const active = document.querySelector(`.${styles.active}`);
    if (active && container.current) {
      const index = [...active.parentElement.children].indexOf(active);
      const width = active.clientWidth;
      const center = index * width - container.current.clientWidth / 2 + width / 2;
      container.current.scrollTo({
        left: center,
        behavior: 'smooth',
      });
    }
  };

  const onClick = (time, windSpeed) => {
    props.showActive !== false && setActiveHour(getActiveHour(time));
    props.onClick && props.onClick(time, windSpeed);
  };

  const containerClass = classnames(styles.hourly, {
    [props.className]: props.className,
  });

  return (
    <div className={containerClass} ref={container}>
      {weather.map((hour, i) => {
        let hardness = 0;
        let color = colors.darkGrey;
        let time = i;
        let formattedTime = '00:00';
        let windBearing = 0;
        let windSpeed = 0;

        if (hour) {
          hardness = props.ride ? getRideHardness(props.ride, hour) : false;
          color = props.ride
            ? getHardnessColor(hardness, 1, 0, SETTINGS.hardness.outof)
            : getWindHardnessColor(hour.windSpeed);
          time = parseDarkSkyTimestamp(hour.time);
          formattedTime = format(time, 'HH:mm');
          windBearing = hour.windBearing;
          windSpeed = hour.windSpeed;
        }

        const classNames = classnames(styles.item, {
          [styles.clickable]: props.onClick,
          [styles.active]: formattedTime === activeHour,
        });

        return (
          <div className={classNames} key={time} onClick={onClick.bind(this, time, hour.windSpeed)}>
            <div className={styles.time}>{formattedTime}</div>
            <WindArrow dir={windBearing} color={color} />
            <div className={styles.speed}>
              {convertToUserPref(windSpeed, 'distance', props.unitPrefs)} {userPrefUnit('speed', props.unitPrefs)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HourlyWind;
