import React, { Component } from 'react';
import styles from './dognut.module.scss';
import colors from '../../style-utils/colors.module.scss';
import { Doughnut } from 'react-chartjs-2';
import CountUp from 'react-countup';
import { calculateWindCalories } from '../../utils';
import classnames from 'classnames';
import store from '../../store';

class DognutChart extends Component {
  constructor(props) {
    super(props);
    this.size = 130;
    const calories = props.calories || 0;
    const windCalories = calculateWindCalories(props.calories || 0, props.hardness);
    const state = store.getState();
    this.data = this.getData(calories, windCalories);
    this.state = {
      calories,
      windCalories,
      ready: false,
      data: this.getData(100, 0),
      layout: state.global.layout,
    };
    this.options = {
      cutoutPercentage: 80,
      maintainAspectRatio: false,
      responsive: false,
      legend: false,
    };
    this.mounted = null;
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.ready) {
      setTimeout(() => {
        if (this.mounted) {
          this.update();
          this.setState({ ready: true });
        }
      }, 1000);
    }
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.global.layout) {
        this.setState({
          layout: state.global.layout,
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hardness !== this.props.hardness) {
      this.update();
    }
    if (prevProps.calories !== this.props.calories) {
      this.update();
    }
    if (this.props.ready && !this.state.ready) {
      this.setState({ ready: true, data: this.data });
    }
  }

  update = () => {
    const windCalories = calculateWindCalories(this.props.calories || 0, this.props.hardness);
    this.data = this.getData(this.props.calories, windCalories);
    this.setState({
      calories: this.props.calories,
      windCalories,
      data: this.data,
      ready: false,
    });
  };

  getData = (calories, windCalories) => {
    return {
      datasets: [
        {
          data: [calories === 0 ? 100 : calories, calories === 0 ? 0 : windCalories],
          backgroundColor: [colors.grey, colors.secondary],
          borderColor: [colors.dark, colors.dark],
        },
      ],
      labels: ['calories', 'wind calories'],
    };
  };

  render() {
    const baseValueClass = classnames(styles.baseValue, {
      [styles.smallValue]: this.state.calories >= 1000,
    });
    const windValueClass = classnames(styles.windValue, {
      [styles.smallValue]: this.state.windCalories >= 1000,
    });
    const chartNumberClass = classnames(styles.value, {
      [styles.smallValue]: this.state.windCalories >= 1000,
    });
    return (
      <>
        {this.state.layout === 'mobile' && <h3 className={styles.infoTitle}>Calories Burned</h3>}
        <div className={styles.container}>
          <div className={styles.info}>
            <table>
              <tbody>
                <tr>
                  <td>
                    {this.state.layout === 'mobile' && (
                      <span className={styles.infoLabel}>Before accounting for the wind speed</span>
                    )}
                    {this.state.layout === 'desktop' && (
                      <span className={styles.infoLabel}>
                        Calories burned before taking the wind speed into account
                      </span>
                    )}
                  </td>
                  <td>
                    <CountUp
                      className={baseValueClass}
                      end={this.state.ready ? this.state.calories : 0}
                      duration={1}
                      delay={1}
                      decimals={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {this.state.layout === 'mobile' && (
                      <span className={styles.infoLabel}>After accounting for the wind speed</span>
                    )}
                    {this.state.layout === 'desktop' && (
                      <span className={styles.infoLabel}>Calories burned after taking the wind speed into account</span>
                    )}
                  </td>
                  <td>
                    <CountUp
                      className={windValueClass}
                      end={this.state.ready ? this.state.windCalories : 0}
                      duration={1}
                      delay={1}
                      decimals={0}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.chartHolder}>
            <div className={styles.chart}>
              <div className={styles.chartNumber}>
                <span className={styles.label}>Wind Calories</span>
                <CountUp
                  className={chartNumberClass}
                  end={this.state.ready ? this.state.windCalories : 0}
                  duration={1}
                  delay={1}
                  decimals={0}
                />
              </div>
              <Doughnut
                data={this.state.data}
                options={this.options}
                width={this.size}
                height={this.size}
                preserveValue={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DognutChart;
