import React, { Component } from 'react';
import store from '../../store';
import { setTitle } from '../../components/header/actions';
import { setLoading } from '../../store/actions';
import * as Message from '../../components/message';
import { InfoView } from '../info';
import { Link } from 'react-router-dom';

export class FourZeroFourView extends Component {
  componentDidMount() {
    store.dispatch(setTitle('HEADWIND'));
    store.dispatch(
      Message.add({
        type: 'error',
        text: '404 Error: Page not found',
        id: '404',
      }),
    );
    setTimeout(() => setLoading(false));
  }

  render() {
    return (
      <InfoView title="Page not found">
        <p>Sorry there appears to have been a problem.</p>
        <p>
          Maybe the wind blew you in the wrong direction, or maybe this bit of the app is broken. Either way returning
          to the{' '}
          <Link to="/" onClick={this.dismissError}>
            homepage
          </Link>{' '}
          is the best course of action.
        </p>
      </InfoView>
    );
  }
}
