import { extend, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import { WebGLRenderTarget } from 'three';
import { SavePass } from 'three/examples/jsm/postprocessing/SavePass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';

extend({
  EffectComposer,
  SavePass,
  AfterimagePass,
  RenderPass,
});

export function Effects({ trailLength = 1 }) {
  const composer = useRef();
  const savePass = useRef();
  const afterImagePass = useRef();
  const { scene, gl, size, camera } = useThree();
  const target = useMemo(() => new WebGLRenderTarget(size.width, size.height), [size]);
  useEffect(() => void composer.current.setSize(size.width, size.height), [size]);
  useFrame(() => {
    savePass.current.renderTarget = target;
    if (afterImagePass.current) {
      afterImagePass.current.uniforms['damp'].value = trailLength;
    }
    composer.current.render();
  }, 1);
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <savePass attachArray="passes" ref={savePass} />
      <afterimagePass attachArray="passes" ref={afterImagePass} />
    </effectComposer>
  );
}
