import React, { Component } from 'react';
import store from '../../store';
import { setTitle } from '../../components/header/actions';
import { setLoading } from '../../store/actions';
import styles from './settings.module.scss';
import Button from '../../components/button';
import { SETTINGS } from '../../config';
import * as Message from '../../components/message';
import { withRouter } from 'react-router-dom';
import { IoMdInformationCircle } from 'react-icons/io';
import { Select } from '../../components/select';
import { Input } from '../../components/input';
import { Checkbox } from '../../components/checkbox';
import * as strava from '../../api/strava';
import format from 'date-fns/format';
import { hasUserSettings } from '../../utils';
import { stopIOSOverscroll } from '../../utils/ios-overscroll';
import merge from 'lodash.merge';

export class Settings extends Component {
  hasSettings = hasUserSettings();
  distanceOptions = [
    { label: 'Kilometers', value: 'kilometers' },
    { label: 'Miles', value: 'miles' },
  ];
  temperatureOptions = [
    { label: 'Celsius', value: 'celsius' },
    { label: 'fahrenheit', value: 'fahrenheit' },
  ];
  rainfallOptions = [
    { label: 'Millimeters', value: 'millimeters' },
    { label: 'Inches', value: 'inches' },
  ];
  stopIOSOverscroll = new stopIOSOverscroll();
  mounted = false;

  constructor(props) {
    super(props);
    const state = store.getState();

    let settings = SETTINGS.defaultUserSettings;
    if (state.strava.user.headwind) {
      settings = merge(settings, state.strava.user.headwind);
    }

    this.state = {
      settings,
      loading: false,
    };
  }

  componentDidMount() {
    store.dispatch(setTitle('Settings'));
    strava.getUser().then(user => {
      if (this.mounted) {
        const settings = merge(this.state.settings, user.headwind);
        setLoading(false);
        this.setState({
          settings,
        });
      }
    });
    this.mounted = true;
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const settings = merge(this.state.settings, state.strava.user.headwind);
      this.setState({
        settings,
      });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  render() {
    const { unitPrefs, commute, email, notifications } = this.state.settings;

    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <h2 className={styles.title}>Settings</h2>
          <div className={styles.content}>
            {!this.hasSettings && (
              <p className={styles.info}>
                <IoMdInformationCircle />
                Please choose your default settings. These can be changed anytime by visiting the settings screen from
                the main menu.
              </p>
            )}

            <h3 className={styles.sectionTitle}>Units of measurement</h3>

            <div className={styles.setting}>
              <Select
                label="Distance"
                name="distance"
                options={this.distanceOptions}
                value={{
                  label: unitPrefs.distance ? unitPrefs.distance : this.distanceOptions[0].label,
                  value: unitPrefs.distance ? unitPrefs.distance : this.distanceOptions[0].value,
                }}
                onChange={this.updateUnitPrefs}
              />
            </div>

            <div className={styles.setting}>
              <Select
                label="Temperature"
                name="temperature"
                options={this.temperatureOptions}
                value={{
                  label: unitPrefs.temperature ? unitPrefs.temperature : this.temperatureOptions[0].label,
                  value: unitPrefs.temperature ? unitPrefs.temperature : this.temperatureOptions[0].value,
                }}
                onChange={this.updateUnitPrefs}
              />
            </div>

            <div className={styles.setting}>
              <Select
                label="Rainfall"
                name="rainfall"
                options={this.rainfallOptions}
                value={{
                  label: unitPrefs.rainfall ? unitPrefs.rainfall : this.rainfallOptions[0].label,
                  value: unitPrefs.rainfall ? unitPrefs.rainfall : this.rainfallOptions[0].value,
                }}
                onChange={this.updateUnitPrefs}
              />
            </div>

            <h3 className={styles.sectionTitle}>Notifications</h3>

            <div className={styles.setting}>
              <Checkbox
                light
                checked={notifications}
                name="notifications"
                label="Receive weekly weather updates about your starred rides:"
                onChange={this.updateNotifications}
              />
            </div>

            <div className={styles.setting}>
              <Input
                label="Email"
                defaultValue={email || ''}
                name="email"
                placeholder="Email"
                type="email"
                onChange={this.updateEmail}
              />
            </div>

            <h3 className={styles.sectionTitle}>Approx commute times</h3>

            <div className={styles.setting}>
              <Input
                label="Morning commute time"
                value={commute.morning || ''}
                name="commute_morning"
                placeholder="Morning commute time"
                type="text"
                onPickTime={this.updateMorningCommute}
                timePicker={true}
                onOpenTimePicker={this.onOpenTimePicker}
                onCloseTimePicker={this.onCloseTimePicker}
              />
            </div>

            <div className={styles.setting}>
              <Input
                label="Afternoon commute time"
                value={commute.afternoon || ''}
                name="commute_afternoon"
                placeholder="Afternoon commute time"
                type="text"
                onPickTime={this.updateAfternoonCommute}
                timePicker={true}
                onOpenTimePicker={this.onOpenTimePicker}
                onCloseTimePicker={this.onCloseTimePicker}
              />
            </div>

            <Button primary full onClick={this.saveSettings} loading={this.state.loading}>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    );
  }

  onOpenTimePicker = () => {
    this.stopIOSOverscroll.enable();
  };

  onCloseTimePicker = () => {
    this.stopIOSOverscroll.disable();
  };

  updateUnitPrefs = value => {
    const settings = { ...this.state.settings };
    const units = { ...this.state.settings.unitPrefs };
    units[value.name] = value.value;
    settings.unitPrefs = units;
    if (value.name === 'distance') {
      if (value.value === 'miles') {
        units.speed = 'MPH';
      } else {
        units.speed = 'KPH';
      }
    }
    this.setState({ settings });
  };

  updateEmail = e => {
    const settings = { ...this.state.settings };
    settings.email = e.target.value;
    this.setState({ settings });
  };

  updateMorningCommute = time => {
    this.stopIOSOverscroll.disable();
    const settings = { ...this.state.settings };
    if (typeof settings.commute) settings.commute.morning = format(time, 'HH:mm');
    this.setState({ settings });
  };

  updateAfternoonCommute = time => {
    this.stopIOSOverscroll.disable();
    const settings = { ...this.state.settings };
    settings.commute.afternoon = format(time, 'HH:mm');
    this.setState({ settings });
  };

  updateNotifications = checked => {
    const settings = { ...this.state.settings };
    settings.notifications = checked;
    this.setState({ settings });
  };

  saveSettings = () => {
    this.setState({
      loading: true,
    });
    strava
      .updateUserSettings(this.state.settings)
      .then(() => {
        store.dispatch(
          Message.add({
            type: 'success',
            text: 'Settings updated successfully.',
          }),
        );
        this.props.history.push('/');
      })
      .catch(error => {
        this.setState({ loading: false });
        // @NOTE dont need to catch error and display here as its handled by the API call.
        // store.dispatch(
        //   Message.add({
        //     type: 'error',
        //     text: error,
        //   }),
        // );
      });
  };
}

export const SettingsView = withRouter(Settings);
