import MobileDetect from 'mobile-detect';
import store from '../store';

export class stopIOSOverscroll {
  md = new MobileDetect(window.navigator.userAgent);
  ios = this.md.phone() === 'iPhone' || this.md.tablet() === 'iPad';
  mobile = store.getState().global.layout === 'mobile';

  enable = () => {
    if (this.ios && this.mobile) {
      this.disable();
      window.addEventListener('touchmove', this.onTouchmove, {
        passive: false,
      });
    }
  };

  disable = () => {
    if (this.ios && this.mobile) {
      window.removeEventListener('touchmove', this.onTouchmove);
    }
  };

  onTouchmove = e => {
    if (this.ios && this.mobile && e.target) {
      if (e.target.closest('.iosScrollable') || e.target.closest('.react-datepicker')) {
        e.stopPropagation();
      } else {
        e.preventDefault();
      }
    }
  };
}
