import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import posed from 'react-pose';
import { FiX } from 'react-icons/fi';
import styles from './modal.module.scss';
import classnames from 'classnames';
import { SETTINGS } from '../../config';
import { onModalOpen, onModalClose } from '../../utils';

const modalSpeed = SETTINGS.modalSpeed;

const StyledOverlay = posed.div({
  overlayOpen: {
    opacity: 1,
    applyAtStart: {
      display: 'block',
    },
    transition: {
      duration: modalSpeed,
    },
  },
  overlayClosed: {
    opacity: 0,
    applyAtEnd: {
      display: 'none',
    },
    transition: {
      duration: modalSpeed,
    },
  },
});

const StyledModal = posed.div({
  modalOpen: {
    opacity: 1,
    y: '-50%',
    x: '-50%',
    applyAtStart: {
      display: 'flex',
    },
    transition: {
      duration: modalSpeed,
    },
  },
  modalClosed: {
    opacity: 0,
    y: '100px',
    x: '-50%',
    applyAtEnd: {
      display: 'none',
    },
    transition: {
      duration: modalSpeed,
    },
  },
});

const Modal = props => {
  const [open, setOpen] = useState(props.open || false);
  const [everOpen, setEverOpen] = useState(props.open || false);
  const [fullyOpen, setFullyOpen] = useState(false);
  const [fullyClosed, setFullyClosed] = useState(!props.open);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  useEffect(
    () => {
      if (props.open) {
        openModal();
      } else {
        closeModal();
      }
    },
    [props.open],
  );

  useEffect(
    () => {
      if (everOpen) {
        setTimeout(() => {
          onAnimationEnd();
        }, modalSpeed);
      }
    },
    [open],
  );

  const openModal = () => {
    props.onOpen && props.onOpen();
    setOpen(true);
    setFullyClosed(false);
    setEverOpen(true);
    onModalOpen();
  };

  const closeModal = () => {
    if (fullyOpen) {
      props.onClose && props.onClose();
      setOpen(false);
      setFullyOpen(false);
      onModalClose();
    }
  };

  const onAnimationEnd = () => {
    if (open) {
      setTimeout(() => {
        mountedRef.current && setFullyOpen(true);
      }, 200);
    } else {
      setTimeout(() => {
        mountedRef.current && setFullyClosed(true);
      }, 200);
    }
  };

  const classNames = classnames(styles.modal, {
    [props.className]: props.className,
  });
  const overlayClassNames = classnames(styles.overlay, {
    [props.overlayClassName]: props.overlayClassName,
  });

  return ReactDOM.createPortal(
    <div className={`${styles.container}`} style={{ display: fullyClosed ? 'none' : 'block' }}>
      <StyledOverlay className={overlayClassNames} pose={open ? 'overlayOpen' : 'overlayClosed'} onClick={closeModal} />
      <StyledModal
        className={classNames}
        pose={open ? `modalOpen` : `modalClosed`}
        style={{ maxWidth: props.width ? `${props.width}px` : null }}
      >
        {!fullyClosed && (
          <>
            <button className={styles.close} onClick={closeModal}>
              <FiX />
            </button>
            {props.title && <h2 className={styles.title}>{props.title}</h2>}
            <div className={`${styles.content}  iosScrollable`}>{props.children}</div>
          </>
        )}
      </StyledModal>
    </div>,
    document.body,
  );
};

export default Modal;
