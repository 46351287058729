import React, { Component } from 'react';
import { SETTINGS } from '../../config';
import * as mapboxgl from 'mapbox-gl';
import colors from '../../style-utils/colors.module.scss';
import styles from './map.module.scss';
import { MapHolder } from './';
import { getPolylineGeoJson, simplifyPolyline } from '../../utils';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Banner } from '../banner';
import { FiAlertTriangle } from 'react-icons/fi';

export class MapStatic extends Component {
  static defaultProps = {
    polyline: [],
    polylines: null,
    width: '100%',
    height: '500px',
  };

  constructor(props) {
    super(props);
    mapboxgl.accessToken = SETTINGS.mapbox.apikey;
    this.mapEl = React.createRef();
    this.state = {
      ready: false,
      height: props.height,
      abandonMap: false,
      abandonHeatmap: false,
    };
    this.layers = [];
    this.mounted = null;
    this.maxPolylineLength = 35;
    this.maxLinestringLength = 7800;
  }

  componentDidMount() {
    this.mounted = true;
    this.props.polyline && this.initStaticMap();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.polyline && this.props.polyline) {
      this.initStaticMap();
    }
  }

  initStaticMap = () => {
    let lineString;
    if (!this.props.polylines) {
      lineString = this.getPolylineLineString();
    } else {
      lineString = this.getPolylinesLineString();
    }

    const img = new Image();
    img.onload = this.onStaticMapLoad;

    const src = this.getImageSrc(lineString);

    // around 7800 characters is the max the static map api can handle if we go above this then
    // abandon the heatmap and just draw single color line
    if (src.length > this.maxLinestringLength) {
      lineString = this.getPolylineLineString();
      const simpleSrc = this.getImageSrc(lineString);
      // if still too big then give up
      if (simpleSrc.length > this.maxLinestringLength) {
        this.mounted && this.setState({ abandonMap: true, ready: true });
      } else {
        img.src = simpleSrc;
        this.mounted && this.props.polylines && this.setState({ abandonHeatmap: true });
      }
    } else {
      img.src = src;
    }

    this.mapEl.current.appendChild(img);
  };

  getPolylineLineString = () => {
    let line = [...this.props.polyline];
    if (line.length > this.maxPolylineLength) {
      line = simplifyPolyline(line, 0.0005);
    }
    let lineString = this.getStaticPolylineGeoJson(line);
    lineString = this.addPins(lineString);
    return lineString;
  };

  getPolylinesLineString = () => {
    let lineString = this.getStaticPolylinesGeoJson();
    lineString = this.stringifyPolylinesGeoJson(lineString);
    lineString = this.addPins(lineString);
    return lineString;
  };

  getImageSrc = lineString => {
    // get width and height of static image. 1280 is the max the api can handle
    const width = Math.min(this.mapEl.current.clientWidth || window.innerWidth, 1280);
    const height = Math.min(this.mapEl.current.clientHeight || window.innerHeight, 1280);

    // double image size on retina screens
    let retina = '';
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
      retina = '@2x';
    }

    const endpoint = `https://api.mapbox.com/styles/v1/${SETTINGS.mapbox.style}/static/##line##/auto/${width}x${height}${retina}?access_token=${SETTINGS.mapbox.apikey}`;

    let src = encodeURI(lineString).replace(/#/g, '%23');
    src = endpoint.replace('##line##', src);
    return src;
  };

  addPins = lineString => {
    if (this.props.polyline) {
      const start = this.props.polyline[0];
      const end = this.props.polyline[this.props.polyline.length - 1];
      lineString += `,pin-l-a+000000(${start[0]},${start[1]})`;
      lineString += `,pin-l-b+000000(${end[0]},${end[1]})`;
    }
    return lineString;
  };

  onStaticMapLoad = () => {
    // ready
    this.mounted && this.setState({ ready: true });
    setTimeout(() => {
      if (this.mounted) {
        this.setState({ height: 'auto' });
        if (this.props.onLoad) {
          this.props.onLoad(null, null);
        }
      }
    }, 500);
  };

  stringifyPolylinesGeoJson = lines => {
    const stringified = [];
    lines.forEach((line, i) => {
      stringified.push(`geojson(${JSON.stringify(line.source.data)})`);
    });
    return stringified.join(',');
  };

  getStaticPolylinesGeoJson = () => {
    const polylines = [];
    if (this.props.polylines) {
      this.props.polylines.forEach((line, i) => {
        const geojson = getPolylineGeoJson(line.path, line.color, i);
        polylines.push(geojson);
      });
    }
    return polylines;
  };

  getStaticPolylineGeoJson = polyline => {
    let lineString = '';
    if (polyline) {
      lineString = getPolylineGeoJson(polyline, colors.tertiary);
      lineString = JSON.stringify(lineString.source.data);
      lineString = `geojson(${lineString})`;
    }
    return lineString;
  };

  render() {
    return (
      <MapHolder width={this.props.width} height={this.state.height} ready={this.state.ready} className={styles.static}>
        {this.state.abandonMap && (
          <div className={styles.abandonMapBanner}>
            <FiAlertTriangle />
            <span>Sorry, Map preview is not available for this ride or route.</span>
          </div>
        )}
        {this.state.abandonHeatmap && (
          <Banner intent="warning" className={styles.abandonHeatmapBanner}>
            Sorry, Heatmap preview is not available for this ride or route.
          </Banner>
        )}
        <div className={styles.map} ref={this.mapEl} />
      </MapHolder>
    );
  }
}

export default MapStatic;
