import React, { Component } from 'react';
import * as strava from '../../api/strava';
import RideList from '../../components/ride-list';
import { LoadingView, InfoView } from '../../views';
import store from '../../store';
import { setLoading } from '../../store/actions';

export class RoutesView extends Component {
  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      routes: state.strava.routes,
      hasRoutes: true,
      updatedAt: null,
    };
    this.mounted = null;
    this.gettingRoutes = false;

    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      this.setState({
        routes: state.strava.routes,
        hasRoutes: state.strava.routes.length,
      });
    });
  }

  componentDidMount() {
    this.mounted = true;
    // get the routes
    this.getRoutes();
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.getRoutes();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.mounted = false;
  }

  gotRoutes = routes => {
    // if (!this.mounted) {
    //   return false;
    // }
    // this.setState({ routes, hasRoutes: routes.length });
    // store.dispatch(updateRoutes(routes));
    setLoading(false);
  };

  getRoutes = () => {
    if (!this.gettingRoutes) {
      const routes = strava.getRoutes();
      // routes.cached.then(routes => {
      //   this.gotRoutes(routes);
      // });
      routes.latest
        .then(routes => {
          this.gotRoutes(routes);
        })
        .finally(() => {
          this.gettingRoutes = false;
          this.setState({ updatedAt: Date.now() });
        });
    }
  };

  render() {
    if (!this.state.hasRoutes) {
      return (
        <InfoView tabs weatherSummary title="No Routes Found">
          Looks like you haven't created any routes yet.
          <a href="https://www.strava.com/routes">Click here</a> to create a route on Strava.
        </InfoView>
      );
    } else if (this.state.routes.length > 0) {
      return (
        <RideList
          rides={this.state.routes}
          type="route"
          active={this.props.active}
          onRefresh={this.getRoutes}
          updatedAt={this.state.updatedAt}
        />
      );
    } else {
      return <LoadingView message="Loading Routes..." tabs weatherSummary />;
    }
  }
}
