import React, { Component, useEffect, useState } from 'react';
import { MapInteractive } from './map-interactive';
import { MapStatic } from './map-static';
import vars from '../../style-utils/variables.module.scss';
import LoadingCircle from '../loading-circle';
import styles from './map.module.scss';
import classnames from 'classnames';
import store from '../../store';

export const MapHolder = props => {
  const state = store.getState();
  const fullScreen = props.height === '100%' || props.height === '100vh';
  const [layout, setLayout] = useState(state.global.layout);

  const getHeight = () => {
    if (layout === 'mobile') {
      return fullScreen ? `calc(${window.innerHeight}px - ${vars.headerHeight})` : props.height;
    } else {
      return fullScreen ? `calc(${window.innerHeight}px - ${vars.headerHeight} - ${vars.footerHeight})` : props.height;
    }
  };

  const [height, setHeight] = useState(getHeight());
  const classNames = classnames(styles.mapHolder, {
    [props.className]: props.className,
    [styles.mapReady]: props.ready,
    [styles.fullScreen]: fullScreen
  });

  useEffect(() => {
    fullScreen && window.addEventListener('resize', resize);
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      setLayout(state.global.layout);
    });
    return () => {
      unsubscribe();
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = () => {
    setHeight(getHeight());
  };

  return (
    <div className={classNames} style={{ width: props.width, height }}>
      {!props.ready && (
        <div className={styles.mapLoading}>
          <LoadingCircle text={props.text || 'Loading map...'} />
        </div>
      )}
      {props.children}
    </div>
  );
};

export class Map extends Component {
  render() {
    if (this.props.static) {
      return <MapStatic {...this.props} />;
    } else {
      return <MapInteractive {...this.props} />;
    }
  }
}

export default Map;
