import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../icon-button';
import posed from 'react-pose';
import styles from './action-button.module.scss';
import classnames from 'classnames';

const Hamburger = posed.div({
  open: {
    scale: 1.5,
  },
  closed: {
    scale: 1,
  },
});

const Line1 = posed.span({
  open: {
    rotate: '45deg',
    top: '50%',
  },
  closed: {
    top: '0',
    rotate: '0deg',
  },
});

const Line2 = posed.span({
  open: {
    opacity: 0,
    transition: {
      duration: 100,
    },
  },
  closed: {
    opacity: 1,
  },
});

const Line3 = posed.span({
  open: {
    rotate: '-45deg',
    top: '50%',
  },
  closed: {
    top: '100%',
    rotate: '0deg',
  },
});

const Actions = posed.ul({
  open: {
    applyAtStart: { visibility: 'visible' },
    staggerChildren: 50,
  },
  closed: {
    applyAtEnd: { visibility: 'hidden' },
  },
});

const ActionItemPose = posed.li({
  open: {
    opacity: 1,
    transform: 'translateY(0px)',
    transition: { type: 'spring', stiffness: 200, delay: 200 },
  },
  closed: {
    opacity: 0,
    transform: 'translateY(50px)',
    transition: { duration: 300 },
  },
});

const Backdrop = posed.div({
  open: {
    opacity: 1,
    width: '200vw',
    height: '200vw',
    transition: { duration: 200 },
  },
  closed: {
    opacity: 0,
    width: '0',
    height: '0',
    transition: { duration: 200 },
  },
});

const ActionItem = props => {
  return (
    <ActionItemPose
      onClick={props.onClick}
      pose={props.open ? 'open' : 'closed'}
      className={props.className ? props.className : ''}
    >
      {props.to && (
        <Link className={styles.actionLink} to={props.to}>
          {props.children}
        </Link>
      )}
      {props.action && (
        <div className={styles.actionLink} onClick={props.action}>
          {props.children}
        </div>
      )}
      {!props.action && !props.to && <div className={styles.actionLink}>{props.children}</div>}
    </ActionItemPose>
  );
};

export class ActionButton extends Component {
  lastScrollTop = 0;

  constructor(props) {
    super(props);
    this.icon = React.createRef();
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scroll);
  }

  render() {
    const classNames = classnames(styles.holder, {
      [this.props.className]: this.props.className,
      [styles.open]: this.state.open,
    });
    return (
      <div className={classNames}>
        <div
          className={styles.innerHolder}
          style={{ bottom: this.props.bottom || null, right: this.props.right || null }}
        >
          <Actions className={styles.actions} pose={this.state.open ? 'open' : 'closed'}>
            {this.props.actions.map((action, i) => {
              return (
                <ActionItem
                  className={styles.actionItem}
                  key={i}
                  open={this.state.open}
                  to={action.link}
                  action={action.action}
                  onClick={this.onActionClick}
                >
                  <span className={styles.actionLabel}>{action.label}</span>
                  <i className={styles.actionIcon}>{action.icon}</i>
                </ActionItem>
              );
            })}
          </Actions>
          <IconButton gradientBg onClick={this.toggle} className={styles.actionButton}>
            <Hamburger className={styles.hamburger} pose={this.state.open ? 'open' : 'closed'}>
              <Line1 className={styles.line1} />
              <Line2 className={styles.line2} />
              <Line3 className={styles.line3} />
            </Hamburger>
          </IconButton>
        </div>
        <Backdrop pose={this.state.open ? 'open' : 'closed'} className={styles.backdrop} />
      </div>
    );
  }

  onActionClick = () => {
    this.setState({ open: false });
  };

  scroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    if (this.state.open && Math.abs(this.lastScrollTop - scrollTop) > 10) {
      this.setState({ open: false });
    }
    this.lastScrollTop = scrollTop;
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
}

export default ActionButton;
