import React from 'react';
import styles from './loading2.module.scss';
import Loading from '../loading';
import posed from 'react-pose';
import { SETTINGS } from '../../config';

const LoadingHolder = posed.div({
  show: {
    applyAtStart: { visibility: "visible" },
    scale: 1,
    delay: SETTINGS.pageTransition / 3
  },
  hide: {
    applyAtEnd: { visibility: "hidden" },
    scale: 0
  }
});

const Loading2 = props => {
  return(
    <LoadingHolder pose={props.pose} className={`${styles.loading}`}>
      <Loading animate={false} />
    </LoadingHolder>
  );
}

export default Loading2;