import { Stats } from '@react-three/drei';
import { render, events, unmountComponentAtNode } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import * as mapboxgl from 'mapbox-gl';
import { useRef } from 'react';
import { Wind } from './wind';
import styles from './wind-map.module.scss';
import { distance, destination } from '@turf/turf';
import { isWebGLAvailable } from './utils';

export const WindMap = ({ windDir, windSpeed, map, bounds, onChange, debug = false, fps = 60 }) => {
  const padding = bounds ? 30 : -50;
  const windRef = useRef(null);
  const canvasRef = useRef(null);
  const markerRef = useRef(null);
  const mapBoundsRef = useRef(null);
  const mountedRef = useRef(null);
  const [ready, setReady] = useState(false);
  const canvasSize = 1000;

  useEffect(() => {
    onMount();
    return () => {
      onUnmount();
    };
  }, []);

  useEffect(() => {
    onUpdate();
  }, [windDir, windSpeed, fps]);

  useEffect(() => {
    if (markerRef.current) {
      const newBounds = bounds || map.getBounds();
      markerRef.current.setLngLat(newBounds.getCenter());
      mapBoundsRef.current = newBounds;
    }
  }, [bounds]);

  const onMount = () => {
    mountedRef.current = true;
    mapBoundsRef.current = bounds || map.getBounds();
    if (isWebGLAvailable()) {
      onUpdate();
      addWindToMap();
    }
  };

  const onUnmount = () => {
    mountedRef.current = false;
    removeWindFromMap();
  };

  const onUpdate = () => {
    if (canvasRef.current) {
      const size = window.devicePixelRatio === 2 ? canvasSize * 2 : canvasSize;
      render(
        <>
          {debug && <Stats className={styles.fps} />}
          <Wind bearing={windDir} speed={windSpeed} debug={debug} onChange={onChange} fps={fps} />
        </>,
        canvasRef.current,
        {
          events,
          size: {
            width: size,
            height: size,
          },
        },
      );
    }
  };

  const addWindToMap = () => {
    if (windRef.current) {
      markerRef.current = new mapboxgl.Marker(windRef.current).setLngLat(mapBoundsRef.current.getCenter()).addTo(map);
      setWindSize();
      map.on('rotate', setWindSize);
      map.on('zoom', setWindSize);
      map.on('move', setWindSize);
      setTimeout(() => {
        mountedRef.current && setReady(true);
      }, 1000);
    }
  };

  const removeWindFromMap = () => {
    markerRef.current && markerRef.current.remove();
    canvasRef.current && unmountComponentAtNode(canvasRef.current);
    markerRef.current = null;
    canvasRef.current = null;
    map.off('rotate', setWindSize);
    map.off('zoom', setWindSize);
    map.off('move', setWindSize);
  };

  const setWindSize = () => {
    if (windRef.current && canvasRef.current && markerRef.current && mapBoundsRef.current) {
      const marker = markerRef.current;
      const bounds = mapBoundsRef.current;
      marker.setLngLat(bounds.getCenter());
      const coordinates = createSquareBounds(bounds, padding);
      const origPixels = coordinates2Pixels(coordinates);
      const bearing = map.getBearing();
      const pixels = rotateBox(origPixels, bearing);
      const size = Math.abs(Math.round(pixels[0][0] - pixels[1][0]));
      windRef.current.style.width = `${size}px`;
      windRef.current.style.height = `${size}px`;
      canvasRef.current.style.transform = getTransform();
    }
  };

  const getBoundDimensions = bounds => {
    // calculate the the width and height of the bounds
    const top = [bounds.getNorthWest().lng, bounds.getNorthWest().lat];
    const bot = [bounds.getSouthWest().lng, bounds.getSouthWest().lat];
    const left = [bounds.getSouthEast().lng, bounds.getSouthEast().lat];
    const right = [bounds.getSouthWest().lng, bounds.getSouthWest().lat];
    return {
      height: distance(top, bot),
      width: distance(left, right),
    };
  };

  const createSquareBounds = (bounds, padding) => {
    const size = getBoundDimensions(bounds);
    let coordinates;
    let nw;
    let ne;
    let se;
    let sw;

    const center = bounds.getCenter();
    let dist = Math.max(size.width, size.height);
    dist = (dist / 100) * (100 + padding);
    const rad = Math.sqrt(Math.pow(dist, 2) + Math.pow(dist, 2)) / 2;

    // make the bounds a square and add padding
    nw = destination([center.lng, center.lat], rad, 315);
    ne = destination([center.lng, center.lat], rad, 45);
    se = destination([center.lng, center.lat], rad, 135);
    sw = destination([center.lng, center.lat], rad, 225);

    // create the coordinates
    coordinates = [
      [nw.geometry.coordinates[0], nw.geometry.coordinates[1]],
      [ne.geometry.coordinates[0], ne.geometry.coordinates[1]],
      [se.geometry.coordinates[0], se.geometry.coordinates[1]],
      [sw.geometry.coordinates[0], sw.geometry.coordinates[1]],
    ];

    return coordinates;
  };

  const getBoxCenter = points => {
    const x = (points[0][0] + points[2][0]) / 2;
    const y = (points[0][1] + points[2][1]) / 2;
    return [x, y];
  };

  const rotateBox = (box, degrees) => {
    const center = getBoxCenter(box);
    const rotated = [];
    box.forEach(coord => {
      const point = rotatePointAround(coord[0], coord[1], center[0], center[1], degrees);
      rotated.push([point.x, point.y]);
    });
    return rotated;
  };

  const rotatePointAround = (pointX, pointY, originX, originY, angle) => {
    angle = (angle * Math.PI) / 180.0;
    return {
      x: Math.cos(angle) * (pointX - originX) - Math.sin(angle) * (pointY - originY) + originX,
      y: Math.sin(angle) * (pointX - originX) + Math.cos(angle) * (pointY - originY) + originY,
    };
  };

  const coordinates2Pixels = coords => {
    return [...coords].map(point => {
      const pixels = map.project([...point]);
      return [pixels.x, pixels.y];
    });
  };

  const getTransform = () => {
    return `rotate(${-map.getBearing()}deg)`;
  };

  return (
    <div className={styles.windMap} ref={windRef} style={{ opacity: ready ? 1 : 0 }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default WindMap;
