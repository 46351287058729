import React, { useState, useEffect } from 'react';
import styles from './select.module.scss';
import posed from 'react-pose';
import classnames from 'classnames';
import { FiChevronDown } from 'react-icons/fi';

const Label = posed.label({
  above: {
    opacity: 1,
    y: '-2.3rem',
    scale: 0.8
  },
  inside: {
    opacity: 0.5,
    y: '-50%',
    scale: 1
  }
});

const Message = posed.span({
  visible: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
});

const Options = posed.div({
  optOpen: {
    opacity: 1,
    height: 'auto',
    transition: {
      duration: 200
    }
  },
  optClosed: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 100
    }
  }
});

const Arrow = posed.div({
  selOpen: {
    rotate: '180deg'
  },
  selClosed: {
    rotate: '0deg'
  }
});

export const Select = props => {

  const blankOption = {
    label: 'clear',
    value: ''
  };

  const [option, setOption] = useState(props.value ? props.value : blankOption);
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  const [open, setOpen] = useState(false);

  const message = 'This field is required';

  useEffect(() => {
    props.onChange && props.onChange(getValue());
  }, [option]);

  const valueChanged = option => {
    setOption(option);
    setTouched(true);
  };

  const getValue = () => {
    return {
      name: props.name,
      value: option.value,
      valid: validateValue(option)
    };
  };

  const validateValue = option => {
    let valid = true;

    if (!props.required && option.value === '') {
      setValid(true);
      setTouched(false);
      return true;
    }

    if (props.required) {
      valid = option.value !== blankOption.value;
    }

    setValid(valid);

    return valid;
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const classNames = classnames(styles.container, {
    [styles.valid]: touched && valid,
    [styles.invalid]: touched && !valid,
    [styles.open]: open
  });

  return (
    <div className={classNames} onClick={toggleOpen} style={{ zIndex: open ? '100' : null }}>
      <Label className={styles.label} pose={option.value.length !== 0 ? 'above' : 'inside'}>
        {props.label}
      </Label>
      <div className={styles.input}>{option.label !== blankOption.label ? option.label : ''}</div>
      <Options className={styles.options} pose={open ? 'optOpen' : 'optClosed'}>
        <ul>
          {props.options.map((opt, i) => (
            <li key={i} className={opt.value === option.value ? styles.activeOption : ''} onClick={valueChanged.bind(null, opt)} data-value={opt.value}>
              {opt.label}
            </li>
          ))}
        </ul>
        { props.clearable &&
          <div className={styles.blankOption} onClick={valueChanged.bind(null, blankOption)} data-value={blankOption.value}>
            {blankOption.label}
          </div>
        }
      </Options>
      <Arrow className={styles.arrow} pose={open ? 'selOpen' : 'selClosed'}>
        <FiChevronDown />
      </Arrow>
      {!open && (
        <Message className={styles.message} pose={touched && !valid ? 'visible' : 'hidden'}>
          {message}
        </Message>
      )}
    </div>
  );

};

Select.defaultProps = {
  label: 'Im a select',
  clearable: false,
  options: [],
  name: 'select',
  required: false,
  onChange: () => {},
}

export default Select;
