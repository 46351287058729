import React, { useState, useRef } from 'react';
import styles from './ride-journey-details.module.scss';
import format from 'date-fns/format';
import addSeconds from 'date-fns/addSeconds';
import subSeconds from 'date-fns/subSeconds';
import { normalizeDate, m2km, kph2mps, secondsToNiceTime } from '../../utils';
import { convertToUserPref, mps2kph } from '../../utils';
import { userPrefUnit } from '../../utils';
import DatePickerModal from '../datepicker';
import RangeSlider from '../range-slider';
import { SETTINGS } from '../../config';
import classnames from 'classnames';

export const RideJourneyDetails = ({
  ride,
  startTime,
  onChangeStartTime,
  onChangeEndTime,
  onChangeAverageSpeed,
  loading,
}) => {
  const maxAverageSpeed = SETTINGS.maxAverageSpeed;
  const defaultAverageSpeed = SETTINGS.defaultAverageSpeed;
  const [averageSpeed, setAverageSpeed] = useState(ride.average_speed || kph2mps(defaultAverageSpeed));
  const [pickerOpen, setPickerOpen] = useState(false);
  const currentlyChanging = useRef(null);

  const getStartTime = endTime => {
    const startTime = normalizeDate(subSeconds(endTime, getDuration()));
    return startTime;
  };

  const getEndTime = () => {
    const endTime = normalizeDate(addSeconds(startTime, getDuration()));
    return endTime;
  };

  const getNiceDuration = () => {
    const seconds = getDuration();
    return secondsToNiceTime(seconds);
  };

  const getNiceDistance = () => {
    return m2km(ride.distance);
  };

  const getDuration = () => {
    return ride.distance / averageSpeed;
  };

  const getNiceAverageSpeed = () => {
    return mps2kph(averageSpeed);
  };

  const onAverageSpeedChange = value => {
    const speed = kph2mps(value);
    setAverageSpeed(speed);
    onChangeAverageSpeed && onChangeAverageSpeed(speed);
  };

  const onOpenPicker = () => {
    setPickerOpen(true);
  };

  const onClosePicker = () => {
    setPickerOpen(false);
  };

  const onTriggerChangeStartTime = () => {
    currentlyChanging.current = 'start';
    onOpenPicker();
  };

  const onTriggerChangeEndTime = () => {
    currentlyChanging.current = 'end';
    onOpenPicker();
  };

  const onDatePickerChange = date => {
    if (currentlyChanging.current === 'start') {
      onChangeStartTime && onChangeStartTime(date);
    } else {
      const newStartTime = getStartTime(date);
      onChangeEndTime && onChangeEndTime(newStartTime);
    }
    currentlyChanging.current = null;
    onClosePicker();
  };

  const editableStatClassNames = classnames(styles.statEditable, {
    [styles.statDisabled]: loading,
  });

  return (
    <div className={styles.container}>
      <div className={styles.stats}>
        <div className={editableStatClassNames} onClick={onTriggerChangeStartTime}>
          <span>Start Time</span>
          {format(normalizeDate(startTime), 'HH:mm')}
          <span>Click to edit</span>
        </div>
        <div className={editableStatClassNames} onClick={onTriggerChangeEndTime}>
          <span>End Time</span>
          {format(getEndTime(), 'HH:mm')}
          <span>Click to edit</span>
        </div>
        <div className={styles.stat}>
          <span>Average Speed</span>
          {convertToUserPref(getNiceAverageSpeed(), 'speed')}
          <span>{userPrefUnit('speed')}</span>
        </div>
        <div className={styles.stat}>
          <span>Duration</span>
          {getNiceDuration()}
          <span>HH:MM</span>
        </div>
        <div className={styles.stat}>
          <span>Distance</span>
          {convertToUserPref(getNiceDistance(), 'distance')}
          <span>{userPrefUnit('distance')}</span>
        </div>
      </div>
      <div className={styles.rangeSlider}>
        <label>Adjust average speed</label>
        <RangeSlider
          disabled={loading}
          min="1"
          max={maxAverageSpeed}
          onChange={onAverageSpeedChange}
          defaultValue={getNiceAverageSpeed()}
          labelSuffix={userPrefUnit('speed')}
          labelValue={convertToUserPref(getNiceAverageSpeed(), 'speed')}
        />
      </div>
      <DatePickerModal
        open={pickerOpen}
        onChange={onDatePickerChange}
        onClose={onClosePicker}
        selected={currentlyChanging.current === 'end' ? getEndTime() : startTime}
        timeIntervals={15}
      />
    </div>
  );
};
