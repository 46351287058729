import { Component } from 'react';
import { initDemo } from '../../api/demo';
import { withRouter } from 'react-router-dom';

export class InitDemo extends Component {

  componentDidMount() {
    initDemo();
    this.props.history.push('/');
  }

  render() {
    return null;
  }
}

export const InitDemoView = withRouter(InitDemo);
