import React, { Component } from 'react';
import styles from './ios-install-prompt.module.scss';
import logo from '../../assets/images/headwind-logo-gradient.svg';
import addIcon from '../../assets/images/ios-add-to-home.png';
import { SETTINGS } from '../../config';
import MobileDetect from 'mobile-detect';
import { isInstalled } from '../../utils';
import { trackEvent } from '../../utils/ga';
import Modal from '../modal';

const md = new MobileDetect(window.navigator.userAgent);

export class IosInstallPrompt extends Component {
  ios = md.phone() === 'iPhone' || md.tablet() === 'iPad';
  installed = isInstalled();
  loggedInstall = localStorage.getItem('ios_installed');

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    if (this.ios && this.installed && !this.loggedInstall) {
      localStorage.setItem('ios_installed', true);
      trackEvent('IOS Install Prompt', 'Installed', window.navigator.userAgent);
    }
    if (!this.ios || this.installed) {
      return false;
    }
    this.timesSeen = localStorage.getItem('times_seen');
    this.timesVisited = localStorage.getItem('times_visited');
    if (this.timesVisited && this.timesSeen) {
      const timesVisited = parseInt(this.timesVisited);
      const timesSeen = parseInt(this.timesSeen);
      if (timesVisited >= SETTINGS.ios.visitsBeforePrompt && timesSeen < SETTINGS.ios.maxPromptTimes) {
        setTimeout(() => {
          trackEvent('IOS Install Prompt', 'Seen prompt', window.navigator.userAgent);
          this.setState({ open: true });
        }, this.props.delay || 6000);
        localStorage.setItem('times_visited', 1);
        localStorage.setItem('times_seen', timesSeen + 1);
      } else {
        localStorage.setItem('times_visited', timesVisited + 1);
      }
    } else {
      localStorage.setItem('times_visited', 2);
      localStorage.setItem('times_seen', 0);
    }
  }

  close = () => {
    trackEvent('IOS Install Prompt', 'Dismissed prompt', window.navigator.userAgent);
    this.setState({ open: false });
  };

  render() {
    if (!this.ios || this.installed) {
      return null;
    } else {
      return (
        <Modal open={this.state.open} onClose={this.close}>
          <div className={styles.icons}>
            <div className={styles.iconBlank} />
            <div className={styles.iconBlank} />
            <div className={styles.iconMain}>
              <img src={logo} alt="Headwind" />
            </div>
            <div className={styles.iconBlank} />
            <div className={styles.iconBlank} />
          </div>
          <div className={styles.content}>
            <h3 className={styles.title}>Install Headwind</h3>
            <p>Install Headwind to your home screen for quick and easy access when you are on the go.</p>
          </div>
          <div className={styles.footer}>
            Just tap <img src={addIcon} alt="Add to home" width="15" /> then 'Add to Home Screen'
          </div>
        </Modal>
      );
    }
  }
}

export default IosInstallPrompt;
