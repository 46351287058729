const initialState = {
  activeTab: localStorage.getItem('active_tab') ? parseInt(localStorage.getItem('active_tab')) : 0,
  open: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MENU':
      return { ...state, open: true };
    case 'CLOSE_MENU':
      return { ...state, open: false };
    case 'SET_ACTIVE_TAB':
      localStorage.setItem('active_tab', action.payload);
      return { ...state, activeTab: action.payload };
    default:
      return state;
  }
};

export default reducer;
