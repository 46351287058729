import React from 'react';
import { Pie } from 'react-chartjs-2';
import colors from '../../style-utils/colors.module.scss';

export const PieChart = props => {
  const options = {
    maintainAspectRatio: true,
    responsive: false,
    legend: {
      display: props.legend,
      align: 'left',
      position: 'left',
    },
  };
  const borderColor = props.dark ? colors.dark : colors.light;
  const sectionColors = [
    colors.primary,
    colors.secondary,
    colors.tertiary,
    colors.grey,
    colors.primaryDark,
    colors.secondaryDark,
    colors.tertiaryDark,
    colors.error,
    colors.success,
    colors.warning,
    colors.info,
  ];

  const getData = () => {
    return {
      datasets: [
        {
          data: props.data.map(item => item.value),
          backgroundColor: props.data.map((item, i) => sectionColors[i] || colors.light),
          borderColor: props.data.map(() => borderColor),
          borderWidth: props.data.map(() => props.borderWidth || 0),
        },
      ],
      labels: props.data.map(item => item.key),
    };
  };

  return <Pie data={getData()} options={options} width={props.width} height={props.height} preserveValue={true} />;
};

PieChart.defaultProps = {
  width: 300,
  height: 300,
  data: new Array(8).fill(0).map((a, i) => {
    return {
      key: `item ${i + 1}`,
      value: Math.random().toFixed(2),
    };
  }),
};
