import React, { Component } from 'react';
import { getHardnessColor, getRideHardness, normalizeDate, getWindHardnessColor, getRideID } from '../../utils';
import { FaArrowDown } from 'react-icons/fa';
import styles from './summary-item.module.scss';
import CountUp from 'react-countup';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { convertToUserPref } from '../../utils';
import { SETTINGS } from '../../config';

const SummaryItemHolder = props => {
  if (props.to) {
    return (
      <Link className={props.className} style={{ color: props.color }} to={props.to}>
        {props.children}
      </Link>
    );
  } else {
    return (
      <div className={props.className} style={{ color: props.color }}>
        {props.children}
      </div>
    );
  }
};

class SummaryItem extends Component {
  constructor(props) {
    super(props);

    this.day = format(normalizeDate(props.weather.time * 1000), 'EEE');
    this.time = format(normalizeDate(props.weather.time * 1000), "yyyy-MM-dd'T12:00:00'");

    this.url = props.ride ? `/${props.type}/${getRideID(props.ride)}/${this.time}` : false;

    const hardness = this.getHardness(props);
    const color = this.getColor(props, hardness);

    this.state = {
      windDir: 0,
      hardness,
      color,
    };

    this.animationTimeout = setTimeout(() => {
      this.setState({ windDir: props.weather.windBearing });
    }, 1000);
  }

  getHardness(props) {
    return props.ride ? getRideHardness(props.ride, props.weather) : false;
  }

  getColor(props, hardness = false) {
    if (!hardness && this.state) {
      hardness = this.state.hardness;
    }
    if (hardness === false) {
      return getWindHardnessColor(props.weather.windSpeed);
    } else {
      return getHardnessColor(hardness, 1, 0, SETTINGS.hardness.outof);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimeout);
  }

  componentWillReceiveProps(props) {
    this.setState({
      windDir: props.weather.windBearing,
      hardness: this.getHardness(props),
      color: this.getColor(props),
    });
  }

  render() {
    const dark = this.props.dark ? styles.dark : '';
    return (
      <SummaryItemHolder color={this.state.color} to={this.url} className={`${styles.container} ${dark}`}>
        <div className={styles.day}>{this.day}</div>
        <div className={styles.windIcon}>
          <FaArrowDown style={{ fill: this.state.color, transform: `rotate(${this.state.windDir}deg)` }} />
        </div>
        {this.props.ride && (
          <div className={styles.hardness}>
            <span className={styles.hardnessLabel}>Difficulty</span>
            <CountUp end={this.state.hardness} duration={2} delay={0.5} decimals={1} />
          </div>
        )}
        {!this.props.ride && (
          <div className={styles.hardness}>
            <span className={styles.hardnessLabel}>Wind Speed</span>
            <CountUp
              end={convertToUserPref(this.props.weather.windSpeed, 'distance')}
              duration={2}
              delay={0.5}
              decimals={1}
            />
          </div>
        )}
      </SummaryItemHolder>
    );
  }
}

export default SummaryItem;
