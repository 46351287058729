import * as Sentry from '@sentry/browser';

const initialState = {
  messages: [],
};

export const reducer = (state = initialState, action) => {
  let msgs = state.messages.slice(0);
  switch (action.type) {
    case 'ADD_MESSAGE':
      // log errors to sentry
      if (action.payload.type === 'error') {
        if (process.env.REACT_APP_VERSION && process.env.NODE_ENV === 'production') {
          Sentry.captureException(action.payload.text);
        }
      }
      if (action.payload.id) {
        const exists = msgs.filter(m => m.id === action.payload.id);
        !exists.length && msgs.push(action.payload);
      } else {
        action.payload.id = Date.now();
        msgs.push(action.payload);
      }
      return { ...state, messages: msgs };
    case 'DELETE_MESSAGE':
      msgs = msgs.filter(m => m.id !== action.payload);
      return { ...state, messages: msgs };
    default:
      return state;
  }
};

export default reducer;
