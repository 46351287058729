import React from 'react';
import styles from './loading-circle.module.scss';
import classnames from 'classnames';
import { FiX } from 'react-icons/fi';

const LoadingCircle = props => {
  const classNames = classnames(styles.loading, {
    [styles[`${props.color}Color`]]: props.color,
    [styles.hasText]: props.text,
    [styles.small]: props.small,
    [props.className]: props.className,
  });
  return (
    <div className={classNames}>
      <div className={styles.loadingCircle}>
        <>
          {props.onCancel && (
            <div className={styles.loadingCancel} onClick={props.onCancel}>
              <FiX />
            </div>
          )}
          {props.background && <div className={styles.background}></div>}
        </>
      </div>
      {props.text && <span className={styles.loadingText}>{props.text}</span>}
    </div>
  );
};

export default LoadingCircle;
