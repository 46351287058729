import React from 'react';
import styles from './icon-button.module.scss';

const IconButton = props => {
  const className = props.className ? props.className : '';
  const state = styles[props.state] ? styles[props.state] : '';
  const simple = props.simple ? styles.simple : '';
  const dark = props.dark ? styles.dark : '';
  const gradient = props.gradient ? styles.gradient : '';
  const gradientBg = props.gradientBg ? styles.gradientBg : '';
  const large = props.large ? styles.large : '';
  const small = props.small ? styles.small : '';
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <button
      onClick={onClick}
      className={`${className} ${styles.button} ${state} ${simple} ${dark} ${gradient} ${gradientBg} ${large} ${small}`}
    >
      {props.children}
    </button>
  );
};

export default IconButton;
