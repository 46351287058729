import React, { Component } from 'react';
import posed from 'react-pose';
import { NavLink, withRouter } from 'react-router-dom';
import { MdStar } from 'react-icons/md';
import { IoIosLogOut, IoMdBicycle, IoIosSettings } from 'react-icons/io';
import { GoDashboard } from 'react-icons/go';
import { FiSun, FiArrowDownCircle, FiCloudRain } from 'react-icons/fi';
import { FaRoute } from 'react-icons/fa';
import styles from './menu.module.scss';
import Button from '../button';
import stravaButton from '../../assets/images/strava-login-orange.svg';
import { trackEvent } from '../../utils/ga';
import { installApp, isAdmin, isDemo } from '../../utils';
import * as strava from '../../api/strava';
import { closeMenu, setActiveTab } from './actions';
import store from '../../store';
import classnames from 'classnames';
import { setLoading } from '../../store/actions';
import { DonateButton } from '../donate-button';

const MainMenu = posed.ul({
  open: {
    delayChildren: 50,
    staggerChildren: 100,
  },
});

const Item = posed.li({
  open: {
    opacity: 1,
    y: '0px',
  },
  closed: {
    opacity: 0,
    y: '50px',
  },
});

const MenuItemComp = props => {
  const onClick = () => {
    if (props.onClick) {
      !props.active && props.onClick();
    }
    store.dispatch(closeMenu());
  };
  const classNames = classnames(styles.menuItem, {
    [props.className]: props.className,
    // allow going from ride forecast to general forecast
    [styles.allowActiveClick]:
      props.href && props.href.includes('forecast') && props.location.pathname.includes('forecast/ride'),
  });
  return (
    <Item className={classNames} onClick={onClick}>
      {props.href && (
        <NavLink to={props.href} activeClassName={styles.menuItemActive}>
          {props.icon && <i>{props.icon}</i>}
          {props.children}
        </NavLink>
      )}
      {!props.href && (
        <span className={props.active ? styles.menuItemActive : ''}>
          {props.icon && <i>{props.icon}</i>}
          {props.children}
        </span>
      )}
    </Item>
  );
};

const MenuItem = withRouter(MenuItemComp);

export class Menu extends Component {
  isDemo = isDemo();

  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      open: state.menu.open,
      user: state.strava.user,
      prompt: false,
      loggingOut: false,
      donated: 0,
      activeTab: localStorage.getItem('active_tab'),
      version: state.global.version,
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (this.state.loggingOut || !state.strava.token) {
        return false;
      }
      this.setState({
        open: state.menu.open,
        prompt: state.global.installPrompt,
        user: state.strava.user,
        donated: (state.strava.user && state.strava.user.headwind && state.strava.user.headwind.donated) || 0,
        activeTab: state.menu.activeTab,
        version: state.global.version,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const classNames = classnames(styles.menu, this.props.className, 'iosScrollable');
    const isDash = this.props.location.pathname === '/';
    return (
      <MainMenu className={classNames}>
        {this.state.user && (
          <li className={styles.profile}>
            {this.state.user.profile_medium && (
              <img
                src={this.state.user.profile_medium}
                alt={this.state.user.username || this.state.user.firstname || 'anon'}
              />
            )}
            <span>{this.state.user.username || this.state.user.firstname || 'anon'}</span>
          </li>
        )}
        {/* <MenuItem icon={<GoDashboard />} href="/" className={styles.menuItemActive}>
          Dashboard
        </MenuItem> */}
        <MenuItem
          icon={<IoMdBicycle />}
          onClick={this.goToDashboard.bind(this, 0)}
          active={this.state.activeTab === 0 && isDash}
        >
          Rides
        </MenuItem>
        <MenuItem
          icon={<MdStar />}
          onClick={this.goToDashboard.bind(this, 1)}
          active={this.state.activeTab === 1 && isDash}
        >
          Starred
        </MenuItem>
        <MenuItem
          icon={<FaRoute />}
          onClick={this.goToDashboard.bind(this, 2)}
          active={this.state.activeTab === 2 && isDash}
        >
          Routes
        </MenuItem>
        <MenuItem icon={<FiSun />} href="/forecast">
          7 day forecast
        </MenuItem>
        <MenuItem icon={<FiCloudRain />} href="/rain-radar">
          Rain Radar
        </MenuItem>
        {/* <MenuItem icon={<IoMdBicycle />} href="/tour-de-france">
          Tour de france
        </MenuItem> */}
        <MenuItem icon={<IoIosSettings />} href="/settings">
          Settings
        </MenuItem>
        <MenuItem icon={<IoIosLogOut />} href="/logout" onClick={this.logout}>
          Log out
        </MenuItem>
        {this.state.user && isAdmin(this.state.user) && (
          <MenuItem icon={<GoDashboard />} href="/admin-dashboard">
            Admin Dashboard
          </MenuItem>
        )}
        <li className={styles.actionButtons}>
          {!this.isDemo && <DonateButton donated={this.state.donated} className={styles.donateButton} />}
          {this.isDemo && (
            <Button className={styles.stravaButton} onClick={strava.initAuth}>
              <img src={stravaButton} alt="Login with Strava" />
            </Button>
          )}
          {this.state.prompt && (
            <Button primary className={styles.installButton} onClick={this.install} icon={<FiArrowDownCircle />}>
              Install App
            </Button>
          )}
          {this.state.version && <p className={styles.version}>Version: {this.state.version}</p>}
        </li>
      </MainMenu>
    );
  }

  close = () => {
    store.dispatch(closeMenu());
  };

  install = () => {
    installApp(this.state.prompt);
    this.close();
  };

  logout = () => {
    trackEvent('Logout', 'Clicked logout button');
    this.setState({ loggingOut: true });
  };

  goToDashboard = tab => {
    store.dispatch(setActiveTab(tab));
    this.props.history.replace('/');
    setLoading(false, 500);
  };
}

export default withRouter(Menu);
