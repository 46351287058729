import React from 'react';
import styles from './full-screen.module.scss';
import classnames from 'classnames';

const FullScreen = props => {
  const classNames = classnames(styles.fullScreen, {
    [styles.hasTabs]: props.tabs,
    [styles.hasWeatherSummary]: props.weatherSummary,
    [props.className]: props.className,
  });
  return <div className={classNames}>{props.children}</div>;
};

export default FullScreen;
