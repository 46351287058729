import {
  SETTINGS
} from '../../config';

const demoUser = {
  "id": -1,
  "username": "jimbo",
  "resource_state": 3,
  "firstname": "Jimmy",
  "lastname": "Jones",
  "city": "",
  "state": "",
  "country": "",
  "sex": "M",
  "premium": false,
  "summit": false,
  "created_at": "2017-01-19T07:14:01Z",
  "updated_at": "2019-02-18T13:54:57Z",
  "badge_type_id": 0,
  "profile_medium": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c4",
  "profile": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c4",
  "friend": null,
  "follower": null,
  "follower_count": 25,
  "friend_count": 24,
  "mutual_friend_count": 0,
  "athlete_type": 0,
  "date_preference": "%m/%d/%Y",
  "measurement_preference": "meters",
  "clubs": [],
  "ftp": null,
  "weight": 67,
  "bikes": [{
    "id": "b3541914",
    "primary": true,
    "name": "Cannondale Caad8",
    "resource_state": 2,
    "distance": 8779597
  }],
  "shoes": [],
  "headwind": SETTINGS.defaultUserSettings
}

export default demoUser;