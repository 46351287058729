import store from './index';
import {
  SETTINGS
} from '../config';

export const setLoadingState = loading => ({
  type: 'SET_LOADING',
  payload: loading
});

let loadingTimeout = 0;

export const setLoading = (loading, speed = SETTINGS.pageTransitionSpeed * 2) => {
  clearTimeout(loadingTimeout);
  if (speed) {
    loadingTimeout = setTimeout(() => {
      store.dispatch(setLoadingState(loading));
    }, speed);
  } else {
    store.dispatch(setLoadingState(loading));
  }
};

export const setLoadingBar = loading => ({
  type: 'SET_LOADING_BAR',
  payload: loading
});

export const setInstallPrompt = prompt => ({
  type: 'SET_INSTALL_PROMPT',
  payload: prompt
});

export const setFontLoaded = loaded => ({
  type: 'SET_FONT_LOADED',
  payload: loaded
});

export const setLayout = layout => ({
  type: 'SET_LAYOUT',
  payload: layout
});

export const updateShared = () => ({
  type: 'UPDATE_SHARED',
});

export const updateVersion = version => ({
  type: 'UPDATE_VERSION',
  payload: version
});