import ReactGA from 'react-ga';
import {
  SETTINGS
} from '../config';
import {
  isInstalled
} from '../utils';

export const initTracking = () => {
  switch (window.location.host) {
    case 'headwind.app':
      ReactGA.initialize(SETTINGS.ga.live);
      break;
    case 'development.headwind.app':
      ReactGA.initialize(SETTINGS.ga.dev);
      break;
    default:
      ReactGA.initialize(SETTINGS.ga.local);
  }
  trackPageView(window.location);
}

export const trackPageView = (location) => {
  let path = location.pathname;
  if (location.pathname === '/') {
    const tab = localStorage.getItem('active_tab');
    if (tab) {
      path += `tab/${tab}`;
    }
  }
  if (isInstalled()) {
    path += '/?installed=1';
  }
  ReactGA.pageview(path);
}

export const trackEvent = (category, action, value) => {
  ReactGA.event({
    category,
    action,
    value
  });
}