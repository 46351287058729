import {
  storage
} from '../../utils';

export const getCachedUser = () => {
  let user = null;
  if (storage) {
    const storedUser = storage.getItem('strava_user') || null;
    if (storedUser) {
      user = JSON.parse(storedUser);
    }
  }
  return user;
};

export default getCachedUser;