import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { format, differenceInMinutes, addSeconds } from 'date-fns';
import * as strava from '../../api/strava';
import HeatMap from '../../components/heat-map';
import {
  getWeatherIcon,
  normalizeDate,
  convertToUserPref,
  userPrefUnit,
  calculateCalories,
  m2km,
  getRideID,
  isEmbedded,
  getSegmentsFromRideOrRoute,
  getSegmentPolylineFromRide,
  mapboxPolyline,
  mps2kph,
  kph2mps,
  secondsToNiceTime,
  miles2km,
} from '../../utils';
import { setTitle } from '../../components/header/actions';
import store from '../../store';
import styles from './ride.module.scss';
import posed, { PoseGroup } from 'react-pose';
import CountUp from 'react-countup';
import { setLoading, setLoadingBar } from '../../store/actions';
import ActionButton from '../../components/action-button';
import { FiCalendar, FiSun } from 'react-icons/fi';
import { MdRefresh, MdShare, MdAccessTime } from 'react-icons/md';
import { AiOutlineNodeIndex } from 'react-icons/ai';
import DatePickerModal from '../../components/datepicker';
import { SETTINGS } from '../../config';
import HourlyWind from '../../components/hourly-wind';
import BottomDrawer from '../../components/bottom-drawer';
import DognutChart from '../../components/dognut-chart';
import { stopIOSOverscroll } from '../../utils/ios-overscroll';
import ShareModal from '../../components/share-modal';
import * as Message from '../../components/message';
import { Helmet } from 'react-helmet';
import { Banner } from '../../components/banner';
import Button from '../../components/button';
import { SegmentSelector } from '../../components/segment-selector';
import demoRide from '../../api/demo/ride';
import { DonateButton } from '../../components/donate-button';
import { RideJourneyDetails } from '../../components/ride-journey-details';
import RangeSlider from '../../components/range-slider';
import mapboxgl from 'mapbox-gl';
import * as polyline from 'google-polyline';
import along from '@turf/along';
import { lineString } from '@turf/helpers';
import { IoMdBicycle } from 'react-icons/io';
import classnames from 'classnames';
import TourDeFrance from '../../api/tour-de-france';

const Actions = posed.div({
  enter: {
    opacity: 1,
    y: '0%',
    transition: {
      delay: 100,
      type: 'spring',
      stiffness: 500,
    },
  },
  exit: {
    opacity: 0,
    y: '100%',
  },
});

const RangeContainer = posed.div({
  enter: {
    opacity: 1,
    x: '0%',
    transition: {
      duration: 100,
      delay: 100,
    },
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: 100,
    },
  },
});

export class RideView extends Component {
  constructor(props) {
    super(props);

    const state = store.getState();
    let niceTime;
    if (props.match.params.time) {
      niceTime = this.getNiceTime(props.match.params.time, props.match.params.type !== 'stage');
    } else {
      niceTime = this.getNiceTime(normalizeDate());
    }

    this.state = {
      ride: null,
      weather: null,
      hardness: null,
      showInfo: false,
      actions: [],
      actionsAdded: false,
      reverse: false,
      datePickerOpen: false,
      time: props.match.params.time || normalizeDate(),
      niceTime,
      drawerOpen: false,
      sharing: false,
      shareText: null,
      shareTitle: null,
      shareURL: null,
      layout: state.global.layout,
      loaded: false,
      user: state.strava.user,
      shared: state.global.shared,
      activeTab: 0,
      type: props.match.params.type,
      niceType: props.match.params.type === 'starred' ? 'ride' : props.match.params.type,
      polyline: [],
      rideDistance: 0,
      loading: state.global.loadingBar,
    };
    this.rideID = props.match.params.id;
    this.time = props.match.params.time;
    this.mounted = null;
    this.stopIOSOverscroll = new stopIOSOverscroll();
    this.userID = props.match.params.uid;
    this.segmentID = props.match.params.sid || null;
    this.rideSelectedDistance = React.createRef();
    this.rideAverageSpeed = React.createRef();
    if (props.match.params.meta) {
      this.meta = JSON.parse(atob(decodeURIComponent(props.match.params.meta)));
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.stopIOSOverscroll.enable();
    if (this.state.type === 'stage') {
      const tourAPI = new TourDeFrance();
      const path = this.rideID.replace('_', '/');
      tourAPI.getRide(path).then(ride => {
        this.gotRide(ride);
      });
    } else if (this.state.type === 'segment-demo') {
      this.initRide(demoRide);
    } else if (this.state.shared) {
      const ride = strava.getShared(this.state.type, this.userID, this.rideID);
      ride.cached.then(ride => {
        this.gotRide(ride);
      });
      ride.latest.then(ride => {
        this.gotRide(ride);
      });
    } else {
      const apiCall = this.state.type === 'route' ? strava.getRoute : strava.getRide;
      const ride = apiCall(this.rideID);
      ride.cached.then(ride => {
        this.gotRide(ride);
      });
      ride.latest.then(ride => {
        this.gotRide(ride);
      });
    }

    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.global.layout) {
        this.setState({
          layout: state.global.layout,
          shared: state.global.shared,
          loading: state.global.loadingBar,
        });
      }
    });
  }

  componentWillUpdate(props, state) {
    if (this.state.type !== props.match.params.type) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      this.setState({
        type: props.match.params.type,
        niceType: props.match.params.type === 'starred' ? 'ride' : props.match.params.type,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.stopIOSOverscroll.disable();
    this.unsubscribe && this.unsubscribe();
    this.userMarker && this.userMarker.remove();
  }

  gotRide = ride => {
    if (this.mounted) {
      if (!ride.calories) {
        ride.calories = this.calculateCalories(ride);
      }
      if (this.segmentID) {
        this.loadSegment(ride, this.segmentID);
      } else {
        this.initRide(ride);
      }
    }
  };

  initRide = ride => {
    this.setState({
      ride,
      polyline: lineString(mapboxPolyline(polyline.decode(ride.map.polyline || ride.map.summary_polyline))),
      rideDistance: convertToUserPref(m2km(ride.distance), 'distance'),
    });
    this.rideAverageSpeed.current = ride.average_speed || kph2mps(SETTINGS.defaultAverageSpeed);
    this.setRideTitle(ride.name);
  };

  loadSegment = (ride, sId) => {
    const segments = getSegmentsFromRideOrRoute(ride);
    let segment = segments.find(s => String(s.id) === sId);
    if (segment) {
      const segmentPolyline = getSegmentPolylineFromRide(ride, segment);
      segment.map = { polyline: segmentPolyline, original_polyline: ride.map.polyline || ride.map.summary_polyline };
      segment = { ...ride, ...segment };
      segment.calories = this.calculateCalories(segment);
      this.setState({
        ride: segment,
      });
      this.setRideTitle(segment.name);
    } else {
      store.dispatch(
        Message.add({
          type: 'error',
          text: `Sorry unable to find segment with id '${sId}' in ${this.state.type} with id '${ride.id}'`,
          showFor: 6000,
        }),
      );
    }
  };

  calculateCalories = (ride, windSpeed = 0) => {
    const time = m2km(ride.distance) / SETTINGS.calorieCalculation.averageSpeed;
    const cals = calculateCalories(ride.athlete.sex || 'M', SETTINGS.calorieCalculation.averageSpeed, windSpeed, time);
    return cals;
  };

  showRadar = () => {
    // const weather = (this.state.weather && this.state.weather.currently) || null;
    const isCurrent = Math.abs(differenceInMinutes(Date.now(), this.state.time)) <= 61;
    // const isRaining = weather && weather.precipIntensity && weather.precipIntensity > 0 ? true : false;
    // if (isCurrent && isRaining) {
    if (this.state.shared) {
      return false;
    } else if (isCurrent) {
      return true;
    } else {
      return false;
    }
  };

  getUserUnitPrefs = () => {
    let prefs = SETTINGS.defaultUserSettings.unitPrefs;
    if (this.state.shared && this.meta.unitPrefs) {
      prefs = this.meta.unitPrefs;
    } else if (this.state.user && this.state.user.headwind && this.state.user.headwind.unitPrefs) {
      prefs = this.state.user.headwind.unitPrefs;
    }
    return prefs;
  };

  render() {
    return (
      <>
        {this.state.shared && this.meta && (
          <Helmet>
            {this.state.ride && (
              <title>
                {this.state.ride.name} by {this.meta.user || 'anon'} | Headwind.app
              </title>
            )}
            {this.meta.description && <meta name="description" content={this.meta.description} />}
          </Helmet>
        )}
        <div className={styles.container}>
          <DatePickerModal
            open={this.state.datePickerOpen}
            onChange={this.changeDate}
            onClose={this.onDatePickerClose}
            selected={this.state.time}
          />
          <HeatMap
            banner={this.renderBanners()}
            ride={this.state.ride}
            time={this.state.time}
            radar={this.showRadar()}
            reverse={this.state.reverse}
            onUpdate={this.onUpdate}
            height="100%"
            onLoad={this.onLoad}
            onWindMapLoad={this.onWindMapLoad}
          />
          {this.renderDurationRangeSlider()}
          {this.renderBottomDrawer()}
          <PoseGroup>
            {this.state.showInfo && !isEmbedded() && (
              <Actions key="rideActions" className={styles.rideActions}>
                <ActionButton actions={this.state.actions} />
              </Actions>
            )}
          </PoseGroup>
        </div>
        {this.state.ride && (
          <ShareModal
            type={this.state.type}
            title={this.state.shareTitle}
            text={this.state.shareText}
            url={this.state.shareURL}
            open={this.state.sharing}
            onClose={this.shareEnd}
          />
        )}
        {this.state.shared && !this.state.user && (
          <div className={styles.shareCredit}>
            <button className="link" onClick={this.signUp}>
              powered by headwind.app
            </button>
          </div>
        )}
      </>
    );
  }

  signUp = () => {
    window.open('https://headwind.app/?utm_source=headwindapp&utm_medium=app&utm_campaign=embed');
  };

  renderBanners = () => {
    if (!this.state.user && this.state.shared) {
      return (
        <Banner
          intent="strava"
          action={
            <Button minimal onClick={this.signUp}>
              Connect to Strava
            </Button>
          }
        >
          Analyse your rides in the same detail.
        </Banner>
      );
    } else if (this.state.type === 'segment-demo') {
      return (
        <Banner
          action={
            <DonateButton
              info="Donate to gain access to segment explorer. Your donation is much appreciated and will help keep Headwind free for everyone."
              infoModalOnly
              title="Unlock Segments"
              minimal
            />
          }
        >
          Unlock heatmaps for each segment of your ride
        </Banner>
      );
    }
  };

  renderTopStats = () => {
    const weather = (this.state.weather && this.state.weather.currently) || null;
    const description = (this.state.weather && this.state.weather.hourly && this.state.weather.hourly.summary) || null;
    const icon = weather ? getWeatherIcon(weather) : null;
    return (
      <div className={styles.rideInfo}>
        <div className={styles.rideHeading}>
          <span className={styles.rideIcon}>{icon && <img src={icon} alt="weather icon" />}</span>
          <span className={styles.rideHeadingText}>
            {this.state.niceTime}
            {description && <span className={styles.rideDescription}>{description}</span>}
          </span>
        </div>
        <div className={styles.rideStats}>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Temp</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={
                  this.state.showInfo
                    ? convertToUserPref(weather.apparentTemperature, 'temperature', this.getUserUnitPrefs())
                    : 0
                }
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('temperature', this.getUserUnitPrefs())}</div>
          </div>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Rain</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={
                  this.state.showInfo
                    ? convertToUserPref(weather.precipIntensity, 'rainfall', this.getUserUnitPrefs())
                    : 0
                }
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('rainfall', this.getUserUnitPrefs())}</div>
          </div>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Wind Speed</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={
                  this.state.showInfo ? convertToUserPref(weather.windSpeed, 'distance', this.getUserUnitPrefs()) : 0
                }
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('speed', this.getUserUnitPrefs())}</div>
          </div>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Wind Gust</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={this.state.showInfo ? convertToUserPref(weather.windGust, 'distance', this.getUserUnitPrefs()) : 0}
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('speed', this.getUserUnitPrefs())}</div>
          </div>
          <div
            className={`${styles.rideStat} ${
              this.state.hardness > SETTINGS.hardness.getsHardAt && styles.rideStatAlert
            }`}
          >
            <div className={styles.rideStatLabel}>Difficulty</div>
            <div className={styles.rideStatValue}>
              <CountUp end={this.state.showInfo ? this.state.hardness : 0} duration={2} delay={1} decimals={1} />
            </div>
            <div className={styles.rideStatLabel}>out of {SETTINGS.hardness.outof}</div>
          </div>
        </div>
      </div>
    );
  };

  renderDurationRangeSlider = () => {
    if (this.state.ride) {
      const classNames = classnames(styles.durationRangeSlider, {
        [styles.durationRangeSliderRadar]: this.showRadar(),
      });
      return (
        <PoseGroup>
          {this.state.activeTab === 1 && (
            <RangeContainer className={classNames} key="rangeSLider">
              <RangeSlider
                disabled={this.state.loading}
                min={0}
                max={this.state.rideDistance}
                step="0.05"
                defaultValue={Math.round(this.state.rideDistance / 2)}
                labelSuffix={` ${userPrefUnit('distance')}`}
                vertical={true}
                dark={true}
                onChange={this.onDurationRangeChange}
              />
            </RangeContainer>
          )}
        </PoseGroup>
      );
    } else {
      return null;
    }
  };

  renderBottomDrawerInfo = () => {
    return (
      <>
        <HourlyWind
          time={this.state.time}
          className={`${styles.hourly} iosScrollable`}
          weather={this.state.weather && this.state.weather.hourly}
          ride={this.state.ride}
          onClick={this.onHourlyWindClick}
          unitPrefs={this.getUserUnitPrefs()}
        />
        <DognutChart
          calories={this.state.ride ? this.state.ride.calories : 0}
          ready={this.state.layout === 'desktop' ? true : this.state.drawerOpen}
          hardness={this.state.hardness}
        />
      </>
    );
  };

  renderBottomDrawer = () => {
    const weather = (this.state.weather && this.state.weather.currently) || null;
    if (!weather) {
      return null;
    } else {
      return (
        <BottomDrawer
          activeTab={this.state.activeTab}
          onTabChange={this.onTabChange}
          ready={this.state.showInfo}
          onReady={this.onDrawerReady}
          onOpen={this.onDrawerOpen}
          onClose={this.onDrawerClose}
          visible={this.renderTopStats()}
          primaryTabTitle="Weather"
          primaryTabIcon={<FiSun />}
          secondaryTab={
            <RideJourneyDetails
              ride={this.state.ride}
              startTime={this.state.time}
              onChangeStartTime={this.changeDate}
              onChangeEndTime={this.changeDate}
              onChangeAverageSpeed={this.averageSpeedChange}
              loading={this.state.loading}
            />
          }
          secondaryTabTitle="Duration"
          secondaryTabIcon={<MdAccessTime />}
          tertiaryTab={
            <SegmentSelector
              ride={this.state.ride}
              onSelect={this.onSelectSegment}
              allowed={this.state.type === 'segment-demo'}
            />
          }
          tertiaryTabTitle="Segments"
          tertiaryTabIcon={<AiOutlineNodeIndex />}
          hideTertiaryTab={this.state.shared || this.state.type === 'stage'}
        >
          {this.renderBottomDrawerInfo()}
        </BottomDrawer>
      );
    }
  };

  onTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
    if (this.userMarker && this.userMarker.getElement()) {
      this.userMarker.getElement().style.opacity = tab === 1 ? 1 : 0;
    }
  };

  onSelectSegment = segment => {
    store.dispatch(setTitle(segment.name));
    if (segment.activeSegment) {
      segment.calories = this.calculateCalories(segment);
    }
    this.setState({
      ride: segment,
      activeTab: 0,
    });
  };

  onDrawerReady = toggleDrawer => {
    this.toggleDrawer = toggleDrawer;
  };

  onDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  onDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  onHourlyWindClick = time => {
    store.dispatch(setLoadingBar(true));
    this.setState({
      time: normalizeDate(time),
      niceTime: this.getNiceTime(time),
    });
  };

  onDurationRangeChange = value => {
    let distance = value;
    if (this.getUserUnitPrefs().distance === 'miles') {
      distance = miles2km(value);
    }
    this.setUserMarkerPosition(distance);
    this.setUserMarkerTime(distance);
    this.rideSelectedDistance.current = distance;
  };

  getUserMarkerTime = distance => {
    const timePassed = (distance / mps2kph(this.rideAverageSpeed.current)) * 60 * 60;
    const duration = secondsToNiceTime(timePassed);
    const time = format(addSeconds(this.state.time, timePassed), 'h:mm a');
    return `<span>${duration}</span><span>${time}</span>`;
  };

  getPointAlongLine = distance => {
    const point = along(this.state.polyline, distance, {
      units: 'kilometers',
    });
    return point.geometry.coordinates;
  };

  setUserMarkerPosition = distance => {
    if (this.userMarker) {
      const point = this.getPointAlongLine(distance);
      this.userMarker.setLngLat(point);
    }
  };

  setUserMarkerTime = distance => {
    if (this.userMarkerLabel) {
      const time = this.getUserMarkerTime(distance);
      this.userMarkerLabel.innerHTML = time;
    }
  };

  getNiceTime = (date, showTime = true) => {
    if (date) {
      const formatString = showTime ? "EEE MMMM do 'at' h:mm a" : 'EEE MMMM do';
      return format(date, formatString);
    } else {
      return format(new Date(), 'EEE MMMM do');
    }
  };

  setRideTitle = title => {
    if (this.state.shared && this.meta && this.meta.user && this.state.type !== 'stage') {
      title = `${title} by ${this.meta.user}`;
    }
    store.dispatch(setTitle(title));
  };

  changeDate = date => {
    store.dispatch(setLoadingBar(true));
    this.setState({
      time: normalizeDate(date),
      niceTime: this.getNiceTime(date),
    });
    this.datePickerClose();
    // @TODO: this fucks up when using the back button, might be nice for sharing
    // window.history.pushState({}, '', `/ride/${getRideID(this.state.ride)}/${normalizeDate(date)}`);
  };

  datePickerOpen = () => {
    this.setState({ datePickerOpen: true });
  };

  datePickerClose = () => {
    this.setState({ datePickerOpen: false });
  };

  onDatePickerClose = () => {
    this.datePickerClose();
  };

  averageSpeedChange = speed => {
    this.rideAverageSpeed.current = speed;
    if (this.userMarkerLabel) {
      this.setUserMarkerTime(this.rideSelectedDistance.current);
    }
  };

  reverseRide = () => {
    const reverse = !this.state.reverse;
    this.state.polyline && this.state.polyline.geometry.coordinates.reverse();
    this.setUserMarkerPosition(this.rideSelectedDistance.current);
    this.setState({
      reverse,
      polyline: this.state.polyline,
    });
    const title = reverse ? `${this.state.ride.name} (reversed)` : this.state.ride.name;
    this.setRideTitle(title);
    document.querySelector(`.${styles.reverseIcon}`).classList.toggle(styles.reverseIconReversed);
  };

  resetRide = () => {
    this.setState({ reverse: false });
    this.setRideTitle(this.state.ride.name);
    document.querySelector(`.${styles.reverseIcon}`).classList.remove(styles.reverseIconReversed);
  };

  onUpdate = (hardness, weather) => {
    store.dispatch(setLoadingBar(false));
    this.mounted && this.setState({ hardness, weather });
    this.setUserMarkerTime(this.rideSelectedDistance.current);
  };

  onWindMapLoad = windMap => {
    this.windMap = windMap;
  };

  onLoad = (weather, hardness, map, bounds) => {
    if (!this.mounted) {
      return false;
    }
    setLoading(false);

    const actions = [];
    actions.push({
      label: `How hard will this ${this.state.niceType} be on...`,
      icon: <FiCalendar />,
      action: () => this.datePickerOpen(),
    });
    actions.push({
      label: `Reverse this ${this.state.niceType}`,
      icon: <MdRefresh className={styles.reverseIcon} />,
      action: this.reverseRide,
    });
    if (!this.state.shared && this.state.type !== 'stage') {
      actions.push({
        label: `7 day forecast for this ${this.state.niceType}`,
        icon: <FiSun />,
        link: `/forecast/${this.state.type}/${getRideID(this.state.ride)}`,
      });
    }
    actions.push({
      label: `Share ${this.state.niceType}`,
      icon: <MdShare />,
      action: this.shareRide,
    });

    if (map.controls) {
      map.controls.forEach(({ label, control }) => {
        const ref = React.createRef();
        actions.push({ label, icon: <div ref={ref} />, ref, control });
      });
    }

    this.setState({
      weather,
      hardness,
      actions,
      loaded: true,
    });

    this.mapReady(map, actions);
  };

  mapReady = (map, actions) => {
    if (!this.mounted) {
      return false;
    }

    this.setState({ showInfo: true });
    if (!this.state.actionsAdded) {
      actions.forEach(action => {
        action.ref && action.ref.current && action.ref.current.appendChild(action.control.onAdd(map));
      });
      this.setState({ actionsAdded: true });
    }

    this.addUserMarker(map);
  };

  addUserMarker = map => {
    if (!this.userMarker) {
      const el = document.createElement('div');
      if (this.state.user && this.state.user.profile_medium) {
        el.innerHTML = `
          <img
            src='${this.state.user.profile_medium}'
            alt='${this.state.user.username || this.state.user.firstname || 'anon'}'
          />
        `;
      } else {
        el.innerHTML = ReactDOMServer.renderToStaticMarkup(<IoMdBicycle />);
      }

      el.className = styles.userMarker;
      const distance = m2km(this.state.ride.distance) / 2;
      const point = this.getPointAlongLine(distance);
      this.userMarkerLabel = document.createElement('div');
      this.userMarkerLabel.classList.add(styles.userMarkerLabel);
      this.setUserMarkerTime(distance);
      this.rideSelectedDistance.current = distance;
      el.appendChild(this.userMarkerLabel);
      this.userMarker = new mapboxgl.Marker(el).setLngLat(point).addTo(map);
    }
  };

  shareRide = () => {
    if (this.state.ride.private) {
      store.dispatch(
        Message.add({
          type: 'error',
          text: `Sorry this ${this.state.type} has been set to private on Strava so cannot be shared. To share it please log into Strava and change the visibility of the ride to public.`,
          showFor: 6000,
        }),
      );
      return;
    }

    const weather = this.state.weather.currently;
    let title = `${this.state.ride.name}`;
    if (this.state.user) {
      title = `${title} by ${this.state.user.username}`;
    } else if (this.meta) {
      title = `${title} by ${this.meta.user}`;
    }
    const desc = [];
    desc.push(title);
    desc.push(this.state.niceTime);
    desc.push(this.state.weather.hourly.summary);
    desc.push(`Hardness: ${this.state.hardness} out of ${SETTINGS.hardness.outof}`);
    desc.push(`Wind Speed: ${convertToUserPref(weather.windSpeed, 'distance')}${userPrefUnit('distance')}`);
    desc.push(`Wind Gust: ${convertToUserPref(weather.windGust, 'distance')}${userPrefUnit('distance')}`);
    desc.push(`Temp: ${convertToUserPref(weather.apparentTemperature, 'temperature')} ${userPrefUnit('temperature')}`);
    desc.push(`Rainfall: ${convertToUserPref(weather.precipIntensity, 'rainfall')}${userPrefUnit('rainfall')}`);
    const blurb = desc.join(', ');
    let meta = this.meta
      ? this.meta
      : {
          user: this.state.user.username,
          unitPrefs: this.state.user.headwind
            ? this.state.user.headwind.unitPrefs
            : SETTINGS.defaultUserSettings.unitPrefs,
        };
    meta = btoa(JSON.stringify(meta));
    meta = encodeURIComponent(meta);
    const segmentID = this.state.ride.activeSegment ? `/${this.state.ride.activeSegment}` : '';
    const userID = this.state.user?.id || this.props.match.params.uid;
    const shareURL = `${window.location.origin}/share/${this.state.type}/${userID}/${this.rideID}/${this.state.time}/${meta}${segmentID}`;

    this.setState({
      sharing: true,
      shareText: blurb,
      shareURL: shareURL,
      shareTitle: title,
    });
  };

  shareEnd = () => {
    this.setState({
      sharing: false,
      shareURL: null,
      shareTitle: null,
      shareText: null,
    });
  };
}

export default RideView;
