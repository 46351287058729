import React from 'react';
import styles from './button.module.scss';
import classnames from 'classnames';
import LoadingCircle from '../loading-circle';
import Link from 'react-router-dom/Link';

const Element = props => {
  if (props.href) {
    return (
      <Link {...props} to={props.href}>
        {props.children}
      </Link>
    );
  } else {
    return <button {...props}>{props.children}</button>;
  }
};

export const Button = props => {
  const classNames = classnames(styles.button, {
    [styles[props.state]]: props.state,
    [styles.primary]: props.primary,
    [styles.light]: props.light,
    [styles.white]: props.white,
    [styles.full]: props.full,
    [styles.loading]: props.loading,
    [styles.minimal]: props.minimal,
    [props.className]: props.className,
  });

  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <Element href={props.href} onClick={onClick} className={classNames} disabled={props.disabled}>
      <span className={styles.innerButton}>
        {props.icon && <span className={styles.icon}>{props.icon}</span>}
        <span>{props.children}</span>
      </span>
      {props.loading && (
        <i className={styles.loadingIcon}>
          <LoadingCircle small color={props.light || props.primary ? 'light' : 'dark'} />
        </i>
      )}
    </Element>
  );
};

export default Button;
