import {
  SETTINGS
} from '../../config';
import {
  API
} from 'aws-amplify';
import store from '../../store';
import * as Message from '../../components/message';

export const getPaymentIntent = email => {
  return new Promise((resolve, reject) => {
    let path = `/payments/get/donate`;
    if (email) {
      path = `${path}/${email}`;
    }
    API.get(SETTINGS.dynamodb.apiName, path, null)
      .then(response => {
        if (response.error) {
          store.dispatch(
            Message.add({
              type: 'error',
              text: response.error.toString(),
            }),
          );
        } else {
          resolve(response);
        }
      })
      .catch(error => {
        store.dispatch(
          Message.add({
            type: 'error',
            text: error.toString(),
          }),
        );
        reject(error);
      });
  });
};