import React, { Component } from 'react';
import {
  getHardnessColor,
  getRideHardness,
  getWindHardnessColor,
  getWeatherIcon,
  normalizeDate,
  convertToUserPref,
  userPrefUnit,
  getRideID,
} from '../../utils';
import styles from './forecast-item.module.scss';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import inView from 'in-view';
import { degToCompass } from '../../utils';
import { SETTINGS } from '../../config';
import HourlyWind from '../hourly-wind';
import WindArrow from '../wind-arrow';

const Item = posed.div({
  visible: {
    opacity: 1,
    transform: 'translateY(0px) scale(1)',
  },
  hidden: {
    opacity: 0,
    transform: 'translateY(100px) scale(0.8)',
  },
});

const ForecastItemHolder = props => {
  if (props.to) {
    return (
      <Link className={styles.container} style={{ color: props.color }} to={props.to}>
        {props.children}
      </Link>
    );
  } else {
    return (
      <div className={styles.container} style={{ color: props.color }}>
        {props.children}
      </div>
    );
  }
};

class ForecastItem extends Component {
  constructor(props) {
    super(props);

    this.date = normalizeDate(props.weather.time * 1000);
    this.time = format(this.date, `yyyy-MM-dd'T'HH':00:00'`);
    this.niceTime = format(this.date, 'EEE MMMM do');
    this.forecastDay = parseInt(format(this.date, 'dd'));
    this.currentDay = parseInt(format(normalizeDate(), 'dd'));
    this.hour = props.time ? format(props.time, 'HH:mm') : format(this.date, 'HH:mm');

    if (this.currentDay === this.forecastDay) {
      this.niceTime = 'Today';
    } else if (this.currentDay + 1 === this.forecastDay) {
      this.niceTime = 'Tomorrow';
    }

    this.url = props.ride ? `/${props.type}/${getRideID(props.ride)}/${this.time}` : false;

    const hardness = this.getHardness(props);
    const color = this.getColor(props, hardness);

    this.state = {
      visible: false,
      color,
      hardness,
    };

    this.mounted = null;
  }

  getColor(props, hardness = false) {
    if (!hardness && this.state) {
      hardness = this.state.hardness;
    }
    if (hardness === false) {
      return getWindHardnessColor(props.weather.windSpeed);
    } else {
      return getHardnessColor(hardness, 1, 0, SETTINGS.hardness.outof);
    }
  }

  getHardness(props) {
    return props.ride ? getRideHardness(props.ride, props.weather) : false;
  }

  componentDidMount() {
    this.mounted = true;
    inView(`.forecast-item-${this.forecastDay}`).once('enter', () => {
      this.mounted && this.setState({ visible: true });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(props) {
    this.setState({
      hardness: this.getHardness(props),
      color: this.getColor(props),
    });
  }

  render() {
    const dark = this.props.dark ? styles.dark : '';
    const slim = this.props.slim ? styles.slim : '';
    const temp = this.props.weather.apparentTemperature || this.props.weather.apparentTemperatureHigh;
    return (
      <Item
        pose={this.state.visible ? 'visible' : 'hidden'}
        className={`forecast-item-${this.forecastDay} ${dark} ${slim} ${styles.outerContainer}`}
      >
        <ForecastItemHolder className={styles.container} color={this.state.color} to={this.url}>
          <div className={styles.header}>
            {!this.props.slim && (
              <div className={styles.headerIcon}>
                <img src={getWeatherIcon(this.props.weather)} alt="weather icon" />
              </div>
            )}
            <div className={styles.headerTitle}>
              {this.niceTime} at {this.hour}
              <span className={styles.headerSummary}>{this.props.weather.summary}</span>
            </div>
          </div>

          <div className={styles.body}>
            {this.props.slim && (
              <div className={styles.stat}>
                <div className={styles.headerIcon}>
                  <img src={getWeatherIcon(this.props.weather)} alt="weather icon" />
                </div>
              </div>
            )}

            <div className={styles.stat}>
              <span className={styles.statLabel}>Temp</span>
              <span className={styles.statValue}>{convertToUserPref(temp, 'temperature')}</span>
              <span className={styles.statLabel}>{userPrefUnit('temperature')}</span>
            </div>

            <div className={styles.stat}>
              <span className={styles.statLabel}>Rain</span>
              <span className={styles.statValue}>
                {convertToUserPref(this.props.weather.precipIntensity * 24, 'rainfall')}
              </span>
              <span className={styles.statLabel}>{userPrefUnit('rainfall')}</span>
            </div>

            <div className={styles.stat}>
              <span className={styles.statLabel}>Wind Dir</span>
              <span className={styles.statValue}>
                <WindArrow dir={this.props.weather.windBearing} color={this.state.color} small={this.props.slim} />
              </span>
              <span className={styles.statLabel}>{degToCompass(this.props.weather.windBearing)}</span>
            </div>

            <div className={styles.stat}>
              <span className={styles.statLabel}>Wind Speed</span>
              <span className={styles.statValue}>{convertToUserPref(this.props.weather.windSpeed, 'distance')}</span>
              <span className={styles.statLabel}>{userPrefUnit('speed')}</span>
            </div>

            {!this.props.ride && (
              <div className={styles.stat}>
                <span className={styles.statLabel}>Wind Gust</span>
                <span className={styles.statValue}>{convertToUserPref(this.props.weather.windGust, 'distance')}</span>
                <span className={styles.statLabel}>{userPrefUnit('speed')}</span>
              </div>
            )}

            {this.props.ride && (
              <div className={`${styles.stat} ${alert && styles.alert}`}>
                <span className={styles.statLabel}>Difficulty</span>
                <span className={styles.statValue}>{this.state.hardness}</span>
                <span className={styles.statLabel}>out of {SETTINGS.hardness.outof}</span>
              </div>
            )}
          </div>

          {this.props.hourly && <HourlyWind weather={this.props.hourly} ride={this.props.ride} showActive={false} />}
        </ForecastItemHolder>
      </Item>
    );
  }
}

export default ForecastItem;
