import { BufferGeometry, Float32BufferAttribute, ShaderMaterial, Color, TextureLoader, Vector3, Clock } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import {
  projectPoint,
  randomBetween,
  randomFloatBetween,
  visibleHeightAtZDepth,
  visibleWidthAtZDepth,
  smoothLine,
  isAccrossLine,
  getLineAngle,
  getDistance,
} from './utils';
import { Circle } from '@react-three/drei';
import fragment from './fragment';
import vertex from './vertex';
import { tearTexture } from './particle-texture';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clamp, degToRad } from 'three/src/math/MathUtils';
import { usePrevious } from '../../utils';
import { Effects } from './effects';

export const Wind = ({ bearing, speed, onChange, debug = false, fps = 60 }) => {
  const [particles, setParticles] = useState(null);
  const [particleGeometry, setParticleGeometry] = useState(null);
  const prevBearing = usePrevious(bearing);
  const prevSpeed = usePrevious(speed);

  const FPS = useMemo(() => {
    return fps;
  }, [fps]);

  const material = useRef(
    new ShaderMaterial({
      uniforms: {
        pointTexture: { value: new TextureLoader().load(tearTexture) },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      vertexColors: true,
      depthTest: false,
      transparent: true,
    }),
  );

  const windBearing = useMemo(() => {
    if (bearing < 180) {
      return -(bearing + 180);
    } else {
      return -(bearing - 180);
    }
  }, [bearing]);

  // create the particle variables
  const maxParticleCount = 2000;
  const particleCount = clamp(speed * 50, 0, maxParticleCount);
  const particleSize = 0.6;
  const maxParticleSpeed = 0.1;
  const minParticleSpeed = 0.001;
  const particleSpeed = clamp(speed / 3000, minParticleSpeed, maxParticleSpeed);
  const particleDepth = 6;
  const maxParticleOpacity = 0.4;
  const minParticleOpacity = 0.1;
  const particleTrailSize = 0.9;
  // const particleColors = ['#09A9FF', '#ff00ff'];
  const particleColors = ['#b2b2b2'];
  const palette = particleColors.map(c => new Color(c));
  const scale = 1;
  const padding = 0.1;

  // container width and height
  const { camera } = useThree();
  const width = visibleWidthAtZDepth(0, camera) * scale;
  const height = visibleHeightAtZDepth(0, camera) * scale;

  // create the line options
  const maxDistance = Math.sqrt(width * width + height * height);
  const curveSize = 0.5;
  const midCurveSize = 0.2;
  const curveAngleModifier = 10;
  const rowGap = 0.1;
  const noOfParticleRows = Math.round(maxDistance / rowGap);

  const getLineBoundingBox = useCallback(
    line => {
      const box = {
        top: height / 2,
        bottom: -(height / 2),
        left: -(width / 2),
        right: width / 2,
      };
      line.forEach(p => {
        if (p.x < box.left) {
          box.left = p.x - padding;
        }
        if (p.x > box.right) {
          box.right = p.x + padding;
        }
        if (p.y < box.top) {
          box.top = p.y - padding;
        }
        if (p.y > box.bottom) {
          box.bottom = p.y + padding;
        }
      });
      return box;
    },
    [height, width],
  );

  const createParticlePath = useCallback(
    windBearing => {
      // always want an odd lineLingth
      let length = 7;
      const center = { x: 0, y: 0 };
      let polyline = new Array(length).fill(0);
      const midIndex = Math.floor(polyline.length / 2);
      polyline = polyline.map((_, i) => {
        const distance = (maxDistance / 2 / midIndex) * (i - midIndex);
        let point = projectPoint(center, windBearing, distance);
        if (i === 1 || i === polyline.length - 2) {
          let angle = windBearing + 90;
          angle = randomFloatBetween(angle - curveAngleModifier, angle + curveAngleModifier);
          let dist = randomFloatBetween(-curveSize, curveSize);
          point = projectPoint(point, angle, dist);
        } else if (i === midIndex) {
          const angle = windBearing + 90;
          const dist = randomFloatBetween(-midCurveSize, midCurveSize);
          point = projectPoint(point, angle, dist);
        }
        return new Vector3(point.x, point.y, 0);
      });
      // return polyline;
      const smoothedPolyline = smoothLine(polyline, 2);
      return smoothedPolyline;
    },
    [maxDistance],
  );

  // create particle start points
  const createStartPoints = useCallback(line => {
    const minDist = 0.1;
    const startPoints = [];
    line.forEach((point, i) => {
      const nextPoint = line[i + 1];
      if (nextPoint) {
        const dist = getDistance(point, nextPoint);
        if (dist > minDist) {
          const no = dist / minDist;
          const angle = getLineAngle(point, nextPoint);
          for (let i = 0; i <= no; i++) {
            const newPoint = projectPoint(point, angle - 90, minDist * i);
            startPoints.push(new Vector3(newPoint.x, newPoint.y, 0));
          }
        } else {
          startPoints.push(point);
        }
      } else {
        startPoints.push(point);
      }
    });
    return startPoints;
  }, []);

  // create the line
  const line = useMemo(() => {
    return createParticlePath(windBearing);
  }, [windBearing, createParticlePath]);

  const bounds = useMemo(() => {
    return getLineBoundingBox(line);
  }, [line, getLineBoundingBox]);

  const lineGeometry = useMemo(() => {
    return new BufferGeometry().setFromPoints(line);
  }, [line]);

  const startPoints = useMemo(() => {
    return createStartPoints(line);
  }, [line, createStartPoints]);

  // create the particles
  const createParticles = useCallback(() => {
    // store the particle properties
    const positions = [];
    const colors = [];
    const sizes = [];
    const speeds = [];
    const opacities = [];
    const rows = [];
    const offsets = [];
    const rotations = [];

    // now create the individual particles
    for (var p = 0; p < particleCount; p++) {
      const startIndex = randomBetween(0, startPoints.length - 1);
      const x = startPoints[startIndex].x;
      const y = startPoints[startIndex].y;
      const z = randomFloatBetween(-particleDepth / 2, particleDepth / 2);
      positions.push(x, y, z);

      const row = p % noOfParticleRows;
      rows.push(row === 0 ? -0.5 : row);
      rows.push(row);

      // opacity based on z index
      // const opacity = 1 - (((z + particleDepth / 2) / particleDepth) * 100) / 100;
      const opacity = randomFloatBetween(minParticleOpacity, maxParticleOpacity);
      opacities.push(opacity);

      const color = palette[randomBetween(0, palette.length - 1)];
      colors.push(color.r, color.g, color.b);

      const speedModifier = randomFloatBetween(-particleSpeed / 10, particleSpeed / 10, 10);
      // seems like division by 1000 gives a good diff between the
      // particle speeds at diff z indexes
      // @TODO: work out a more rational way of setting particle speeds
      const speed = clamp(particleSpeed + speedModifier + z / 1000, minParticleSpeed, maxParticleSpeed);
      speeds.push(speed);

      const offset = randomFloatBetween(-rowGap, rowGap);
      offsets.push(offset);

      sizes.push(particleSize);

      // arrow image
      // rotations.push(degToRad(-windBearing) - degToRad(180));
      // tear image
      rotations.push(degToRad(-windBearing));
    }

    return {
      positions,
      colors,
      sizes,
      speeds,
      opacities,
      rows,
      offsets,
      rotations,
    };
  }, [windBearing, noOfParticleRows, palette, startPoints]);

  const createParticleGeometry = useCallback(() => {
    const particleGeometry = new BufferGeometry();
    particleGeometry.dynamic = true;
    particleGeometry.setAttribute('position', new Float32BufferAttribute(particles.positions, 3));
    particleGeometry.setAttribute('linePosition', new Float32BufferAttribute(particles.positions, 3));
    particleGeometry.setAttribute('color', new Float32BufferAttribute(particles.colors, 3));
    particleGeometry.setAttribute('size', new Float32BufferAttribute(particles.sizes, 1));
    particleGeometry.setAttribute('alpha', new Float32BufferAttribute(particles.opacities, 1));
    particleGeometry.setAttribute('rotation', new Float32BufferAttribute(particles.rotations, 1));
    return particleGeometry;
  }, [particles]);

  useEffect(() => {
    if (bearing !== prevBearing || speed !== prevSpeed) {
      setParticles(createParticles());
      onChange && onChange(particleSpeed, particleCount);
    }
  }, [bearing, speed, createParticles, prevBearing]);

  useEffect(() => {
    if (particles) {
      setParticleGeometry(createParticleGeometry());
    }
  }, [particles, createParticleGeometry, setParticleGeometry]);

  const animate = delta => {
    if (!particleGeometry || !particles) {
      return false;
    }
    const positions = particleGeometry.attributes.position.array;
    const linePositions = particleGeometry.attributes.linePosition.array;
    const rotations = particleGeometry.attributes.rotation.array;
    let index = 0;
    linePositions.forEach((_, i) => {
      if (i % 3 === 0) {
        const particle = {
          x: i,
          y: i + 1,
          z: i + 2,
        };
        let pos = {
          x: linePositions[particle.x],
          y: linePositions[particle.y],
        };
        if (pos.x < bounds.left || pos.x > bounds.right || pos.y < bounds.top || pos.y > bounds.bottom) {
          linePositions[particle.x] = line[0].x;
          linePositions[particle.y] = line[0].y;
          index++;
          return;
        }
        let to;
        let angle = windBearing;
        let speed = particles.speeds[index];
        // factor in frame rate to distance moved each frame
        speed = speed + speed * 60 * delta;

        const isSection = line.some((n, i) => {
          const start = projectPoint(n, windBearing - 90, maxDistance);
          const end = projectPoint(n, windBearing - 90, -maxDistance);
          const section = [start, end];

          if (!isAccrossLine(pos, section)) {
            angle = windBearing - 90;
            if (line[i - 1]) {
              angle = getLineAngle(n, line[i - 1]);
            }
            to = projectPoint(pos, angle + 90, speed);
            return true;
          }
          return false;
        });

        if (!isSection) {
          to = projectPoint(pos, windBearing, speed);
        }

        linePositions[particle.x] = to.x;
        linePositions[particle.y] = to.y;
        rotations[index] = degToRad(-angle - 90);

        positions[particle.x] = to.x;
        positions[particle.y] = to.y;

        let rowPos = to;
        const dist = particles.rows[index] * rowGap + particles.offsets[index];
        if (particles.rows[index] % 2 === 0) {
          rowPos = projectPoint(rowPos, windBearing - 90, dist);
        } else {
          rowPos = projectPoint(rowPos, windBearing - 90, -dist);
        }

        const z = linePositions[particle.z];
        // dont know why this works but it does
        // magic number is 0.2 every scale increase of 1
        // @TODO: work out why the magic number is 0.2
        const ratio = 1 - 0.2 * z;
        positions[particle.x] = rowPos.x * ratio;
        positions[particle.y] = rowPos.y * ratio;

        index++;
      }
    });
    particleGeometry.attributes.position.needsUpdate = true;
    particleGeometry.attributes.rotation.needsUpdate = true;
  };

  // ANIMATION LOOP
  let timePassed = 0;
  useFrame((state, delta) => {
    timePassed += delta;
    if (timePassed > 60 / FPS / 60) {
      animate(timePassed > 1 ? 0 : timePassed);
      timePassed = 0;
    }
  });

  return (
    <>
      {debug && (
        <>
          <line geometry={lineGeometry}>
            <lineBasicMaterial attach="material" color="#e1ff00" linewidth={2} />
          </line>
          {startPoints.map((node, i) => (
            <Circle key={`startPoint-${i}`} args={[0.03, 10]} position={node}>
              <meshBasicMaterial color="#e1ff00" />
            </Circle>
          ))}
          {line.map((node, i) => (
            <Circle key={`node-${i}`} args={[0.05, 10]} position={node}>
              <meshBasicMaterial color="#ff3d3d" />
            </Circle>
          ))}
        </>
      )}
      {particleGeometry && <points args={[particleGeometry, material.current]} sortParticles={true} />}
      <Effects trailLength={particleTrailSize} />
    </>
  );
};
