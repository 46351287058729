import React, { Component } from 'react';
import styles from './bottom-drawer.module.scss';
import { FiChevronDown, FiChevronUp, FiInfo, FiMoreVertical } from 'react-icons/fi';
import posed from 'react-pose';
import classnames from 'classnames';
import vars from '../../style-utils/variables.module.scss';
import Tabs, { TabContent } from '../tabs';

const Drawer = posed.div({
  open: {
    y: props => `calc(100% - ${props.aboveFoldHeight}px)`,
  },
  closed: {
    y: '100%',
  },
});

class BottomDrawer extends Component {
  aboveFoldRef = React.createRef();
  dragRef = React.createRef();
  toggleRef = React.createRef();
  distanceToActivate = 25;
  scale = 0.95;
  amountOfContentVisible = this.props.children ? 22 : 0;
  amountOfOverDrag = 50;
  tabHeight = this.props.secondaryTab ? parseInt(vars.tabHeightSlim) : 0;

  constructor(props) {
    super(props);
    this.state = {
      aboveFoldHeight: 0,
      aboveFoldRefHeight: 0,
      ready: this.props.showInfo || false,
      startFingerPosition: 0,
      startDragPosition: 0,
      position: 0,
      open: false,
      direction: 'up',
      dragging: false,
      dragHeight: 0,
      canDrag: false,
      distance: 0,
      tooTallForScreen: false,
      activeTab: props.activeTab,
      secondaryTabHeight: 0,
    };
  }

  componentDidMount() {
    if (this.aboveFoldRef.current && this.dragRef.current) {
      this.setState({
        aboveFoldHeight: this.getAboveFoldHeight(),
        aboveFoldRefHeight: this.aboveFoldRef.current.clientHeight,
        dragHeight: this.dragRef.current.clientHeight,
      });
    }
    if (typeof this.props.ready === 'undefined') {
      setTimeout(() => {
        this.setState({ ready: true });
      }, 1000);
    }
    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.onDragEnd);
    window.addEventListener('touchmove', this.onDrag);
    window.addEventListener('touchend', this.onDragEnd);

    this.props.onReady && this.props.onReady(this.toggleOpen);
  }

  getAboveFoldHeight = () => {
    return (
      this.aboveFoldRef.current.clientHeight +
      this.amountOfContentVisible +
      this.toggleRef.current.clientHeight +
      this.tabHeight
    );
  };

  setSecondaryTabHeight = () => {
    let secondaryTabHeight = 0;
    if (!this.state.open) {
      secondaryTabHeight = this.state.aboveFoldRefHeight + this.amountOfContentVisible;
    } else if (this.state.open && this.state.tooTallForScreen) {
      secondaryTabHeight =
        window.innerHeight - parseInt(vars.headerHeight) - this.tabHeight - this.toggleRef.current.clientHeight;
    } else if (this.state.open) {
      secondaryTabHeight = this.state.dragHeight - this.amountOfContentVisible - this.tabHeight;
    }
    this.setState({
      secondaryTabHeight,
    });
  };

  onDrawerReady = () => {
    if (this.dragRef.current) {
      this.setState({
        dragHeight: this.dragRef.current.clientHeight,
      });
    }
    this.setSecondaryTabHeight();
  };

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.onDragEnd);
    window.removeEventListener('touchmove', this.onDrag);
    window.removeEventListener('touchend', this.onDragEnd);
    window.removeEventListener('resize', this.onResize);
  }

  componentWillReceiveProps(props) {
    this.setState({ ready: props.ready });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      this.state.open && this.props.onOpen && this.props.onOpen();
      !this.state.open && this.props.onClose && this.props.onClose();
      this.setSecondaryTabHeight();
    }
    if (this.props.activeTab !== this.state.activeTab) {
      this.setState({ activeTab: this.props.activeTab });
    }
  }

  render() {
    const classNames = classnames(styles.dragable, {
      [styles.dragging]: this.state.dragging,
      [styles.tooTallForScreen]: this.state.tooTallForScreen && this.state.open && !this.state.activeTab,
    });

    const toggleClassNames = classnames(styles.toggle, {
      [styles.toggleHidden]: !this.props.children && !this.props.tabs,
    });

    return (
      <div className={styles.container} id="bottomDrawer">
        <Drawer
          className={styles.drawer}
          pose={this.state.ready ? 'open' : 'closed'}
          aboveFoldHeight={this.state.aboveFoldHeight}
          onPoseComplete={this.onDrawerReady}
        >
          <div onTouchStart={this.onDragStart} onMouseDown={this.onDragStart} className={classNames} ref={this.dragRef}>
            <div className={toggleClassNames} onClick={this.toggleOpen} ref={this.toggleRef}>
              {this.renderArrow()}
            </div>
            {this.props.secondaryTab && this.renderTabContent()}
            {!this.props.secondaryTab && this.renderContent()}
          </div>
        </Drawer>
      </div>
    );
  }

  renderTabContent = () => {
    return (
      <Tabs
        stretch
        slim
        swipable={false}
        navClass={styles.tabNav}
        onChange={this.onTabChange}
        active={this.props.activeTab || 0}
      >
        <TabContent title={this.props.primaryTabTitle || 'Info'} icon={this.props.primaryTabIcon || <FiInfo />}>
          {this.renderContent()}
        </TabContent>
        <TabContent
          hidden={this.props.hideSecondaryTab}
          title={this.props.secondaryTabTitle || 'More'}
          icon={this.props.secondaryTabIcon || <FiMoreVertical />}
        >
          <div
            className={`${styles.secondaryTabContent} iosScrollable`}
            style={{ height: `${this.state.secondaryTabHeight}px` }}
          >
            {this.props.secondaryTab}
          </div>
        </TabContent>
        <TabContent
          hidden={this.props.hideTertiaryTab}
          title={this.props.tertiaryTabTitle || 'More'}
          icon={this.props.tertiaryTabIcon || <FiMoreVertical />}
        >
          <div
            className={`${styles.tertiaryTabContent} iosScrollable`}
            style={{ height: `${this.state.secondaryTabHeight}px` }}
          >
            {this.props.tertiaryTab}
          </div>
        </TabContent>
      </Tabs>
    );
  };

  renderContent = () => {
    return (
      <>
        <div className={styles.aboveFold} ref={this.aboveFoldRef}>
          {this.props.visible}
        </div>
        <div className={styles.belowFold}>{this.props.children}</div>
      </>
    );
  };

  renderArrow = () => {
    if (this.state.dragging) {
      if (this.state.direction === 'up') {
        return <FiChevronDown />;
      } else {
        return <FiChevronUp />;
      }
    } else {
      if (this.state.open) {
        return <FiChevronDown />;
      } else {
        return <FiChevronUp />;
      }
    }
  };

  onTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
    this.setSecondaryTabHeight();
    this.props.onTabChange && this.props.onTabChange(tab);
  };

  toggleOpen = () => {
    if (this.dragRef.current) {
      let position = 0;
      let direction = 'down';
      if (!this.state.open) {
        position = this.getOpenPosition();
        direction = 'up';
      }
      this.setState({
        open: !this.state.open,
        position,
        dragging: false,
        canDrag: false,
        direction,
        distance: this.distanceToActivate,
      });
      this.dragRef.current.style.transform = `translateY(${position}px) scale(1)`;
    }
  };

  onDragStart = e => {
    if (this.state.activeTab) {
      return false;
    }
    this.setState({
      startDragPosition: this.state.position,
      startFingerPosition: this.getFingerPosition(e),
      canDrag: true,
    });
    if (this.dragRef.current) {
      this.dragRef.current.style.transform = `translateY(${this.state.position}px) scale(1)`;
    }
  };

  onDragEnd = e => {
    if (this.state.activeTab) {
      return false;
    }
    let position = this.state.startDragPosition;
    let open = this.state.open;
    if (this.dragRef.current) {
      if (this.state.distance >= this.distanceToActivate) {
        const closePoint = this.getAboveFoldHeight() / 2;
        if (this.state.position < 0 && this.state.direction === 'up') {
          position = this.getOpenPosition();
          open = true;
        } else if (this.state.position > closePoint) {
          position = this.getClosedPosition();
          open = false;
        } else {
          position = 0;
          open = false;
        }
      }
      this.dragRef.current.style.transform = `translateY(${position}px) scale(1)`;
    }
    this.setState({ dragging: false, canDrag: false, position, open });
  };

  onDrag = e => {
    if (this.state.activeTab) {
      return false;
    }
    e.preventDefault();
    if (this.state.canDrag && this.dragRef.current) {
      const lastPosition = this.state.position;
      const dragAmount = this.state.startFingerPosition - this.getFingerPosition(e);
      const distance = Math.abs(dragAmount);
      if (distance >= this.distanceToActivate) {
        let position = -dragAmount + this.state.startDragPosition;
        if (position < this.getOpenPosition() - this.amountOfOverDrag)
          position = this.getOpenPosition() - this.amountOfOverDrag;
        let direction = this.state.direction;
        if (lastPosition - position > 0) {
          direction = 'up';
        } else if (lastPosition - position < 0) {
          direction = 'down';
        }
        // console.log(dragAmount, direction, distance, position, this.state.startDragPosition);
        this.dragRef.current.style.transform = `translateY(${position}px) scale(${this.scale})`;
        this.setState({
          direction,
          position,
          distance,
          dragging: true,
        });
      }
    }
  };

  getOpenPosition = () => {
    let position = 0;
    const aboveFold = this.state.activeTab ? this.state.aboveFoldRefHeight : this.aboveFoldRef.current.clientHeight;
    if (this.state.dragHeight + this.toggleRef.current.clientHeight + this.tabHeight >= window.innerHeight) {
      position =
        -(window.innerHeight - aboveFold) +
        this.amountOfContentVisible +
        parseInt(vars.headerHeight) +
        this.tabHeight +
        this.toggleRef.current.clientHeight;
      this.setState({ tooTallForScreen: true });
    } else {
      position =
        -(this.state.dragHeight - aboveFold) +
        this.amountOfContentVisible +
        this.tabHeight +
        this.toggleRef.current.clientHeight;
      this.setState({ tooTallForScreen: false });
    }
    return position;
  };

  getClosedPosition = () => {
    const aboveFoldHeight = this.getAboveFoldHeight();
    return aboveFoldHeight - this.toggleRef.current.clientHeight - this.amountOfContentVisible;
  };

  getFingerPosition = e => {
    return e.touches ? e.touches[0].clientY : e.clientY;
  };

  getDrawerPosition = () => {
    if (!this.dragRef.current) {
      return 0;
    }
    return this.dragRef.current.getBoundingClientRect().y;
  };
}

export default BottomDrawer;
