import React, { Component } from 'react';
import * as strava from '../../api/strava';
import * as DS from '../../api/darksky';
import { LoadingView } from '../../views';
import { setTitle } from '../../components/header/actions';
import SummaryItem from '../../components/summary-item';
import ForecastItem from '../../components/forecast-item';
import store from '../../store';
import { setLoading } from '../../store/actions';
import styles from './forecast.module.scss';
import * as polyline from 'google-polyline';
import { getCurrentWeather, getUserSettings } from '../../utils';
import Tabs, { TabContent } from '../../components/tabs';
import { MdAccessTime } from 'react-icons/md';
import format from 'date-fns/format';
import DatePickerModal from '../../components/datepicker';
import IconButton from '../../components/icon-button';
import { SETTINGS } from '../../config';

export class ForecastView extends Component {
  constructor(props) {
    const { commute } = getUserSettings();
    super(props);
    this.state = {
      ride: null,
      weather: null,
      icon: null,
      hardness: null,
      type: this.getType(props),
      rideHour: null,
      customHours: [],
      timePickerOpen: false,
      activeTab: 0,
      overCustomTimeButton: [false, false],
    };
    this.commute = commute || SETTINGS.defaultUserSettings.commute;
    this.mounted = null;
  }

  componentDidMount() {
    this.mounted = true;
    this.init(this.state.type, true);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(props) {
    const type = this.getType(props);
    this.init(type);
  }

  getType(props) {
    return props.match.params.type || 'general';
  }

  init(type, force = false) {
    if (type !== this.state.type || force) {
      this.setState({ type });
      if (type !== 'general') {
        // if ride or route
        this.initRideForecast(this.props.match.params.id);
        // else general generic forecast
      } else {
        this.initGeneralForecast();
      }
    }
  }

  initRideForecast(id) {
    const apiCall = this.state.type === 'route' ? strava.getRoute : strava.getRide;
    const ride = apiCall(id);
    ride.cached.then(ride => {
      this.gotRide(ride);
    });
    ride.latest.then(ride => {
      this.gotRide(ride);
    });
  }

  gotRide = ride => {
    if (!this.mounted) {
      return false;
    }
    const rideHour = ride.start_date ? format(ride.start_date, 'HH:00') : null;
    this.setState({
      ride,
      rideHour,
      activeTab: rideHour ? 0 : 1,
    });
    store.dispatch(setTitle(`${ride.name} forecast`));
    let line = ride.map.polyline ? ride.map.polyline : ride.map.summary_polyline;
    line = polyline.decode(line);
    const center = line[Math.floor(line.length / 2)];
    DS.getWeather({
      lat: center[0],
      lng: center[1],
      hourly: true,
    }).then(weather => {
      if (this.mounted) {
        this.setState({ weather });
        setLoading(false);
      }
    });
  };

  initGeneralForecast() {
    store.dispatch(setTitle('7 day forecast'));
    getCurrentWeather(true).then(weather => {
      if (this.mounted) {
        this.setState({
          weather,
          ride: null,
          activeTab: 1,
        });
        setLoading(false);
      }
    });
  }

  getWeatherForHour = hour => {
    const hourKey = hour.split(':')[0];
    const weather = this.state.weather || {};
    const days = [];
    Object.keys(weather.hourly.data).forEach((key, i) => {
      const h = weather.hourly.data[key][hourKey] || null;
      if (h) {
        days.push(h);
      }
    });
    days.sort((a, b) => a.time - b.time);
    return days;
  };

  getTabTitle = type => {
    return (
      <>
        {type} <span className={styles.tabExtraInfo}>Commute</span>
      </>
    );
  };

  openTimePicker = () => {
    this.setState({
      timePickerOpen: true,
    });
  };

  onTimePickerClose = () => {
    this.setState({
      timePickerOpen: false,
    });
  };

  onPickTime = time => {
    this.onTimePickerClose();
    const hour = format(time, 'HH:00');
    const customHours = [...this.state.customHours];
    customHours[this.state.activeTab] = hour;
    this.setState({
      customHours,
    });
  };

  onTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  onMouseOverCustomTime = no => {
    const overCustomTimeButton = [...this.state.overCustomTimeButton];
    overCustomTimeButton[no] = true;
    this.setState({
      overCustomTimeButton,
    });
  };

  onMouseOutCustomTime = no => {
    const overCustomTimeButton = [...this.state.overCustomTimeButton];
    overCustomTimeButton[no] = false;
    this.setState({
      overCustomTimeButton,
    });
  };

  render() {
    const weather = this.state.weather || null;
    const ride = this.state.ride;
    if (!ride && this.state.type !== 'general') {
      return <LoadingView message="Loading Ride..." />;
    } else if (!weather) {
      return <LoadingView message="Loading Weather..." />;
    } else {
      // console.log(weather.daily.data);
      return (
        <>
          {this.renderKey()}
          <Tabs stretch={true} navClass={styles.tabNav} onChange={this.onTabChange}>
            <TabContent title="Ride time" icon={<MdAccessTime />} hidden={this.state.rideHour ? false : true}>
              {this.state.rideHour && <>{this.renderTabContent(this.state.customHours[0] || this.state.rideHour)}</>}
            </TabContent>
            <TabContent title={this.getTabTitle('Morning')} icon={<MdAccessTime />}>
              {this.renderTabContent(this.state.customHours[1] || this.commute.morning)}
            </TabContent>
            <TabContent title={this.getTabTitle('Afternoon')} icon={<MdAccessTime />}>
              {this.renderTabContent(this.state.customHours[2] || this.commute.afternoon)}
            </TabContent>
          </Tabs>
          <DatePickerModal
            open={this.state.timePickerOpen}
            timeOnly={true}
            onChange={this.onPickTime}
            onClose={this.onTimePickerClose}
          />
        </>
      );
    }
  }

  renderCustomTimeButton = no => {
    return (
      <div
        className={styles.customTimeButton}
        onClick={this.openTimePicker}
        onMouseOver={this.onMouseOverCustomTime.bind(this, no)}
        onMouseOut={this.onMouseOutCustomTime.bind(this, no)}
      >
        <span>Change Time</span>
        <IconButton simple dark small state={this.state.overCustomTimeButton[no] ? 'hover' : ''}>
          <MdAccessTime />
        </IconButton>
      </div>
    );
  };

  renderTabContent = hour => {
    return (
      <div className={styles.container}>
        <h2 className={styles.title}>
          Daily Summary for {hour} {this.renderCustomTimeButton(0)}
        </h2>
        <div className={styles.summaryContainer}>{this.renderSummary(hour)}</div>
        <h2 className={styles.title}>
          Daily Forecast for {hour} {this.renderCustomTimeButton(1)}
        </h2>
        <div className={styles.forecastContainer}>{this.renderForecastList(hour)}</div>
      </div>
    );
  };

  renderKey() {
    return (
      <div className={styles.key}>
        {this.state.type === 'general' && (
          <>
            <div className={styles.keyItem}>
              <span>Strong Wind</span>
              <span className={`${styles.keySwatch} ${styles.keySwatchStrong}`} />
            </div>
            <div className={styles.keyItem}>
              <span>Light Wind</span>
              <span className={`${styles.keySwatch} ${styles.keySwatchLight}`} />
            </div>
          </>
        )}
        {this.state.type !== 'general' && (
          <>
            <div className={styles.keyItem}>
              <span>Headwind</span>
              <span className={`${styles.keySwatch} ${styles.keySwatchHead}`} />
            </div>
            <div className={styles.keyItem}>
              <span>Tailwind</span>
              <span className={`${styles.keySwatch} ${styles.keySwatchTail}`} />
            </div>
          </>
        )}
      </div>
    );
  }

  renderSummary(hour) {
    const weather = this.getWeatherForHour(hour);
    return (
      <>
        {weather.map(hour => {
          return (
            <SummaryItem key={hour.time} weather={hour} ride={this.state.ride} type={this.state.type} dark={true} />
          );
        })}
      </>
    );
  }

  renderForecastList(hour) {
    const weather = this.getWeatherForHour(hour);
    return (
      <>
        {weather.map((hour, i) => {
          return (
            <ForecastItem key={hour.time} weather={hour} ride={this.state.ride} type={this.state.type} dark={true} />
          );
        })}
      </>
    );
  }
}
