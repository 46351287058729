import { loadStripe } from '@stripe/stripe-js';
import { SETTINGS } from '../config';
import { getEnv } from '../utils';

export const initStripe = () => {
  const env = getEnv();
  switch (env) {
    case 'prod':
      return loadStripe(SETTINGS.stripe.apikey.live);
    default:
      return loadStripe(SETTINGS.stripe.apikey.test);
  }
};
