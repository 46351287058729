const initialState = {
  loading: false,
  loadingBar: false,
  installPrompt: false,
  fontLoaded: false,
  layout: 'mobile',
  shared: window.location.pathname.includes('/share/') || window.location.pathname.includes('/embed/'),
  version: localStorage.getItem('version') || null,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INSTALL_PROMPT':
      return {
        ...state, installPrompt: action.payload
      };
    case 'SET_LOADING':
      // console.log('setting loading', action.payload)
      return {
        ...state, loading: action.payload
      };
    case 'SET_LOADING_BAR':
      return {
        ...state, loadingBar: action.payload
      };
    case 'SET_FONT_LOADED':
      return {
        ...state, fontLoaded: action.payload
      };
    case 'SET_LAYOUT':
      return {
        ...state, layout: action.payload
      };
    case 'UPDATE_SHARED':
      return {
        ...state, shared: window.location.pathname.includes('/share/')
      };
    case 'UPDATE_VERSION':
      localStorage.setItem('version', action.payload);
      return {
        ...state, version: action.payload
      };
    default:
      return state;
  }
};

export default reducer;