
export const requestingToken = (requesting) => ({ type: 'REQUESTING_AUTH_TOKEN', payload: requesting });

export const updateToken = (token) => ({ type: 'UPDATE_AUTH_TOKEN', payload: token });

export const updateUser = (user) => ({ type: 'UPDATE_USER', payload: user });

export const updateStarredRides = (starredRides) => ({ type: 'UPDATE_STARRED_RIDES', payload: starredRides });

export const updateStarredRideIds = (rides) => ({ type: 'UPDATE_STARRED_RIDE_IDS', payload: rides });

export const updateRides = (rides) => ({ type: 'UPDATE_RIDES', payload: rides });

export const updateRoutes = (routes) => ({ type: 'UPDATE_ROUTES', payload: routes });
