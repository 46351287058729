import React, { useState, useEffect, useRef } from 'react';
import styles from './segment-selector.module.scss';
import classnames from 'classnames';
import { MdStar } from 'react-icons/md';
import { AiOutlineNodeIndex } from 'react-icons/ai';
import { IoMdBicycle } from 'react-icons/io';
import store from '../../store';
import { hasDonated, isDemo } from '../../utils';
import { DonateButton } from '../donate-button';
import { Link } from 'react-router-dom';
import { getSegmentsFromRideOrRoute, normalizeDate } from '../../utils';
import * as strava from '../../api/strava';
import { setLoadingBar } from '../../store/actions';
import LoadingCircle from '../loading-circle';

export const SegmentSelector = props => {
  const state = store.getState();
  const [originalRide, setOriginalRide] = useState();
  const [active, setActive] = useState();
  const holder = useRef();
  const [allowed, setAllowed] = useState(false); //useState(props.allowed || hasDonated() || isDemo());
  const [segmentLoading, setSegmentLoading] = useState(false);

  const donateInfo = (
    <>
      Donate to gain access to segment explorer. Your donation is much appreciated and will help keep Headwind free for
      everyone. <a href={`/segment-demo/0/${normalizeDate()}`}>View the Demo.</a>
    </>
  );

  let segments = getSegmentsFromRideOrRoute(props.ride);
  const starredSegments = [];
  const otherSegments = [];

  segments.forEach(segment => {
    if (segment.starred) {
      starredSegments.push(segment);
    } else {
      otherSegments.push(segment);
    }
  });
  segments = [...starredSegments, ...otherSegments];

  useEffect(() => {
    setOriginalRide(props.ride);
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (hasDonated(state.strava.user)) {
        setAllowed(true);
      }
    });
    props.onMount && props.onMount();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setAllowed(props.allowed || hasDonated(state.strava.user) || isDemo());
  }, [props.allowed]);

  const onClick = segment => {
    highlightSegment(segment);
  };

  const highlightSegment = segment => {
    store.dispatch(setLoadingBar(true));
    setSegmentLoading(segment.id);
    if (!allowed || !originalRide) {
      return false;
    }
    const request = strava.getSegment(segment.id);
    request.cached.then(gotSegment);
    request.latest.then(gotSegment);
  };

  const gotSegment = segment => {
    setActive(segment.id);
    store.dispatch(setLoadingBar(false));
    setSegmentLoading(false);
    segment.map = { ...segment.map, original_polyline: originalRide.map.polyline };
    props.onSelect && props.onSelect({ ...originalRide, ...segment, activeSegment: segment.id });
  };

  const reset = () => {
    setActive(null);
    delete props.ride.activeSegment;
    props.onSelect && props.onSelect({ ...originalRide });
  };

  const renderIcon = segment => {
    if (segmentLoading === segment.id) {
      return (
        <div className={styles.loadingCircleHolder}>
          <LoadingCircle small color="dark" />
        </div>
      );
    } else if (segment.starred) {
      return <MdStar />;
    } else {
      return <AiOutlineNodeIndex />;
    }
  };

  const classNames = classnames(styles.container, {
    [styles.notDonated]: !allowed,
    [props.className]: props.className,
  });

  const itemClassNames = classnames(styles.item, {
    [styles.itemActive]: !active,
  });

  if (!props.ride) {
    return null;
  }

  return (
    <div className={`${classNames} iosScrollable`} ref={holder}>
      {!allowed && (
        <div className={styles.donateGate}>
          <div className={styles.donateGateInner}>
            <p>{donateInfo}</p>
            <DonateButton info={donateInfo} infoModalOnly title="Unlock Segments" />
          </div>
        </div>
      )}
      <ul>
        <li className={itemClassNames} onClick={reset}>
          <i className={styles.icon}>
            <IoMdBicycle />
          </i>
          Whole ride
        </li>
        {segments.map(segment => {
          const itemClass = classnames(styles.item, `segment-item-${segment.id}`, {
            [styles.itemActive]: segment.id === active,
          });
          return (
            <li key={segment.id} data-id={segment.id} className={itemClass} onClick={onClick.bind(this, segment)}>
              <i className={styles.icon}>{renderIcon(segment)}</i>
              {segment.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
