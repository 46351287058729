import smooth from 'smooth-polyline';
import { Vector3 } from 'three';

export const projectPoint = (point, angle, distance) => {
  // const a = ((angle + 90) * Math.PI) / 180;
  const a = (angle * Math.PI) / 180 + (90 * Math.PI) / 180;
  const x = point.x + Math.cos(a) * distance;
  const y = point.y + Math.sin(a) * distance;
  return { x, y };
};

export const smoothLine = (line, amount = 1) => {
  let smoothed = line.map(p => [p.x, p.y]);
  for (let i = 0; i <= amount; i++) {
    smoothed = smooth(smoothed);
  }
  return smoothed.map(p => {
    return new Vector3(p[0], p[1], 0);
  });
};

export const randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getDistance = (point1, point2) => {
  return Math.hypot(point2.x - point1.x, point2.y - point1.y);
};

export const randomFloatBetween = (minValue, maxValue, precision = 2) => {
  return parseFloat(Math.min(minValue + Math.random() * (maxValue - minValue), maxValue).toFixed(precision));
};

export const visibleHeightAtZDepth = (depth, camera) => {
  // compensate for cameras not positioned at z=0
  const cameraOffset = camera.position.z;
  if (depth < cameraOffset) depth -= cameraOffset;
  else depth += cameraOffset;

  // vertical fov in radians
  const vFOV = (camera.fov * Math.PI) / 180;

  // Math.abs to ensure the result is always positive
  return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
};

export const visibleWidthAtZDepth = (depth, camera) => {
  const height = visibleHeightAtZDepth(depth, camera);
  return height * camera.aspect;
};

export const getLineAngle = (p1, p2) => {
  let angle = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
  if (angle < 0) {
    angle = 360 + angle;
  }
  return angle;
};

export const isAccrossLine = (p, line) => {
  const a = line[0];
  const b = line[1];
  return (p.x - a.x) * (b.y - a.y) - (p.y - a.y) * (b.x - a.x) > 0;
};

export const isWebGLAvailable = () => {
  try {
    const canvas = document.createElement('canvas');
    return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
  } catch (e) {
    return false;
  }
};
