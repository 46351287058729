import React, { Component } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import styles from './wind-arrow.module.scss';
import classnames from 'classnames';

class WindArrow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dir: 0
    }
  }

  componentDidMount() {
    this.animationTimeout = setTimeout(() => {
      this.setState({ dir: this.props.dir });
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimeout);
  }

  render() {
    const classNames = classnames(styles.windArrow, {
      [styles.small]: this.props.small
    });
    return (
      <span className={classNames}>
        <FaArrowDown style={{fill: this.props.color || null, transform: `rotate(${this.state.dir}deg)`}}/>
      </span>
    );
  }
}

export default WindArrow;