import React, { Component } from 'react';
import store from '../../store';
import { remove } from './actions';
import { IoIosCloseCircle, IoMdCheckmarkCircle, IoMdInformationCircle, IoIosWarning } from 'react-icons/io';
import styles from './message.module.scss';
import posed, { PoseGroup } from 'react-pose';
import { SETTINGS } from '../../config';

export class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: <IoMdInformationCircle />,
      type: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.setType();
  }

  setType = () => {
    if (!this.mounted) {
      return false;
    }

    let icon = null;
    let type = styles[`${this.props.type}Message`] || '';

    if (this.props.animateOut) {
      setTimeout(() => {
        store.dispatch(remove(this.props.id));
      }, this.props.showFor || SETTINGS.messages.timeBeforeDismiss);
    }

    switch (this.props.type) {
      case 'error':
        icon = <IoIosCloseCircle />;
        break;
      case 'warning':
        icon = <IoIosWarning />;
        break;
      case 'success':
        icon = <IoMdCheckmarkCircle />;
        break;
      case 'info':
        icon = <IoMdInformationCircle />;
        break;
      default:
        icon = <IoMdInformationCircle />;
    }

    this.setState({
      icon,
      type,
    });
  };

  render() {
    return (
      <div className={`${styles.message} ${this.state.type}`}>
        <i className={styles.icon}>{this.state.icon}</i>
        {this.props.children}
      </div>
    );
  }
}

export const Error = props => (
  <Message {...props} type="error">
    {props.children}
  </Message>
);
export const Success = props => (
  <Message {...props} type="success">
    {props.children}
  </Message>
);
export const Warning = props => (
  <Message {...props} type="warning">
    {props.children}
  </Message>
);
export const Info = props => (
  <Message {...props} type="info">
    {props.children}
  </Message>
);

const MessageContainer = posed.div({
  enter: {
    opacity: 1,
    y: '0',
  },
  exit: {
    opacity: 0,
    y: '-100px',
  },
});

export const render = messages => {
  return (
    <div className={styles.container}>
      <PoseGroup>
        {messages.map(message => {
          return (
            <MessageContainer key={message.id}>
              <Message type={message.type} showFor={message.showFor} animateOut id={message.id}>
                {message.text}
              </Message>
            </MessageContainer>
          );
        })}
      </PoseGroup>
    </div>
  );
};
