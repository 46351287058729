import { Component } from 'react';
import { RidesView, StarredView, RoutesView } from '../';
import { IoMdBicycle } from 'react-icons/io';
import { MdStar } from 'react-icons/md';
import { FaRoute } from 'react-icons/fa';
import store from '../../store';
import { setTitle } from '../../components/header/actions';
import Tabs, { TabContent } from '../../components/tabs';
import styles from './dashboard.module.scss';
import { trackPageView } from '../../utils/ga';
import { getCurrentWeather } from '../../utils';
import ForecastItem from '../../components/forecast-item';
import LoadingCircle from '../../components/loading-circle';
import { setActiveTab } from '../../components/menu/actions';
import { Map } from '../../components/map';

export class DashboardView extends Component {
  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      weather: null,
      activeTab: state.menu.activeTab,
    };
    this.mounted = null;
  }

  componentDidMount() {
    this.mounted = true;
    store.dispatch(setTitle('HEADWIND'));
    getCurrentWeather().then(weather => {
      this.mounted && this.setState({ weather });
    });
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.menu.activeTab !== this.state.activeTab) {
        this.setState({
          activeTab: state.menu.activeTab,
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.mapPreloadHolder}>
          <Map width="100px" height="100px" />
        </div>
        <div className={styles.weatherSummary}>
          {this.state.weather && (
            <ForecastItem weather={this.state.weather.currently} dark={true} slim={true} time={Date.now()} />
          )}
          {!this.state.weather && <LoadingCircle color="dark" small className={styles.weatherSummaryLoading} />}
        </div>
        <Tabs active={this.state.activeTab} stretch={true} onChange={this.updateTabs} navClass={styles.tabNav}>
          <TabContent title="Rides" icon={<IoMdBicycle />}>
            <RidesView active={this.state.activeTab === 0} />
          </TabContent>
          <TabContent title="Starred" icon={<MdStar />}>
            <StarredView active={this.state.activeTab === 1} />
          </TabContent>
          <TabContent title="Routes" icon={<FaRoute className={styles.smallIcon} />}>
            <RoutesView active={this.state.activeTab === 2} />
          </TabContent>
        </Tabs>
      </div>
    );
  }

  updateTabs = value => {
    store.dispatch(setActiveTab(value));
    window.scrollTo(0, 0);
    trackPageView(window.location);
  };
}
