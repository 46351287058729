import React, { Component } from 'react';
import { normalizeDate, getRideID } from '../../utils';
import RideCard from '../ride-card';
import { format, differenceInCalendarDays, addDays } from 'date-fns';
import { FiSun, FiCalendar, FiArrowRight, FiSearch } from 'react-icons/fi';
import DatePickerModal from '../datepicker';
import styles from './ride-list.module.scss';
import { withRouter } from 'react-router-dom';
import store from '../../store';
import Input from '../input';
import { getRideDistance } from '../../utils';
import LoadingCircle from '../loading-circle';

export class RideList extends Component {
  static defaultProps = {
    type: 'ride',
  };

  constructor(props) {
    super(props);
    this.now = normalizeDate(Date.now());
    this.type = this.props.type === 'starred' ? 'ride' : this.props.type;
    this.mobileCardHeight = window.innerHeight / 1.8;
    this.startDrag = 0;
    this.dragTriggerDist = 20;
    this.isRefreshing = false;
    this.refresher = React.createRef();
    this.refreshDistance = 60;
    const state = store.getState();
    this.state = {
      rides: this.props.rides,
      ride: null,
      layout: state.global.layout,
    };
  }

  componentDidMount() {
    // all for drag down to refresh
    window.addEventListener('touchstart', this.touchStart, { passive: true });
    window.addEventListener('touchend', this.touchEnd, { passive: true });
    window.addEventListener('touchmove', this.touchMove, { passive: true });

    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.global.layout) {
        this.setState({
          layout: state.global.layout,
        });
      }
    });
  }

  componentWillUnmount() {
    // all for drag down to refresh
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchend', this.touchEnd);
    window.removeEventListener('touchmove', this.touchMove);

    this.unsubscribe();
  }

  componentWillUpdate(nextProps) {
    // if the rides prop has changed. more rides have been added. make sure to animate them in nicely
    if (this.props.rides.length !== nextProps.rides.length) {
      const ids = this.props.rides.map(ride => getRideID(ride));
      nextProps.rides.forEach(ride => {
        if (!ids.includes(getRideID(ride))) {
          ride.new = true;
        } else {
          ride.new = false;
        }
      });
      this.setState({ rides: nextProps.rides });
    }
    // reset refresh if new updated time passed in
    if (this.props.updatedAt !== nextProps.updatedAt) {
      this.resetRefresher();
    }
  }

  render() {
    const pluralType = this.props.type === 'starred' ? `starred rides` : `${this.props.type}s`;
    let i = 0;
    return (
      <div className={styles.container}>
        <div className={styles.refresher} ref={this.refresher}>
          <LoadingCircle color="dark" small background />
        </div>
        <DatePickerModal
          open={this.state.ride ? true : false}
          onChange={this.handleDateChange}
          selected={this.state.ride ? this.convertDate(this.state.ride.start_date) : new Date()}
          onClose={this.closePicker}
        />
        <Input
          className={styles.searchBar}
          type="text"
          icon={<FiSearch />}
          placeholder={`Search ${pluralType}`}
          onChange={this.onSearch}
        />
        {this.state.rides.map(ride => {
          const isVirtual = ride.type ? String(ride.type).toLowerCase().includes('virtual') : false;
          if (ride.map.summary_polyline && !isVirtual) {
            i++;
            const today = this.convertDate(ride.start_date);
            const time = format(today, 'HH:mm');
            const rideActions = [];
            if (this.props.type === 'stage') {
              const then = normalizeDate(ride.start_date);
              if (this.now > then) {
                rideActions.push({
                  label: `How hard was this ${this.type}?`,
                  icon: 'Then',
                  link: `/stage/${getRideID(ride)}/${then}`,
                });
              } else {
                rideActions.push({
                  label: `How hard will this ${this.type} be?`,
                  icon: <FiArrowRight />,
                  link: `/stage/${getRideID(ride)}/${then}`,
                });
              }
            }
            rideActions.push({
              label: `How hard will this ${this.type} be on...`,
              icon: <FiCalendar />,
              action: this.openPicker.bind(this, ride),
            });
            if (this.props.type !== 'route' && this.props.type !== 'stage' && this.now < today) {
              rideActions.push({
                label: `How hard will this ${this.type} be today?`,
                icon: time,
                link: `/${this.props.type}/${getRideID(ride)}/${today}`,
              });
            }
            if (this.props.type !== 'stage') {
              rideActions.push({
                label: `How hard will this ${this.type} be right now?`,
                icon: 'Now',
                link: `/${this.props.type}/${getRideID(ride)}/${this.now}`,
              });
            }
            if (this.props.type !== 'route' && this.props.type !== 'stage') {
              const then = normalizeDate(ride.start_date);
              rideActions.push({
                label: `How hard was this ${this.type}?`,
                icon: 'Then',
                link: `/ride/${getRideID(ride)}/${then}`,
              });
            }
            if (this.props.type !== 'stage') {
              rideActions.push({
                label: `7 day forecast for this ${this.type}`,
                icon: <FiSun />,
                link: `/forecast/${this.props.type}/${getRideID(ride)}`,
              });
            }
            return (
              <RideCard
                key={getRideID(ride)}
                first={i === 1}
                actions={rideActions}
                ride={ride}
                type={this.props.type}
                new={ride.new}
                height={this.state.layout === 'mobile' ? this.mobileCardHeight : '65vh'}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  touchStart = e => {
    if (this.props.active && !this.isRefreshing) {
      this.startDrag = e.touches[0].clientY || 0;
    }
  };

  touchEnd = e => {
    if (this.props.active && !this.isRefreshing) {
      this.resetRefresher();
    }
  };

  touchMove = e => {
    if (!this.props.active || this.isRefreshing || window.scrollY !== 0) {
      this.startDrag = e.touches[0].clientY || 0;
      return;
    }
    const drag = e.touches[0].clientY || 0;
    const dist = drag - this.startDrag;
    if (dist < this.dragTriggerDist) {
      return;
    }
    if (this.refresher.current) this.refresher.current.style.height = `${dist - this.dragTriggerDist}px`;
    if (dist > this.refreshDistance) {
      if (this.refresher.current) this.refresher.current.style.height = `${this.refreshDistance}px`;
      this.isRefreshing = true;
      this.props.onRefresh && this.props.onRefresh();
    }
  };

  resetRefresher = () => {
    this.isRefreshing = false;
    if (!this.refresher.current) {
      return false;
    }
    setTimeout(() => {
      if (this.refresher.current) {
        this.refresher.current.style.transition = `height .2s`;
        this.refresher.current.style.height = `0px`;
      }
      setTimeout(() => {
        if (this.refresher.current) {
          this.refresher.current.style.transition = null;
        }
      }, 300);
    }, 0);
  };

  onSearch = e => {
    if (e.target.value && this.props.rides && this.props.rides.length) {
      const foundRides = this.props.rides.filter(ride => {
        const value = e.target.value || '';
        const titleMatch = ride.name.toLowerCase().includes(value.toLowerCase());
        const distance = getRideDistance(ride.distance);
        let distanceMatch = true;
        for (let i = 0; i < value.length; i++) {
          if (value.charAt(i) !== distance.charAt(i)) {
            distanceMatch = false;
          }
        }
        return titleMatch || distanceMatch;
      });
      this.setState({ rides: foundRides });
    } else {
      this.setState({ rides: this.props.rides });
    }
  };

  convertDate = (date1, date2) => {
    // takes 2 dates and changes the day of the first date to match that of the second
    const then = normalizeDate(date1);
    const now = normalizeDate(date2);
    const diff = differenceInCalendarDays(now, then);
    const today = addDays(then, diff);
    return normalizeDate(today);
  };

  handleDateChange = date => {
    date = normalizeDate(date);
    this.props.history.push(`/${this.props.type}/${getRideID(this.state.ride)}/${date}`);
  };

  openPicker = ride => {
    this.setState({ ride });
  };

  closePicker = () => {
    this.setState({ ride: null });
  };
}

export default withRouter(RideList);
