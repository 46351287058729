const initialState = {
  el: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_MAP':
      return { ...state, el: action.payload };
    default:
      return state;
  }
};

export default reducer;
