import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { SETTINGS } from '../../config';
import { getPaymentIntent } from '../../api/payments';
import { Button } from '../button';
import store from '../../store';
import * as Message from '../message';
import styles from './payment-request-button.module.scss';
import * as strava from '../../api/strava';
import { trackEvent } from '../../utils/ga';
import { getUserEmail, getUserFullName } from '../../utils';
import Modal from '../../components/modal';
import colors from '../../style-utils/colors.module.scss';
import { getExchangeRates, getCurrency } from '../../api/currency';
import { Input } from '../input';
import { AcceptedCards } from '../accepted-cards';

export const PaymentRequestButton = props => {
  const user = store.getState().strava.user;
  const [paymentRequest, setPaymentRequest] = useState(false);
  const [email, setEmail] = useState(getUserEmail(user));
  const [name, setName] = useState(getUserFullName(user));
  const [displayAmount, setDisplayAmount] = useState(`A$${SETTINGS.stripe.donate.amount / 100}`);
  const [processing, setProcessing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  let mounted = null;

  useEffect(() => {
    mounted = true;
    const promises = [];
    promises.push(getExchangeRates());
    promises.push(getCurrency());
    Promise.all(promises).then(([rates, currency]) => {
      if (currency) {
        const AUDRate = rates.rates['AUD'] || 1;
        const AUDtoEuro = Number(props.amount / 100) / AUDRate;
        const rate = rates.rates[currency.code] || 1;
        let amount = AUDtoEuro * rate;
        // amount = new Intl.NumberFormat('en-AU', { style: 'currency', currency: currency.code }).format(amount);
        amount = amount.toFixed(2);
        amount = `${currency.symbol}${amount}`;
        mounted && setDisplayAmount(amount);
      }
    });
    const unsubscribe = store.subscribe(() => {
      const user = store.getState().strava.user;
      setName(getUserFullName(user));
      setEmail(getUserEmail(user));
    });
    return () => {
      unsubscribe();
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: 'AU',
        currency: props.currency,
        total: {
          label: props.label,
          amount: props.amount,
        },
        // displayItems: [
        //   {
        //     label: props.label,
        //     amount: props.amount,
        //   },
        // ],
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest
        .canMakePayment()
        .then(result => {
          if (result) {
            setPaymentRequest(paymentRequest);
          }
        })
        .catch(e => {
          trackEvent('Donation', 'Payment Request', 'error');
          throw new Error(e);
        });

      paymentRequest.on('paymentmethod', async e => {
        trackEvent('Donation', 'Payment Request', 'started');
        getPaymentIntent(e.payerEmail).then(async paymentIntent => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { error: confirmError } = await stripe.confirmCardPayment(
            paymentIntent.clientSecret,
            { payment_method: e.paymentMethod.id },
            { handleActions: false },
          );
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            e.complete('fail');
            trackEvent('Donation', 'Payment Request', 'error');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            e.complete('success');
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(paymentIntent.clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              onError(error, 'Payment Request');
            } else {
              // The payment has succeeded.
              onSuccess('Payent Request');
            }
          }
        });
      });
    }
  }, [stripe]);

  const onUpdateName = e => {
    setName(e.target.value);
  };

  const onUpdateEmail = e => {
    setEmail(e.target.value);
  };

  const onError = (error, type) => {
    store.dispatch(
      Message.add({
        type: 'error',
        text: error.message || error.toString(),
      }),
    );
    trackEvent('Donation', type, 'error');
    setProcessing(false);
    props.onError && props.onError();
  };

  const onSuccess = type => {
    store.dispatch(
      Message.add({
        type: 'success',
        text: props.successText || 'Payment successful',
        showFor: 10000,
      }),
    );
    // update user in db to flag they have donated
    strava.donate(email);
    trackEvent('Donation', 'Payment Request', 'success');
    setProcessing(false);
    props.onSuccess && props.onSuccess();
  };

  const openPaymentRequest = () => {
    if (paymentRequest) {
      paymentRequest.show();
    }
  };

  const openModal = () => {
    setTimeout(() => {
      setModalOpen(true);
      trackEvent('Donation', 'Default', 'started');
    }, props.delay || 0);
  };

  const onModalClose = () => {
    setModalOpen(false);
    props.onClose && props.onClose();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setProcessing(true);
    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs
    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      onError(error, 'Default');
    } else {
      getPaymentIntent(email).then(async paymentIntent => {
        try {
          const { error } = await stripe.confirmCardPayment(
            paymentIntent.clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name,
                  email,
                },
              },
            },
            // { handleActions: false },
          );
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            onError(error, 'Default');
          } else {
            // The payment has succeeded.
            onSuccess('Default');
            setModalOpen(false);
          }
        } catch (error) {
          // The payment failed -- ask your customer for a new payment method.
          onError(error, 'Default');
        }
      });
    }
  };

  const onClick = () => {
    props.onClick && props.onClick();
    if (paymentRequest) {
      openPaymentRequest();
    } else {
      openModal();
    }
  };

  if (paymentRequest) {
    return (
      <>
        {props.info && !props.infoModalOnly && <p className={styles.infoText}>{props.info}</p>}
        <Button {...props} className={`${styles.button} ${props.className}`} onClick={onClick}>
          {props.children} {displayAmount}
        </Button>
      </>
    );
  } else {
    return (
      <>
        {props.info && !props.infoModalOnly && <p className={styles.infoText}>{props.info}</p>}
        <Button {...props} className={`${styles.button} ${props.className}`} onClick={onClick}>
          {props.children} {displayAmount}
        </Button>
        <Modal open={modalOpen} onClose={onModalClose} title={props.title || 'Make a payment'}>
          <div className={styles.modalContent}>
            {props.info && <p className={styles.modalInfoText}>{props.info}</p>}
            <form onSubmit={handleSubmit}>
              <Input defaultValue={name} placeholder="Name" onChange={onUpdateName} />
              <Input defaultValue={email} type="email" placeholder="Email" onChange={onUpdateEmail} />
              <div className={styles.cardElement}>
                <CardElement
                  options={{
                    style: {
                      invalid: {
                        color: colors.error,
                      },
                    },
                  }}
                />
              </div>
              <div className={styles.payButton}>
                <Button
                  type="submit"
                  {...props}
                  full={false}
                  light
                  white={false}
                  minimal={false}
                  disabled={!stripe}
                  loading={processing}
                >
                  {props.children} {displayAmount}
                </Button>
              </div>
              <AcceptedCards />
              <small className={styles.disclaimer}>
                * Your bank might charge you a tiny international transaction fee if you are located outside Australia
              </small>
            </form>
          </div>
        </Modal>
      </>
    );
  }
};

export default PaymentRequestButton;
