import React from 'react';
import * as strava from '../../api/strava';
import { openMenu } from '../menu/actions';
import store from '../../store';
import { withRouter } from 'react-router-dom';
import { MdMenu, MdArrowBack } from 'react-icons/md';
import IconButton from '../icon-button';
import logo from '../../assets/images/headwind-logo-gradient.svg';
import styles from './header.module.scss';
import { isEmbedded } from '../../utils';
import Link from 'react-router-dom/Link';

const openMainMenu = () => {
  store.dispatch(openMenu());
};

const Header = props => {
  const transparent = props.transparent ? styles.transparent : '';
  const solid = props.solid ? styles.solid : '';
  const dark = props.dark ? styles.dark : '';
  const slim = props.slim ? styles.slim : '';
  const shared = store.getState().global.shared;
  const token = store.getState().strava.token;

  const onGoBack = () => {
    if (shared) {
      props.history.push('/');
    } else {
      props.history.goBack();
    }
  };

  return (
    <header className={`${styles.header} ${transparent} ${solid} ${dark} ${slim}`}>
      {props.location.pathname !== '/' && token && !isEmbedded() && (
        <IconButton className={styles.back} onClick={onGoBack} simple dark>
          <MdArrowBack />
        </IconButton>
      )}
      <h1 className={styles.title}>
        <Link to="/">
          <img src={logo} alt="Headwind App" />
        </Link>
        {props.title && <span className={styles.text}>{props.title}</span>}
      </h1>
      {token && !shared && (
        <IconButton className={styles.menu} simple gradient large onClick={openMainMenu}>
          <MdMenu />
        </IconButton>
      )}
    </header>
  );
};

export default withRouter(Header);
