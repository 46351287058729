import React, { Component } from 'react';
import { format } from 'date-fns';
import HeatMap from '../../components/heat-map';
import { getWeatherIcon, convertToUserPref, userPrefUnit, hasDonated } from '../../utils';
import { setTitle } from '../../components/header/actions';
import store from '../../store';
import styles from './rain-radar.module.scss';
import CountUp from 'react-countup';
import { setLoading } from '../../store/actions';
import BottomDrawer from '../../components/bottom-drawer';
import { stopIOSOverscroll } from '../../utils/ios-overscroll';
import { getCurrentWeather } from '../../utils';
import { MapHolder } from '../../components/map';
import { InfoView } from '../info';
import { DonateButton } from '../../components/donate-button';

export class RainRadarView extends Component {
  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      weather: null,
      layout: state.global.layout,
      showInfo: false,
      donated: hasDonated(),
      mapReady: false,
    };
    this.mounted = null;
    this.stopIOSOverscroll = new stopIOSOverscroll();
  }

  componentDidMount() {
    this.mounted = true;
    store.dispatch(setTitle('Rain Radar'));
    getCurrentWeather().then(weather => {
      if (this.mounted) {
        this.setState({ weather });
        this.ready();
      }
    });
    this.state.donated && this.stopIOSOverscroll.enable();
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.global.layout) {
        this.setState({
          layout: state.global.layout,
          donated: hasDonated(state.strava.user),
        });
      }
    });
    !this.state.donated && setLoading(false);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.stopIOSOverscroll.disable();
    this.unsubscribe();
  }

  render() {
    if (!this.state.donated) {
      return (
        <div className={styles.donateBackground}>
          <InfoView title="Unlock access to the Rain Radar">
            <p>Sorry the rain radar view is currently only available to those who have donated.</p>
            <p>To gain access to current Rain Radar for your precise location please hit the donate button below.</p>
            <p>
              Your donation will go towards the running costs for Headwind and help keep the app free for everyone.
              Thank you.
            </p>
            <br />
            <DonateButton
              info="Donate to gain access to current Rain Radar for your precise location. Your donation is much appreciated and will help keep Headwind free for everyone."
              infoModalOnly
              title="Unlock Rain Radar"
              white
              full
            />
          </InfoView>
        </div>
      );
    } else {
      if (this.state.weather) {
        return (
          <div className={styles.container}>
            <HeatMap radar={true} weather={this.state.weather} height="100%" onLoad={this.onLoad} />
            {this.renderBottomDrawer()}
          </div>
        );
      } else {
        return <MapHolder height="100vh" text="Loading Rain Radar..." />;
      }
    }
  }

  renderTopStats = () => {
    const weather = (this.state.weather && this.state.weather.currently) || null;
    const description = (this.state.weather && this.state.weather.hourly.summary) || null;
    const icon = weather ? getWeatherIcon(weather) : null;
    return (
      <div className={styles.rideInfo}>
        <div className={styles.rideHeading}>
          <span className={styles.rideIcon}>{icon && <img src={icon} alt="weather icon" />}</span>
          <span className={styles.rideHeadingText}>
            {this.getNiceTime()}
            {description && <span className={styles.rideDescription}>{description}</span>}
          </span>
        </div>
        <div className={styles.rideStats}>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Temp</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={this.state.showInfo ? convertToUserPref(weather.apparentTemperature, 'temperature') : 0}
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('temperature')}</div>
          </div>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Rain</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={this.state.showInfo ? convertToUserPref(weather.precipIntensity, 'rainfall') : 0}
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('rainfall')}</div>
          </div>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Wind Speed</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={this.state.showInfo ? convertToUserPref(weather.windSpeed, 'distance') : 0}
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('speed')}</div>
          </div>
          <div className={styles.rideStat}>
            <div className={styles.rideStatLabel}>Wind Gust</div>
            <div className={styles.rideStatValue}>
              <CountUp
                end={this.state.showInfo ? convertToUserPref(weather.windGust, 'distance') : 0}
                duration={2}
                delay={1}
                decimals={1}
              />
            </div>
            <div className={styles.rideStatLabel}>{userPrefUnit('speed')}</div>
          </div>
        </div>
      </div>
    );
  };

  renderBottomDrawer = () => {
    const weather = (this.state.weather && this.state.weather.currently) || null;
    if (!weather) {
      return null;
    } else {
      return <BottomDrawer ready={this.state.showInfo} visible={this.renderTopStats()} />;
    }
  };

  getNiceTime = time => {
    if (time) {
      return format(time, "EEE MMMM do 'at' h:mm a");
    } else {
      return format(new Date(), 'EEE MMMM do');
    }
  };

  onLoad = (weather, hardness, map) => {
    if (!this.mounted) {
      return false;
    }
    setLoading(false);
    this.mounted && this.setState({ mapReady: true });
    this.ready();
  };

  ready = () => {
    if (this.mounted && this.state.weather && this.state.mapReady) {
      setTimeout(() => {
        this.mounted && this.setState({ showInfo: true });
      }, 1000);
    }
  };
}

export default RainRadarView;
