export const vertex = `
  attribute float size;
  attribute float alpha;
  attribute float rotation;
  varying vec3 vColor;
  varying float vAlpha;
  varying float vRotation;
  varying float vSize;

  void main() {
    vColor = color;
    vAlpha = alpha;
    vRotation = rotation;
    vSize = size;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_PointSize = vSize * ( 300.0 / -mvPosition.z );
    gl_Position = projectionMatrix * mvPosition;
  }
`;

export default vertex;
