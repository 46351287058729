export const fragment = `
  uniform sampler2D pointTexture;
  varying vec3 vColor;
  varying float vAlpha;
  varying float vRotation;

  void main() {
    float mid = 0.5;
    vec2 rotated = vec2(cos(vRotation) * (gl_PointCoord.x - mid) + sin(vRotation) * (gl_PointCoord.y - mid) + mid, cos(vRotation) * (gl_PointCoord.y - mid) - sin(vRotation) * (gl_PointCoord.x - mid) + mid);
    vec4 rotatedTexture = texture2D( pointTexture,  rotated);
    gl_FragColor = vec4(vColor, vAlpha);
    gl_FragColor = gl_FragColor * rotatedTexture;
  }
`;

export default fragment;