import React, { Component } from 'react';
import Map from '../map';
import WindMap from '../wind-map';
import * as polyline from 'google-polyline';
import * as DS from '../../api/darksky';
import { MapHolder } from '../map';
import {
  processPolylines,
  mapboxPolyline,
  getRideHardness,
  getFirstSymbolLayer,
  getCurrentPosition,
  hasDonated,
  simplifyPolyline,
} from '../../utils';
import { SETTINGS } from '../../config';
import styles from './heat-map.module.scss';
import { format } from 'date-fns';
import mapboxgl from 'mapbox-gl';
import {
  MdPlayArrow,
  MdSkipNext,
  MdSkipPrevious,
  MdPause,
  MdFastRewind,
  MdFastForward,
  MdVisibility,
  MdVisibilityOff,
  MdRefresh,
} from 'react-icons/md';
import store from '../../store';
import { DonateButton } from '../donate-button';
import { Banner } from '../banner';

class HeatMap extends Component {
  static defaultProps = {
    ride: null,
    weather: null,
    width: '100%',
    height: '500px',
    static: false,
    time: null,
    onLoad: null,
    reverse: false,
    interactive: false,
    radar: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      polylines: null,
      polyline: null,
      originalPolyline: null,
      windDir: 0,
      windSpeed: 0,
      mapBearing: 0,
      map: null,
      bounds: null,
      radarTimeStamp: null,
      radarLoading: true,
      radarPlaying: true,
      activeRadarIndex: 0,
      radarJSON: null,
      radarVisible: true,
      donated: hasDonated(),
    };
    this.mounted = null;
    this.radarLoop = null;
    this.radarLayers = [];
    this.radarOpacity = 0.3;
    this.radarTheme = 3;
    this.radarSpeed = 1000;
    this.radarLocationMarker = null;
    this.maxStaticPolylineLength = 35;
  }

  componentDidMount() {
    this.mounted = true;
    this.loadRide();
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      this.setState({
        donated: hasDonated(state.strava.user),
      });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unloadRadar(this.state.map);
    this.unsubscribe();
  }

  componentWillUpdate(nextProps) {
    // if we are reversing the current ride
    if (nextProps.reverse !== this.props.reverse) {
      this.reverseRide();
    }
    if (nextProps.radar !== this.props.radar && !nextProps.radar) {
      this.unloadRadar(this.state.map);
    }
    if (nextProps.radar !== this.props.radar && nextProps.radar) {
      this.loadRadar(this.state.map);
    }
  }

  componentDidUpdate(prevProps) {
    // if no ride do the init map loading
    if (!prevProps.ride && this.props.ride) {
      this.loadRide();
    } else if (prevProps.ride !== this.props.ride) {
      this.loadRide();
      // if the time is changing
    } else if (prevProps.time !== this.props.time) {
      this.loadRide();
      // if the weather is changing
    } else if (JSON.stringify(prevProps.weather) !== JSON.stringify(this.props.weather)) {
      this.loadRide();
    }
  }

  render() {
    const className = (this.props.static ? styles.static : styles.interactive) || '';
    if (this.state.ready) {
      return (
        <div className={`${styles.heatMapHolder} ${className}`} style={{ width: this.props.width }}>
          <div className={styles.topUI}>
            <div className={styles.interactiveUI}>
              {this.props.banner && <>{this.props.banner}</>}
              {this.props.radar && this.renderRadarUI()}
            </div>
            <div className={styles.otherTopUI}>
              <ul className={styles.mapKey} id="mapKey">
                <li className={styles.mapKeyItem}>
                  <span className={`${styles.mapKeySquare} ${styles.headwind}`} /> Headwind
                </li>
                <li className={styles.mapKeyItem}>
                  <span className={`${styles.mapKeySquare} ${styles.tailwind}`} /> Tailwind
                </li>
              </ul>
              {this.state.windDir !== null && (
                <div
                  className={styles.windDir}
                  style={{
                    transform: `rotate(${this.state.windDir - this.state.mapBearing}deg)`,
                  }}
                />
              )}
            </div>
          </div>
          {!this.props.static && this.state.map && (
            <WindMap
              map={this.state.map}
              bounds={this.state.bounds}
              windSpeed={this.state.windSpeed}
              windDir={this.state.windDir}
              onLoad={this.onWindMapLoad}
            />
          )}
          <Map
            {...this.props}
            polylines={this.state.polylines}
            polyline={this.state.polyline}
            originalPolyline={this.state.originalPolyline}
            onLoad={this.onMapLoad}
            onBoundsChange={this.onBoundsChange}
            pitch={SETTINGS.heatmap.pitch}
            bearing={SETTINGS.heatmap.bearing}
            zoom={SETTINGS.heatmap.zoom}
          />
        </div>
      );
    } else {
      return <MapHolder width={this.props.width} height={this.props.height} />;
    }
  }

  onMapLoad = (map, bounds) => {
    if (!this.mounted) {
      return false;
    }
    if (map) {
      // set the map state so we can draw the wind
      this.setState({ map, bounds });

      this.props.radar && this.loadRadar(map);

      map.on('rotate', () => {
        this.setState({ mapBearing: map.getBearing() });
      });

      if (this.props.onLoad) {
        let hardness = null;
        if (this.weather && this.weather.currently && this.props.ride) {
          hardness = getRideHardness(this.props.ride, this.weather.currently);
        }
        this.props.onLoad(this.weather, hardness, map, bounds);
      }
    }
  };

  onWindMapLoad = windMap => {
    this.mounted && this.props.onWindMapLoad && this.props.onWindMapLoad(windMap);
  };

  onBoundsChange = bounds => {
    this.setState({
      bounds,
    });
  };

  loadRide() {
    if (this.props.ride && this.mounted) {
      const time = this.props.time || this.props.ride.start_date_local;
      // if static, simplify polyline so static map request does not exceeed url limit
      let line = [];
      let originalLine = [];
      if (this.props.static) {
        line = polyline.decode(this.props.ride.map.summary_polyline);
        line = simplifyPolyline(line, 0.0005);
      } else {
        line = polyline.decode(this.props.ride.map.polyline || this.props.ride.map.summary_polyline);
        if (this.props.ride.map.original_polyline) {
          originalLine = polyline.decode(this.props.ride.map.original_polyline);
        }
      }
      // reverse lat and lng for mapbox usage
      // line = [[-31.97724, 115.83933],[-31.97666, 115.85701], [-31.98889, 115.85718], [-31.98831, 115.84156], [-31.97579, 115.84070]];
      // line = [[-31.97666, 115.85701], [-31.98889, 115.85718]];
      // line = [[-31.97724, 115.83933],[-31.97666, 115.85701]];
      originalLine = mapboxPolyline(originalLine);
      line = mapboxPolyline(line);
      if (this.props.reverse) {
        line = line.reverse();
        originalLine = originalLine.reverse();
      }
      if (this.props.weather) {
        setTimeout(() => {
          this.ready(this.props.weather, line);
        }, 1000);
      } else {
        const center = line[Math.floor(line.length / 2)];
        DS.getWeather({
          lat: center[1],
          lng: center[0],
          time,
        }).then(weather => {
          this.ready(weather, line);
        });
      }
      this.setState({ polyline: line, originalPolyline: originalLine });
    } else if (this.props.weather) {
      setTimeout(() => {
        this.ready(this.props.weather);
      }, 1000);
    }
  }

  reverseRide = () => {
    const line = this.state.polyline.slice(0).reverse();
    const sections = processPolylines(line, this.weather.currently.windBearing, this.weather.currently.windSpeed);
    this.setState({
      polyline: line,
      polylines: sections,
    });
    const hardness = getRideHardness(line, this.weather.currently);
    this.props.onUpdate && this.props.onUpdate(hardness, this.weather);
  };

  renderRadarUI = () => {
    if (!this.state.donated) {
      if (this.props.banner) {
        return null;
      } else {
        return (
          <Banner
            action={
              <DonateButton
                info="Donate to gain access to current Rain Radar for your precise location. Your donation is much appreciated and will help keep Headwind free for everyone."
                infoModalOnly
                title="Unlock Rain Radar"
                minimal
              />
            }
          >
            Unlock Rain Radar for this Ride
          </Banner>
        );
      }
    }
    let status = 'Loading...';
    if (!this.state.radarLoading) {
      if (!this.state.radarVisible) {
        status = 'off';
      } else if (this.state.radarTimeStamp) {
        status = format(this.state.radarTimeStamp, 'hh:mma');
      }
    }
    return (
      <div className={styles.rainRadarUI}>
        <div className={styles.radarLeftControls}>
          <button onClick={this.toggleRadarVisibility} disabled={this.state.radarLoading}>
            {this.state.radarVisible && <MdVisibility />}
            {!this.state.radarVisible && <MdVisibilityOff />}
          </button>
          <button onClick={this.refreshRadar} disabled={this.state.radarLoading}>
            <MdRefresh />
          </button>
        </div>
        <div className={styles.radarInfo}>
          <span className={styles.radarLabel}>
            <span className={styles.radarLabelRain}>Rain</span> Radar:
          </span>
          {status}
        </div>
        {!this.state.radarLoading && (
          <div className={styles.radarRightControls}>
            <button
              disabled={this.state.radarPlaying || this.state.activeRadarIndex <= 0 || !this.state.radarVisible}
              onClick={this.showRadarStep.bind(this, 0)}
              className={styles.secondaryRadarControl}
            >
              <MdSkipPrevious />
            </button>
            <button
              disabled={this.state.radarPlaying || this.state.activeRadarIndex <= 0 || !this.state.radarVisible}
              onClick={this.showRadarStep.bind(this, this.state.activeRadarIndex - 1)}
            >
              <MdFastRewind />
            </button>
            <button onClick={this.onRadarPlayPause} disabled={!this.state.radarVisible}>
              {this.state.radarPlaying && <MdPause />}
              {!this.state.radarPlaying && <MdPlayArrow />}
            </button>
            <button
              disabled={
                this.state.radarPlaying ||
                this.state.activeRadarIndex >= this.state.radarJSON.length - 1 ||
                !this.state.radarVisible
              }
              onClick={this.showRadarStep.bind(this, this.state.activeRadarIndex + 1)}
            >
              <MdFastForward />
            </button>
            <button
              disabled={
                this.state.radarPlaying ||
                this.state.activeRadarIndex >= this.state.radarJSON.length - 1 ||
                !this.state.radarVisible
              }
              onClick={this.showRadarStep.bind(this, this.state.radarJSON.length - 1)}
              className={styles.secondaryRadarControl}
            >
              <MdSkipNext />
            </button>
          </div>
        )}
      </div>
    );
  };

  onRadarPlayPause = () => {
    if (this.state.radarPlaying) {
      this.stopRadar();
    } else {
      this.playRadar();
    }
    this.setState({
      radarPlaying: !this.state.radarPlaying,
    });
  };

  stopRadar = () => {
    cancelAnimationFrame(this.radarLoop);
    clearTimeout(this.radarLoopTimeout);
    clearTimeout(this.radarPauseTimeout);
  };

  playRadar = () => {
    this.animateRadar();
  };

  hideRadar = map => {
    if (!map) {
      return false;
    }
    this.stopRadar();
    this.mounted && this.setState({ radarTimeStamp: null });
    this.hideAllRadarSteps();
  };

  showRadar = map => {
    if (!map) {
      return false;
    }
    this.radarLayers.forEach(layer => {
      map.getLayer(layer) && map.setPaintProperty(layer, 'raster-opacity', 0);
    });
    if (this.state.radarPlaying) {
      this.animateRadar();
    } else {
      this.showRadarStep(this.state.activeRadarIndex);
    }
  };

  toggleRadarVisibility = () => {
    if (this.state.radarVisible) {
      this.hideRadar(this.state.map);
    } else {
      this.showRadar(this.state.map);
    }
    this.setState({
      radarVisible: !this.state.radarVisible,
    });
  };

  refreshRadar = () => {
    const map = this.state.map;
    if (map) {
      this.unloadRadar(map);
      this.loadRadar(map, true);
    }
  };

  unloadRadar(map) {
    if (!map) {
      return false;
    }
    this.stopRadar();
    this.mounted && this.setState({ radarTimeStamp: null });
    this.radarLayers.forEach(layer => {
      map.getLayer(layer) && map.removeLayer(layer);
      map.getSource(layer) && map.removeSource(layer);
    });
    this.removeLocationMarker();
  }

  loadRadar(map) {
    if (!this.mounted || !this.state.donated) {
      return false;
    }
    this.setState({ radarLoading: true });
    const getTiles = layer => {
      return `https://tilecache.rainviewer.com/v2/radar/${layer}/512/{z}/{x}/{y}/${this.radarTheme}/1_1.png`;
    };
    fetch('https://api.rainviewer.com/public/maps.json')
      .then(res => {
        return res.json();
      })
      .then(json => {
        if (!json || !json.length || !map) {
          return false;
        }
        json = json.slice(json.length / 2);
        this.setState({
          radarJSON: json,
        });
        const firstSymbolLayerID = getFirstSymbolLayer(map);
        json.forEach((layer, i) => {
          const id = `radar-${i}`;
          this.radarLayers.push(id);
          map.addSource(id, {
            type: 'raster',
            tiles: [getTiles(layer)],
            tileSize: 512,
          });
          map.addLayer(
            {
              id,
              type: 'raster',
              source: id,
              paint: {
                'raster-opacity': 0,
                'raster-resampling': 'linear',
                'raster-fade-duration': 0,
              },
            },
            firstSymbolLayerID,
          );
        });
        map.on('movestart', () => {
          if (this.state.radarVisible) {
            this.mounted && this.setState({ radarLoading: true });
            this.hideRadar(map);
          }
        });
        map.on('moveend', () => {
          if (this.lastCenter !== JSON.stringify(map.getCenter()) && this.state.radarVisible) {
            this.lastCenter = JSON.stringify(map.getCenter());
            this.radarLoaded();
          }
        });
        this.radarLoaded();
      })
      .catch(e => {
        // console.error(e);
      });
    if (!this.props.ride) {
      this.addLocationMarker(map);
    }
  }

  radarLoaded = () => {
    this.mounted && this.setState({ radarLoading: false });
    this.stopRadar();
    if (this.state.radarPlaying) {
      this.animateRadar();
    } else {
      this.showRadarStep(this.state.activeRadarIndex);
    }
  };

  addLocationMarker = map => {
    this.removeLocationMarker();
    getCurrentPosition().then(position => {
      if (position && map) {
        const el = document.createElement('div');
        el.className = styles.radarCurrentLocation;
        const center = [position.longitude, position.latitude];
        this.radarLocationMarker = new mapboxgl.Marker(el).setLngLat(center).addTo(map);
        map
          .setCenter(center)
          .setZoom(SETTINGS.rainRadar.zoom)
          .setBearing(SETTINGS.rainRadar.bearing)
          .setPitch(SETTINGS.rainRadar.pitch);
        const bounds = map.getBounds();
        // this triggers the wind anim to reposition to the center of the marker
        this.setState({
          bounds,
        });
      }
    });
  };

  removeLocationMarker = () => {
    this.radarLocationMarker && this.radarLocationMarker.remove();
  };

  animateRadar = () => {
    if (!this.state.radarJSON || !this.state.map) {
      return false;
    }
    let speed = this.radarSpeed;
    // if at end hide last radar layer and pause for a little time before beginning animation
    if (this.state.activeRadarIndex === this.state.radarJSON.length - 1) {
      speed = this.radarSpeed * 3;
      this.radarPauseTimeout = setTimeout(() => {
        this.hideAllRadarSteps();
      }, speed - 1);
    }
    this.radarLoopTimeout = setTimeout(() => {
      this.showRadarStep(this.state.activeRadarIndex + 1);
      this.radarLoop = requestAnimationFrame(this.animateRadar);
    }, speed);
  };

  showRadarStep = index => {
    if (index >= this.state.radarJSON.length) index = 0;
    if (index < 0) index = this.state.radarJSON.length - 1;
    const time = this.state.radarJSON[index];
    const showID = `radar-${index}`;
    const showLayer = this.state.map.getLayer(showID);
    this.hideRadarStep(this.state.activeRadarIndex);
    if (showLayer) {
      this.state.map.setPaintProperty(showID, 'raster-opacity', this.radarOpacity);
    }
    this.mounted &&
      this.setState({
        radarTimeStamp: time * 1000,
        activeRadarIndex: index >= this.state.radarJSON.length ? 0 : index,
      });
  };

  hideRadarStep = index => {
    const hideID = `radar-${index}`;
    const hideLayer = this.state.map.getLayer(hideID);
    if (hideLayer) {
      this.state.map.setPaintProperty(hideID, 'raster-opacity', 0);
    }
  };

  hideAllRadarSteps = () => {
    this.radarLayers.forEach(layer => {
      this.state.map.getLayer(layer) && this.state.map.setPaintProperty(layer, 'raster-opacity', 0);
    });
  };

  ready(weather, line) {
    if (!this.mounted) {
      return false;
    }
    this.weather = weather;
    let hardness = null;
    if (line) {
      const sections = processPolylines(line, this.weather.currently.windBearing, this.weather.currently.windSpeed);
      hardness = getRideHardness(line, this.weather.currently);
      this.setState({
        polyline: line,
        polylines: sections,
      });
    }
    this.setState({
      ready: true,
      windDir: this.weather.currently.windBearing,
      windSpeed: this.weather.currently.windSpeed,
    });
    this.props.onUpdate && this.props.onUpdate(hardness, this.weather);
  }
}

export default HeatMap;
