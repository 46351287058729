import format from 'date-fns/format';
import * as polyline from 'google-polyline';
import rgb2hex from 'rgb2hex';

// @NOTE this is overly complex due to sharing a file with the backend
let settings;
try {
  settings = require('../config');
  settings = settings.SETTINGS ? settings.SETTINGS : settings;
} catch (e) {
  // @NOTE if you change these settings make sure to update ./config/settings.js
  settings = {
    hardness: {
      outof: 5,
      maxWindSpeed: 30,
      getsHardAt: 3,
    },
    defaultUserSettings: {
      units: {
        distance: 'kilometers',
        rainfall: 'millimeters',
        temperature: 'celsius',
      },
    },
  };
}
export const SETTINGS = settings;

export const normalizeDate = date => {
  return format(date || new Date(), "yyyy-MM-dd'T'HH:mm:ss");
};

export const easeInOutQuad = (t, b, c, d) => {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
  return (-c / 2) * (--t * (t - 2) - 1) + b;
};

export const getLineAngle = (start, end) => {
  const dy = end.x - start.x;
  const dx = end.y - start.y;
  let theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  if (theta < 0) {
    theta = 360 + theta;
  } // range [0, 360)
  return theta;
};

export const mapboxPolyline = polylines => {
  return polylines.map(point => {
    return [point[1], point[0]];
  });
};

export const latLng2point = (lat, lng, multiplier) => {
  multiplier = multiplier || 1;
  const point = {
    x: (lng + 180) * (256 / 360) * multiplier,
    y: 256 / 2 - ((256 * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 180 / 2))) / (2 * Math.PI)) * multiplier,
  };
  return point;
};

export const getHardnessColor = (hardness, opacity = 1, min = 0, max = 0) => {
  let range = 100;
  if (max) {
    range = max - min;
    hardness = hardness - min;
  }
  const color = Math.abs(Math.round((255 / range) * hardness));
  const rgba = 'rgba(255,' + (255 - color) + ',' + color + ',' + opacity + ')';
  return rgb2hex(rgba).hex;
};

export const processPolylines = (path, windDir, windSpeed) => {
  const lines = [];
  let lastPoint = null;
  let lastPxPoint = null;
  for (const point of path) {
    let angle = 0;
    const pxPoint = latLng2point(point[1], point[0], 10000);
    if (lastPoint) {
      angle = getLineAngle(lastPxPoint, pxPoint);
      let windFactor = Math.abs(360 - windDir - angle);
      if (windFactor > 180) {
        windFactor = Math.abs(windFactor - 360);
      }
      const hardness = (windFactor / 180) * 100;
      const line = {
        color: getHardnessColor(hardness),
        path: [lastPoint, point],
        angle,
        hardness: {
          dir: hardness,
          speed: windSpeed,
        },
      };
      lines.push(line);
    }
    lastPoint = point;
    lastPxPoint = pxPoint;
  }
  return lines;
};

export const getRideHardness = (data, weather) => {
  const windDir = weather.windBearing;
  const windSpeed = weather.windSpeed;
  const maxWindSpeed = SETTINGS.hardness.maxWindSpeed;

  // if we are passing in a ride object from strava
  let polylines;
  if (data.id !== undefined) {
    polylines = mapboxPolyline(polyline.decode(data.map.polyline || data.map.summary_polyline));
    // else assume we have a valid polyline already
  } else {
    polylines = data;
  }
  const sections = processPolylines(polylines, windDir, windSpeed);

  // get wind speed hardness
  const windSpeedHardness = Math.min((windSpeed / maxWindSpeed) * 100, 100);

  // get wind dir hardness
  let windDirHardness = 0;
  for (const section of sections) {
    windDirHardness += section.hardness.dir;
  }
  windDirHardness = windDirHardness / sections.length;

  //console.log(windSpeedHardness, windDirHardness);

  // work out total hardness
  let hardness = ((windSpeedHardness * windDirHardness) / 10000) * 100;
  //console.log(hardness);
  hardness = (hardness / 100) * SETTINGS.hardness.outof;
  //console.log(hardness);
  hardness = easeInOutQuad(hardness, 0, SETTINGS.hardness.outof, SETTINGS.hardness.outof);
  //console.log(hardness);
  hardness = Math.round(hardness * 10) / 10;
  //console.log(hardness);

  return hardness;
};

export const getWeatherIcon = (weather, nameOnly) => {
  const cur = weather.currently || weather;
  const icon = [];
  if (!cur.nearestStormDistance) {
    let precipType = '';
    let cloud = 'clear';
    let precip = 'clear';
    // rain or snow
    switch (cur.precipType) {
      case 'rain':
        precipType = 'rain';
        break;
      case 'snow':
      case 'sleet':
        precipType = 'snow';
        break;
      default:
        precipType = '';
    }
    // how much rain or snow
    const daily = cur.precipIntensity * 24;
    if (precipType && daily > 1) {
      if (daily > 9) {
        precip = `${precipType}-4`;
      } else if (daily > 6) {
        precip = `${precipType}-3`;
      } else if (daily > 3) {
        precip = `${precipType}-2`;
      } else {
        precip = `${precipType}-1`;
      }
      icon.push(precip);
      // else no rain or snow so just use cloud
    } else {
      if (cur.cloudCover > 0.8) {
        cloud = 'cloud-4';
      } else if (cur.cloudCover > 0.6) {
        cloud = 'cloud-3';
      } else if (cur.cloudCover > 0.4) {
        cloud = 'cloud-2';
      } else if (cur.cloudCover > 0.2) {
        cloud = 'cloud-1';
      }
      icon.push(cloud);
    }
    let cycle = 'day';
    if (!cur.sunriseTime && !cur.sunsetTime) {
      const hour = format(new Date(cur.time * 1000), 'HH');
      if (hour < 6 || hour > 18) {
        cycle = 'night';
      }
    }
    icon.push(cycle);
  } else {
    icon.push('storm');
  }
  // cloud fallback
  if (!icon.length) {
    icon.push('cloud-1-day');
  }
  const iconPath = icon.join('-');
  // console.log(iconPath);
  // console.log(cur.icon);
  // return require(`../assets/images/weather-icons/clear-day.svg`);
  if (nameOnly) {
    return iconPath;
  } else {
    const img = require(`../assets/images/weather-icons/${iconPath}.svg`);
    return img.default || img;
  }
};

export const degToCompass = num => {
  var val = Math.floor(num / 22.5 + 0.5);
  var arr = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
  return arr[val % 16];
};

export const userPrefUnit = (type, units) => {
  if (units && units[type]) {
    switch (units[type]) {
      case 'kilometers':
        return 'KM';
      case 'millimeters':
        return 'MM';
      default:
        return units[type];
    }
    // return settings.units[type];
  }
  return type;
};

export const convertToUserPref = (number, type, units) => {
  if (!units) {
    return oneDecPlace(number);
  }
  let no = number;
  if (units && units[type]) {
    if (units[type] === 'miles' || units[type] === 'MPH') {
      no = km2miles(number);
    } else if (units[type] === 'inches') {
      no = mm2inches(number);
    } else if (units[type] === 'fahrenheit') {
      no = centigrade2fahrenheit(number);
    }
  }
  return oneDecPlace(no);
};

export const km2miles = km => {
  return km / 1.6;
};

export const miles2km = miles => {
  return miles * 1.6;
};

export const centigrade2fahrenheit = c => {
  return (c * 9) / 5 + 32;
};

export const mm2inches = mm => {
  return mm / 25.4;
};

export const m2km = m => {
  return m / 1000;
};

export const km2m = km => {
  return km * 1000;
};

export const oneDecPlace = n => {
  return Math.ceil(n * 10) / 10;
};

export const twoDecPlace = n => {
  return Math.round((n + Number.EPSILON) * 100) / 100;
};
