import React, { Component } from 'react';
import * as strava from '../../api/strava';
import { updateStarredRides } from '../../api/strava/actions';
import store from '../../store';
import RideList from '../../components/ride-list';
import { LoadingView, InfoView } from '../../views';
import { setLoading } from '../../store/actions';
import { stateChanged } from '../../utils';

export class StarredView extends Component {
  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      rides: state.strava.starredRides,
      ridesFetched: false,
      updatedAt: null,
    };
    this.mounted = null;
    this.gettingStarred = false;
  }

  componentDidMount() {
    this.mounted = true;
    // get the starred rides
    this.getStarred();

    // update the starred rides when one is starred on the rides tab
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (stateChanged(state.strava.starredRides, this.state.rides)) {
        this.setState({
          rides: state.strava.starredRides,
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.getStarred();
    }
  }

  gotStarred = rides => {
    if (!this.mounted) {
      return false;
    }
    this.setState({ rides, ridesFetched: true });
    store.dispatch(updateStarredRides(rides));
    setLoading(false);
  };

  getStarred = () => {
    if (!this.gettingStarred) {
      this.gettingStarred = true;
      const starred = strava.getStarredRides();
      starred.cached.then(rides => {
        this.gotStarred(rides);
      });
      starred.latest
        .then(rides => {
          this.gotStarred(rides, true);
        })
        .finally(() => {
          this.gettingStarred = false;
          this.setState({ updatedAt: Date.now() });
        });
    }
  };

  render() {
    if (this.state.rides.length !== 0) {
      return (
        <RideList
          rides={this.state.rides}
          type="starred"
          active={this.props.active}
          onRefresh={this.getStarred}
          updatedAt={this.state.updatedAt}
        />
      );
    } else if (this.state.rides.length === 0 && !this.state.ridesFetched) {
      return <LoadingView message="Loading Rides..." tabs weatherSummary />;
    } else {
      return (
        <InfoView tabs weatherSummary title="No Starred Rides">
          Looks like you haven't starred any rides yet. Why not head over to the rides tab and click the star icon on
          any rides you want to appear here.
        </InfoView>
      );
    }
  }
}
