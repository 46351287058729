import React from 'react';
import styles from './info.module.scss';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const Info = props => {
  const dark = props.dark ? styles.dark : '';
  return (
    <div className={`${styles.info} ${dark}`}>
      <h2 className={styles.title}>
        <IoIosInformationCircleOutline />
        {props.title || 'Info'}
      </h2>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default Info;
