import React from 'react';
import styles from './banner.module.scss';
import { FiInfo } from 'react-icons/fi';
import classnames from 'classnames';

export const Banner = props => {
  const classNames = classnames(styles.container, {
    [props.className]: props.className,
    [styles[props.intent]]: props.intent,
  });
  return (
    <div className={classNames}>
      <div className={styles.text}>
        <FiInfo />
        <p>{props.children}</p>
      </div>
      {props.action && <div className={styles.action}>{props.action}</div>}
    </div>
  );
};
