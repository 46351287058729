import React, { Component } from 'react';
import { MdClose } from 'react-icons/md';
import { closeMenu } from '../menu/actions';
import store from '../../store';
import styles from './menu-drawer.module.scss';
import posed from 'react-pose';
import Menu from '../menu';
import { onModalOpen, onModalClose } from '../../utils';

const Drawer = posed.div({
  drawerOpen: {
    x: '100px',
  },
  drawerClosed: {
    x: '100%',
  },
});

const DrawerOverlay = posed.div({
  drawerOpen: {
    applyAtStart: { display: 'block' },
    opacity: 1,
  },
  drawerClosed: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
  },
});

const MenuClose = posed.button({
  drawerOpen: {
    opacity: 1,
    x: '-100%',
    transition: {
      delay: 300,
    },
  },
  drawerClosed: {
    opacity: 0,
    x: '0%',
  },
});

export class MenuDrawer extends Component {
  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      open: state.menu.open,
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (!state.strava.token) {
        return false;
      }
      if (state.menu.open !== this.state.open) {
        if (state.menu.open) {
          onModalOpen(false);
        } else {
          onModalClose();
        }
      }
      this.setState({
        open: state.menu.open,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <Drawer className={styles.drawer} pose={this.state.open ? 'drawerOpen' : 'drawerClosed'}>
        <MenuClose className={styles.menuClose} onClick={this.close}>
          <MdClose />
        </MenuClose>
        <Menu />
        <DrawerOverlay className={styles.drawerOverlay} onClick={this.close} />
      </Drawer>
    );
  }

  close = () => {
    store.dispatch(closeMenu());
  };
}

export default MenuDrawer;
