import React, { useEffect, useState } from 'react';
import styles from './update-toast.module.scss';
import { IoMdInformationCircle } from 'react-icons/io';
import posed from 'react-pose';
import { getAppVersion } from '../../utils';
import { withRouter } from 'react-router-dom';
import store from '../../store';
import { updateVersion } from '../../store/actions';

const Toast = posed.div({
  showToast: {
    y: '0',
  },
  hideToast: {
    y: '100%',
  },
});

const UpdateToastComp = props => {
  const [render, setRender] = useState(false);
  const [show, setShow] = useState(false);
  const [newVersion, setNewVersion] = useState(null);
  const state = store.getState();

  useEffect(() => {
    checkForUpdates();
    const listener = props.history.listen(() => {
      checkForUpdates();
    });
    return () => {
      if (listener && listener.unsubscribe) {
        listener.unsubscribe();
      }
    };
  }, []);

  const checkForUpdates = (timeout = 8000) => {
    getAppVersion().then(version => {
      const currentVersion = state.global.version;
      if (!currentVersion) {
        store.dispatch(updateVersion(version));
      } else {
        if (version !== currentVersion) {
          setNewVersion(version);
          setRender(true);
          setTimeout(() => {
            setShow(true);
          }, timeout);
        }
      }
    });
  };

  const update = () => {
    store.dispatch(updateVersion(newVersion));
    if (window.caches !== undefined) {
      window.caches.keys().then(function (names) {
        for (let name of names) window.caches.delete(name);
        reload();
      });
    } else {
      reload();
    }
  };

  const reload = () => {
    setShow(false);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  if (newVersion && render) {
    return (
      <div className={styles.holder}>
        <Toast className={styles.updateToast} onClick={update} pose={show ? 'showToast' : 'hideToast'}>
          <IoMdInformationCircle size={20} />
          <button>
            An update is available. <span>Click here</span> to update to version {newVersion}
          </button>
        </Toast>
      </div>
    );
  } else {
    return null;
  }
};

export const UpdateToast = withRouter(UpdateToastComp);
