import React from 'react';
import styles from './loading.module.scss';

const Loading = props => {
  const color = styles[`${props.color}Color`] || '';
  const text = props.text ? styles.hasText : '';
  let animate = props.animate;
  if (typeof(animate) === 'undefined') {
    animate = true;
  }
  animate = animate ? styles.animate : '';
  return (
    <div className={`${styles.loading} ${color} ${text} ${animate}`}>
      <div className={styles.loadingMask}>
        <div className={styles.loadingBar}></div>
        <div className={styles.loadingBar}></div>
        <div className={styles.loadingBar}></div>
        <div className={styles.loadingBar}></div>
        <div className={styles.loadingBar}></div>
      </div>
      {props.text &&
        <span className={styles.loadingText}>{props.text}</span>
      }
    </div>
  );
}

export default Loading;