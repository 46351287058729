import React, { useState, useRef, useEffect } from 'react';
import styles from './input.module.scss';
import { FiCopy } from 'react-icons/fi';
import { MdShare, MdCheck, MdAccessTime } from 'react-icons/md';
import classnames from 'classnames';
import * as Message from '../../components/message';
import { trackEvent } from '../../utils/ga';
import store from '../../store';
import DatePickerModal from '../../components/datepicker';
import format from 'date-fns/format';
import { normalizeDate } from '../../utils';

export const Input = props => {
  const [coppied, setCoppied] = useState(props.coppied || false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const [value, setValue] = useState(props.value);
  const canShare = navigator.share && props.onShareText;
  const ref = useRef();

  useEffect(() => {
    setCoppied(props.coppied);
  }, [props.coppied]);

  const onShare = () => {
    if (!canShare) {
      onCopy();
      props.onShareText && props.onShareText(ref.current.value);
    } else {
      copyText();
      navigator
        .share({
          title: props.shareTitle,
          text: props.shareText,
          url: (ref.current && ref.current.value) || window.location.origin,
        })
        .then(() => {
          store.dispatch(
            Message.add({
              type: 'success',
              text: `Ride shared successfully.`,
              showFor: 10000,
            }),
          );
          trackEvent('Share Ride', 'Native', 'Success');
          props.onShareText && props.onShareText(ref.current.value);
        })
        .catch(e => {
          const error = e.message || e;
          if (error && !error.includes('canceled')) {
            onCopy();
            props.onShareText && props.onShareText(ref.current.value);
            // store.dispatch(
            //   Message.add({
            //     type: 'error',
            //     text: `Error sharing ride. ${error.message || error}.`,
            //   }),
            // );
            trackEvent('Share Ride', 'Native', 'Error');
          }
        });
    }
  };

  const onCopy = () => {
    if (ref.current) {
      copyText();
      setCoppied(true);
      props.onCopyText && props.onCopyText(ref.current.value);
      store.dispatch(
        Message.add({
          type: 'success',
          text: `Text coppied to clipboard.`,
        }),
      );
      trackEvent('Share Ride', 'Clipboard', 'Success');
    }
  };

  const copyText = () => {
    if (ref.current) {
      ref.current.select();
      ref.current.setSelectionRange(0, 99999);
      document.execCommand('copy');
    }
  };

  const onOpenTimePicker = () => {
    setTimePickerOpen(true);
    props.onOpenTimePicker && props.onOpenTimePicker();
  };

  const onPickTime = date => {
    setTimePickerOpen(false);
    setValue(format(date, 'HH:mm'));
    props.onPickTime && props.onPickTime(date);
  };

  const onCloseTime = () => {
    setTimePickerOpen(false);
    props.onCloseTimePicker && props.onCloseTimePicker();
  };

  const getSelectedTime = () => {
    const now = normalizeDate();
    const dateAndTime = now.split('T');
    dateAndTime[1] = `${value}:00`;
    const selected = dateAndTime.join('T');
    return new Date(selected);
  };

  const classNames = classnames(styles.inputHolder, {
    [props.className]: props.className,
  });

  const buttonClassNames = classnames({
    [styles.coppied]: coppied,
  });

  const inputProps = { ...props };
  delete inputProps.shareText;
  delete inputProps.shareTitle;
  delete inputProps.onShareText;
  delete inputProps.onCopyText;
  delete inputProps.coppied;
  delete inputProps.timePicker;
  delete inputProps.onPickTime;
  delete inputProps.onOpenTimePicker;
  delete inputProps.onCloseTimePicker;

  return (
    <div className={classNames}>
      {props.label && <label>{props.label}:</label>}
      <div className={styles.input}>
        {props.icon && <div className={styles.icon}>{props.icon}</div>}
        <input
          {...inputProps}
          value={value}
          readOnly={props.onCopyText || props.onShareText || props.timePicker ? true : false}
          ref={ref}
        />
        {props.onCopyText && (
          <button onClick={onCopy} className={buttonClassNames}>
            {coppied && <MdCheck />}
            {!coppied && <FiCopy />}
          </button>
        )}
        {props.onShareText && (
          <button onClick={onShare} className={buttonClassNames}>
            {coppied && <MdCheck />}
            {!coppied && canShare && <MdShare />}
            {!coppied && !canShare && <FiCopy />}
          </button>
        )}
        {props.timePicker && (
          <button onClick={onOpenTimePicker} className={buttonClassNames}>
            <MdAccessTime />
          </button>
        )}
      </div>
      {props.timePicker && (
        <DatePickerModal
          open={timePickerOpen}
          timeOnly={true}
          onChange={onPickTime}
          onClose={onCloseTime}
          selected={getSelectedTime()}
        />
      )}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  shareTitle: 'headwind.app',
  shareText: '',
};

export default Input;
