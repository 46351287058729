import React from 'react';
import visa from '../../assets/images/payment-icons/1.png';
import mastercard from '../../assets/images/payment-icons/2.png';
// import maestro from '../../assets/images/payment-icons/3.png';
// import cirrus from '../../assets/images/payment-icons/4.png';
import AE from '../../assets/images/payment-icons/22.png';
import stripe from '../../assets/images/payment-icons/powered-by-stripe.png';
import styles from './accepted-cards.module.scss';

export const AcceptedCards = () => {
  return (
    <div className={styles.holder}>
      <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
        <img src={stripe} alt="Powered by stripe" />
      </a>
      <img src={visa} alt="Visa" />
      <img src={mastercard} alt="Mastercard" />
      {/* <img src={maestro} alt="Maestro" />
      <img src={cirrus} alt="Cirrus" /> */}
      <img src={AE} alt="American Express" />
    </div>
  );
};
