import React, { Component } from 'react';
import styles from './splash.module.scss';
import posed from 'react-pose';
import logo from '../../assets/images/headwind-logo-gradient.svg';
import store from '../../store';
import LoadingBar from '../loading-bar';

const Mask = posed.div({
  hide: {
    applyAtEnd: { visibility: 'hidden' },
    width: 'calc(100vmax + 200px)',
    height: 'calc(100vmax + 200px)',
  },
  show: {
    applyAtStart: { visibility: 'visible' },
    width: '0px',
    height: '0px',
  },
});

const Content = posed.div({
  hide: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
    scale: 0,
  },
  show: {
    applyAtStart: { display: 'flex' },
    opacity: 1,
    scale: 1,
  },
});

const InnerContent = posed.div({
  loading: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
    scale: 0,
  },
  ready: {
    applyAtStart: { display: 'flex' },
    opacity: 1,
    scale: 1,
  },
});

class Splash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fontLoaded: false,
      logoLoaded: false,
    };

    this.mounted = null;
  }

  componentDidMount() {
    this.mounted = true;
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      this.setState({ fontLoaded: state.global.fontLoaded });
    });
    const img = new Image();
    img.onload = () => {
      this.mounted && this.setState({ logoLoaded: true });
    };
    img.src = logo;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  componentDidUpdate() {
    if (this.state.fontLoaded && this.state.logoLoaded && this.props.pose === 'show') {
      this.props.onReady && this.props.onReady();
    }
  }

  render() {
    const ready = this.state.fontLoaded && this.state.logoLoaded;
    return (
      <>
        {this.props.pose === 'show' && <LoadingBar />}
        <Content className={styles.content} pose={this.props.pose}>
          <InnerContent className={styles.innerContent} pose={ready ? 'ready' : 'loading'}>
            <img src={logo} alt="Headwind" />
            <h1>Headwind</h1>
            {this.props.version && <p>version {this.props.version}</p>}
          </InnerContent>
        </Content>
        <Mask pose={this.props.pose} className={styles.splash} />
      </>
    );
  }
}

export default Splash;
