import { Component } from 'react';
import { userPrefUnit, getRideID, mapboxPolyline, getRideDistance } from '../../utils';
import Map from '../map';
import ActionButton from '../action-button';
import { format } from 'date-fns';
import { MdStar } from 'react-icons/md';
import inView from 'in-view';
import styles from './ride-card.module.scss';
import posed from 'react-pose';
import * as strava from '../../api/strava';
import store from '../../store';
import { updateStarredRides } from '../../api/strava/actions';
import * as polyline from 'google-polyline';

const Card = posed.div({
  hidden: {
    opacity: 0,
    transform: 'translateY(200px) scale(0.8)',
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transform: 'translateY(0px) scale(1)',
  },
  removed: {
    height: 0,
    transition: { delay: 200, duration: 200 },
  },
  new: {
    height: 0,
    transition: { duration: 200 },
  },
});

const CardInner = posed.div({
  hidden: {
    opacity: 1,
    transform: 'scale(1)',
  },
  visible: {
    opacity: 1,
    transform: 'scale(1)',
  },
  removed: {
    opacity: 0,
    transform: 'scale(0)',
    transition: { duration: 200 },
  },
  new: {
    opacity: 0,
    transform: 'scale(0)',
    transition: { delay: 200, duration: 200 },
  },
});

export class RideCard extends Component {
  static defaultProps = {
    new: false,
    height: '500px',
    interactive: false,
  };

  constructor(props) {
    super(props);
    const state = store.getState();
    const starred = state.strava.starredRideIds;

    let initialPoseState = 'hidden';
    if (props.new) {
      initialPoseState = 'new';
    } else if (props.first) {
      initialPoseState = 'visible';
    }

    const line = polyline.decode(this.props.ride.map.summary_polyline || this.props.ride.map.polyline);
    this.polyline = mapboxPolyline(line);

    this.state = {
      polyline: null,
      starred: starred.includes(getRideID(props.ride)),
      cardPoseState: initialPoseState,
    };

    // use created date if route else start date
    this.date = props.type === 'route' ? props.ride.created_at : props.ride.start_date;

    // format date
    const formatString = props.type === 'stage' ? 'EEE MMM do' : "EEE MMM do 'at' h:mma";
    this.date = format(this.date, formatString);

    // if route add created to beginning of date
    if (this.props.type === 'route') {
      this.date = `Created ${this.date}`;
    }

    this.mounted = null;
  }

  componentDidMount() {
    this.mounted = true;
    inView(`.card-${getRideID(this.props.ride)}`).once('enter', () => {
      if (this.mounted) {
        this.setState({
          polyline: this.polyline,
          cardPoseState: 'visible',
        });
      }
    });
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const starred = state.strava.starredRideIds;
      this.setState({ starred: starred.includes(getRideID(this.props.ride)) });
    });
  }

  componentWillReceiveProps(props) {
    if (props.first) {
      this.setState({
        polyline: this.polyline,
        cardPoseState: 'visible',
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    return (
      <Card className={`card-${getRideID(this.props.ride)} ${styles.card}`} pose={this.state.cardPoseState}>
        <CardInner pose={this.state.cardPoseState}>
          <div className={styles.header}>
            <h2 className={styles.title}>
              {this.props.ride.name} <br />
              <span className={styles.subtitle}>{this.date}</span>
            </h2>
            <div className={styles.right}>
              <div className={styles.item}>
                <span className={styles.unit}>{userPrefUnit('distance')}</span>
                {getRideDistance(this.props.ride.distance)}
              </div>
              {this.props.type !== 'route' && this.props.type !== 'stage' && (
                <div className={styles.favourite}>
                  <span className={this.state.starred ? styles.starSelected : styles.star}>
                    <MdStar onClick={this.toggleStarred} />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.map}>
            <Map polyline={this.state.polyline} static={true} height={this.props.height} />
            {this.props.actions && (
              <ActionButton className={styles.actions} actions={this.props.actions} bottom="2rem" />
            )}
          </div>
        </CardInner>
      </Card>
    );
  }

  toggleStarred = () => {
    if (this.state.starred) {
      this.unStarRide();
    } else {
      this.starRide();
    }
  };

  starRide = () => {
    if (!this.mounted) {
      return false;
    }

    this.setState({ starred: true });

    // update global starred rides state
    setTimeout(() => {
      const state = store.getState();
      const starredRides = [...state.strava.starredRides];
      starredRides.unshift(this.props.ride);
      store.dispatch(updateStarredRides(starredRides));
    }, 500);

    // save in the database
    strava.starRide(getRideID(this.props.ride), this.props.ride.athlete.id).then(() => {
      // this.setState({ starred: true });
    });
  };

  unStarRide = () => {
    if (!this.mounted) {
      return false;
    }

    this.setState({ starred: false });

    if (this.props.type === 'starred') {
      this.setState({ cardPoseState: 'removed' });
    }

    // update global starred rides state
    setTimeout(() => {
      const state = store.getState();
      const starredRides = state.strava.starredRides.filter(ride => getRideID(ride) !== getRideID(this.props.ride));
      store.dispatch(updateStarredRides(starredRides));
    }, 500);

    // update the database
    strava.unStarRide(getRideID(this.props.ride), this.props.ride.athlete.id).then(() => {
      // this.setState({ starred: false });
    });
  };
}

export default RideCard;
