import React, { Component } from 'react';
import * as mapboxgl from 'mapbox-gl';
import { SETTINGS } from '../../config';
import store from '../../store';
import styles from './map.module.scss';
import { MapHolder } from './';
import { saveMap } from './actions';
// import PitchControl from './pitch-control';
import colors from '../../style-utils/colors.module.scss';
import { getPolylineGeoJson, getCurrentPosition } from '../../utils';
import { lineString } from '@turf/helpers';
import length from '@turf/length';
import distance from '@turf/distance';
import 'mapbox-gl/dist/mapbox-gl.css';
import classnames from 'classnames';
import icon from '../../assets/images/map-marker.png';

export class MapInteractive extends Component {
  static defaultProps = {
    polyline: null,
    polylineColor: colors.primary,
    polylines: null,
    originalPolyline: null,
    width: '100%',
    height: '500px',
    zoom: 10,
    maxZoom: 18,
    minZoom: 4,
    pitch: null,
    bearing: null,
    static: false,
  };

  constructor(props) {
    super(props);
    mapboxgl.accessToken = SETTINGS.mapbox.apikey;
    this.mapEl = React.createRef();
    const state = store.getState();
    this.state = {
      ready: false,
      layout: state.global.layout,
    };
    this.map = this.props.static ? null : state.map.el;
    this.mounted = null;
    this.interactiveMapPadding = { top: 180, bottom: 180, left: 50, right: 50 };
    this.staticMapPadding = 100;
  }

  componentDidMount() {
    this.mounted = true;
    if (!this.map) {
      this.initInteractiveMap();
    } else {
      this.reInitInteractiveMap();
    }
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.global.layout) {
        this.setState({
          layout: state.global.layout,
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe && this.unsubscribe();
    this.deleteMapPolylines();
    this.deleteMapMarkers();
  }

  componentDidUpdate(prevProps) {
    const polylinesChange = JSON.stringify(prevProps.polylines) !== JSON.stringify(this.props.polylines);
    if (!this.props.static) {
      if (polylinesChange) {
        this.addMapPolylines();
        this.addMapMarkers();
        this.focusOnLine();
      }
    }
  }

  focusOnLine = () => {
    const bounds = this.getBounds();
    if (!bounds) {
      return false;
    }
    this.map.fitBounds(bounds, { padding: this.interactiveMapPadding, offset: [0, -50] });
    this.props.onBoundsChange && this.props.onBoundsChange(bounds);
  };

  initInteractiveMap = () => {
    // grab the center point
    this.getInitMapCenter().then(center => {
      const mapContainer = document.createElement('div');
      mapContainer.style.width = '100%';
      mapContainer.style.height = '100%';

      // init the map
      this.map = new mapboxgl.Map({
        container: mapContainer,
        style: `mapbox://styles/${SETTINGS.mapbox.style}`,
        maxZoom: this.props.maxZoom,
        minZoom: this.props.minZoom,
        interactive: !this.props.static,
        center: center,
        // pitch: 60,
        // bearing: -20,
        maxPitch: 0,
        minPitch: 0,
        zoom: 2,
        bounds: this.props.static ? this.getBounds() : null,
        attributionControl: false,
        // preserveDrawingBuffer: true, @NOTE: dont think this is needed as no longer taking screenshot of map for sharing
        pitchWithRotate: false,
        touchPitch: false,
      });

      // add the map controls
      if (!this.props.static) {
        const navigationControl = new mapboxgl.NavigationControl({
          showZoom: false,
        });
        // const pitchControl = new PitchControl({});
        this.map.controls = [];
        this.map.controls.push({
          label: 'Reset map bearing',
          control: navigationControl,
        });
        // this.map.controls.push({
        //   label: 'Toggle map pitch',
        //   control: pitchControl,
        // });
        this.map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'bottom-left');
        // this.map.addControl(new mapboxgl.NavigationControl({ showZoom: false }), 'bottom-right');
        // this.map.addControl(new PitchControl({}), 'bottom-right');
      }

      this.map.on('load', this.onInteractiveMapLoad);
      !this.props.static && store.dispatch(saveMap(this.map));

      this.mapEl.current && this.mapEl.current.appendChild(this.map.getContainer());
      this.map.resize();
    });
  };

  reInitInteractiveMap = () => {
    // @NOTE: not sure why this isnt needed anymore
    // if (this.map.loaded()) {
    //   this.onInteractiveMapLoad();
    // } else {
    //   this.map.on('load', this.onInteractiveMapLoad);
    // }
    this.onInteractiveMapLoad();
    this.mapEl.current.appendChild(this.map.getContainer());
    this.map.resize();
  };

  getInitMapCenter = () => {
    return new Promise(resolve => {
      let center;
      if (this.props.polyline && this.props.polyline.length) {
        center = this.props.polyline[Math.round(this.props.polyline.length / 2)] || this.props.polyline[0];
        resolve(center);
      } else {
        getCurrentPosition()
          .then(position => {
            center = [position.longitude, position.latitude];
            resolve(center);
          })
          .catch(error => {
            resolve([-115.8605, 31.9505]);
          });
      }
    });
  };

  onInteractiveMapLoad = () => {
    if (!this.mounted) {
      return false;
    }
    // ready
    this.setState({ ready: true });
    this.deleteMapPolylines();
    this.deleteMapMarkers();
    this.addMapPolylines();
    this.addMapMarkers();

    if (this.props.static) {
      this.map.setPitch(0);
      this.map.setBearing(0);
    }

    // wait for map to center and fire onload
    const bounds = this.getBounds();
    setTimeout(() => {
      if (!this.mounted) {
        return false;
      }
      if (this.props.static) {
        this.map.fitBounds(bounds, { padding: this.staticMapPadding, duration: 0 });
      } else {
        this.map.once('moveend', () => {
          this.map.flyTo({
            pitch: this.props.pitch,
            bearing: this.props.bearing,
            duration: 0,
          });
        });
        if (bounds) {
          this.map.fitBounds(bounds, {
            padding: this.interactiveMapPadding,
            offset: [0, -50],
            duration: 0,
          });
        } else {
          this.map.flyTo({
            zoom: this.props.zoom,
            duration: 0,
          });
        }
      }
      if (this.props.onLoad) {
        this.props.onLoad(this.map, bounds);
      }
    }, 100);

    this.map.on('idle', () => {
      // your code  here
    });
  };

  addMapPolylines = () => {
    this.deleteMapPolylines();

    if (this.props.originalPolyline) {
      const line = getPolylineGeoJson(this.props.originalPolyline, colors.tertiaryDark);
      line.id = 'original_polyline';
      if (this.map.getLayer('original_polyline')) {
        this.map.removeLayer('original_polyline');
      }
      this.map.addLayer(line);
    }

    // draw the polyline if a single polyline
    if (!this.props.polylines && this.props.polyline) {
      const line = getPolylineGeoJson(this.props.polyline, this.props.polylineColor);
      line.id = 'polyline';
      if (this.map.getLayer('polyline')) {
        this.map.removeLayer('polyline');
      }
      this.map.addLayer(line);
    }

    // draw the polylines
    if (this.props.polylines) {
      this.drawPolylines();
    }
  };

  drawPolylines = () => {
    // create the heatmap geojson
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: this.props.polyline,
            type: 'LineString',
          },
        },
      ],
    };
    if (this.map.getSource('heatmap')) {
      this.map.removeSource('heatmap');
    }
    this.map.addSource('heatmap', {
      type: 'geojson',
      lineMetrics: true,
      data: geojson,
    });
    // add the layer to the map
    const heatmap = this.getPolylinesGeoJson();
    if (this.map.getLayer('heatmap')) {
      this.map.removeLayer('heatmap');
    }
    this.map.addLayer(heatmap);
  };

  deleteMapPolylines = () => {
    if (this.map) {
      const layers = ['heatmap', 'polyline', 'original_polyline'];
      layers.forEach(id => {
        if (this.map.getLayer(id)) {
          this.map.removeLayer(id);
        }
        if (this.map.getSource(id)) {
          this.map.removeSource(id);
        }
      });
    }
  };

  addMapMarkers = () => {
    this.deleteMapMarkers();
    const line = this.props.polyline;
    if (line && line.length) {
      this.map.loadImage(icon, (e, img) => {
        // if (e) throw new Error(e);
        if (!this.map.hasImage('marker')) {
          this.map.addImage('marker', img);
        }
        const start = line[0];
        const end = line[line.length - 1];

        this.deleteMapMarkers(false);

        this.map.addSource('markers', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: start,
                },
                properties: {
                  title: 'A',
                },
              },
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: end,
                },
                properties: {
                  title: 'B',
                },
              },
            ],
          },
        });
        this.map.addLayer({
          id: 'markers',
          type: 'symbol',
          source: 'markers',
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'marker',
            'icon-size': 0.06,
            'icon-anchor': 'bottom',
            'text-field': ['get', 'title'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-offset': [0, -0.9],
            'text-anchor': 'bottom',
            'text-allow-overlap': true,
            'text-size': 12,
          },
          paint: {
            'text-color': '#fff',
          },
        });
      });
    }
  };

  deleteMapMarkers = (image = true) => {
    if (this.map) {
      if (this.map.getLayer('markers')) {
        this.map.removeLayer('markers');
      }
      if (this.map.getSource('markers')) {
        this.map.removeSource('markers');
      }
      if (this.map.hasImage('marker') && image) {
        this.map.removeImage('marker');
      }
    }
  };

  getBounds = () => {
    if (this.props.polyline && this.props.polyline.length) {
      // center the map on the line
      const bounds = new mapboxgl.LngLatBounds();
      this.props.polyline.forEach(point => {
        bounds.extend(point);
      });
      return bounds;
    } else {
      return null;
    }
  };

  getPolylinesGeoJson = () => {
    if (!this.props.polyline) {
      return false;
    }
    // calc the total dist of the line
    const totalDist = length(lineString(this.props.polyline));
    let totalPercentage = 0;

    // the geo json gradient code to add to
    const gradient = ['interpolate', ['linear'], ['line-progress']];

    // loop the individual lines
    this.props.polylines.forEach((line, i) => {
      // calc the dist of the line and the percentage of the total ride it takes up
      const dist = distance(line.path[0], line.path[1]);
      const diff = 0.0000000000001;
      const percentage = dist / totalDist;
      if (percentage > 0) {
        const stop1 = totalPercentage;
        const stop2 = totalPercentage + percentage;
        if (stop1 < stop2) {
          gradient.push(stop1 + diff);
          gradient.push(line.color);
          // add another stop
          gradient.push(stop2 - diff);
          gradient.push(line.color);
        }
        totalPercentage = stop2;
      }
    });

    // gradient.push(1);
    // gradient.push(this.props.polylines[this.props.polylines.length - 1].color);

    // return created line
    return {
      type: 'line',
      source: 'heatmap',
      id: 'heatmap',
      paint: {
        'line-color': colors.primary,
        'line-width': 2,
        'line-gradient': gradient,
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
    };
  };

  render() {
    const classNames = classnames(styles.interactive, {
      [styles.interactiveStatic]: this.props.static,
    });
    return (
      <MapHolder width={this.props.width} height={this.props.height} ready={this.state.ready} className={classNames}>
        {this.props.static && <div className={styles.staticOverlay} />}
        <div className={styles.map} ref={this.mapEl} />
      </MapHolder>
    );
  }
}

export default MapInteractive;
