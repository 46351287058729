const rides = [{
    "resource_state": 2,
    "athlete": {
      "id": -1,
      "resource_state": 1
    },
    "name": "Afternoon Ride",
    "distance": 21875.2,
    "moving_time": 3229,
    "elapsed_time": 3392,
    "total_elevation_gain": 238.3,
    "type": "Ride",
    "workout_type": null,
    "id": 0,
    "external_id": "d2626ffeeed9e9943c5c1aad489b52b7",
    "upload_id": 2535943214,
    "start_date": "2019-05-21T08:24:06Z",
    "start_date_local": "2019-05-21T16:24:06Z",
    "timezone": "(GMT+08:00) Australia/Perth",
    "utc_offset": 28800,
    "start_latlng": [
      -31.954505,
      115.857057
    ],
    "end_latlng": [
      -32.071363,
      115.7874
    ],
    "location_city": null,
    "location_state": null,
    "location_country": "",
    "start_latitude": -31.954505,
    "start_longitude": 115.857057,
    "achievement_count": 5,
    "kudos_count": 1,
    "comment_count": 0,
    "athlete_count": 1,
    "photo_count": 0,
    "map": {
      "id": "a2384621287",
      "summary_polyline": "tb`bEqicbUCKy@DaAIFNh@`@hAr@JBJID??DBCMC`ClAj@jABd@N?x@y@J?DJtBGjDdANEPq@LIbBl@pChA@Ns@|CBzCHnBHxATzAb@xAR`AVp@KRgB|ACZB^Rz@\\fAn@~@x@f@HZTVj@Zt@Hx@?~Ah@XRx@`Ad@z@n@f@tTzBv@Cf@SJ_@AYDSNMD_@RKtCq@dBo@nEmApEuAtFwBdAU`@FZC~@e@hAUbAg@`@Gl@UPDNRHZ@x@Vd@p@rCXvA^b@XHv@G~Dk@jCUzDm@~BKtBBlAMfBLnCSfAQbH}C~HeFhAi@tAc@xHcE`H_DpC{@nAMlBe@LGJo@FEz@LXItMeFzJmBbIStBJ`CAlCHdIlAbFj@xG`A~GjBtALrCAp@[TALHO\\IBQI?KHGJJBfCETK?AMZIRBrAa@dEm@lDkApCs@`AKhCE~@KfAq@n@ObAPlAh@h@`@p@v@hAt@j@NPE\\aAEc@?{ATcBZ}@z@{@|CuA`ImBnGi@|N@fBLxAZ`CFpB@lCQ|@Oz@_@vCu@rAw@jAe@`FgDlDqBj@g@bBeAvDaArC@rFc@`BJlBZ~@l@zApAxBl@nAVNIRY|@`@PP~A~@`L~EdC~AtGrC`Al@@r@xB~AbALpD~@dANtCl@tBj@bBRNNDPLHFGAk@BUZInQhCJ@VOlBL^Lf@b@h@NhAw@v@KnJ`CnBZdBbAxAC`@F~@d@`A^pFlAx@f@b@NpCp@`DX|B`@bEb@tE@vBVlBf@~Mp@hAPbJ\\pD\\j@Fz@XbAt@Zd@L@\\C`Ae@tBAf@Ql@u@`@IjBN`A?xIr@RJp@lAh@f@`@HdA?LBHNFb@Mt@?\\?pHC\\y@dB@NhAdAp@dA`A`CEhE?~DHvEHhBUjCB|BPrBOzLQpAoAxBMZIf@MjO?`CNlBp@lCfBtEv@bCp@hAZpAfC|Gv@z@Ln@\\v@l@j@h@Pj@D|DCr@OBD_@jFOdEHvAl@nEjBfE^h@~DxDVb@z@z@DVXTHX^h@j@`B^~Af@vCKPY~@{Bz@eB|@u@R_@@?f@_Bp@Bd@OPFbCC|BBjCCnEBhGCjA?`GDvACxUHdJLtZ@fAJj@ARMb@DbFEdHBzAKvEDz@EvAFtCKzZ",
      "resource_state": 2
    },
    "trainer": false,
    "commute": false,
    "manual": false,
    "private": false,
    "visibility": "everyone",
    "flagged": false,
    "gear_id": "b3541914",
    "from_accepted_tag": false,
    "average_speed": 6.775,
    "max_speed": 10.9,
    "average_watts": 132.4,
    "kilojoules": 427.7,
    "device_watts": false,
    "has_heartrate": true,
    "average_heartrate": 154.5,
    "max_heartrate": 190,
    "heartrate_opt_out": false,
    "display_hide_heartrate_option": true,
    "elev_high": 63.3,
    "elev_low": -0.6,
    "pr_count": 3,
    "total_photo_count": 0,
    "has_kudoed": false,
  },
  {
    "resource_state": 2,
    "athlete": {
      "id": -1,
      "resource_state": 1
    },
    "name": "Morning Ride",
    "distance": 23376.1,
    "moving_time": 3404,
    "elapsed_time": 6814,
    "total_elevation_gain": 282.1,
    "type": "Ride",
    "workout_type": null,
    "id": 1,
    "external_id": "787dbb0aa77d91c86f34738dc797862a",
    "upload_id": 2535301209,
    "start_date": "2019-05-20T22:19:03Z",
    "start_date_local": "2019-05-21T06:19:03Z",
    "timezone": "(GMT+08:00) Australia/Perth",
    "utc_offset": 28800,
    "start_latlng": [
      -32.071204,
      115.787328
    ],
    "end_latlng": [
      -31.954061,
      115.856778
    ],
    "location_city": null,
    "location_state": null,
    "location_country": "",
    "start_latitude": -32.071204,
    "start_longitude": 115.787328,
    "achievement_count": 2,
    "kudos_count": 0,
    "comment_count": 0,
    "athlete_count": 2,
    "photo_count": 0,
    "map": {
      "id": "a2383999014",
      "summary_polyline": "vqwbE{~uaU@kBOyIFqA?wEFkEGqBFs@?kAIaG?yJH{AG}ADkAGkBA{G@wDLuECmCO_IB{II}JDuD@}QHmJEwAGe@Ia@F]NUzIgEvBy@Zc@?M_BgH{@yB}AyBkDeDu@cAaAiBgAeD]qBM}CLeGVqCUK_APgEBeA[cA}@e@eB{@s@uCwHa@o@Km@eEkLYoAMiBBgLAyEF{@Nm@|@kA^kAFc@RqL_@gE^eDAw@]uDKOF?@MAaEFyDM{A_@yA_AcBcAkACYv@gBD[@}IKQ}@OSO{@eAq@aBUK}AG{Ek@yCOqABk@t@k@VuBFoAb@[EeBaBs@UaCYgE[kGMo@KqF]aFSoFeAgAEmCHoIcAc@AmH{AqAs@}G{Am@c@c@MyABa@K}@m@a@OcMuCo@EYFq@d@y@NYe@UMe@KaBIa@NuF}@}IgAY?C`AS@OYOEcBUiBi@yBa@gD{@mDk@gCiBCq@EGe@Um@KoB{@yA{@sAc@{KkG{FqCUKW@ANQNi@SuCm@i@Wo@m@yAy@sCg@k@AsD^gE?]BoBj@kBz@}CrBaA`@}@r@_Ad@kClBi@RyA|@wEtAeBZyBNaCA}BK_C_@kDIeI@kE^cCJiAVw@XyEjAmB|@eA|@a@hAWpAAjCEZMP]L[@SEaAu@iAkAg@WqAg@w@E[HoAv@[FkDLwAN}IdCiDh@q@X]GWBGLLJF_@McCMIELLLPCLKEWMAWBUT}AP}AEuAUwEyAuYcEsGQsCDmBEs@JkDDmGrAiANsI`DmEzAYKEu@BuCCiF?aFHoDCg@uBc@yFi@cJ{A_CYYMd@gGl@_FGc@gCc@eCWyCi@mHaAIWP}BCCb@BIg@QQiVoDgGiAUBU|@MdAc@j@Jn@@n@Gl@In@uArEAtBK`BYjCQx@u@`AMb@_@dDYdAaCdFg@~A]bBUd@e@L@^Ih@{ClJq@vAa@rAQp@C`@_@rAm@dA_@b@o@f@}Az@sInDsC|@_GnAw@\\k@b@WVSl@P|AX~@J`ASl@OJIR?`@O\\e@Pg@DaE_@oO_BYUcBeCa@UqAc@aC[o@a@e@u@k@[m@cAa@sASoAD_@NYbBiAuAgF[iDMuFRcBZkAEOeFmBQCKLMh@QDyDsAe@??Mo@Gc@DuCs@m@?GQSQ_B_@QBIK",
      "resource_state": 2
    },
    "trainer": false,
    "commute": false,
    "manual": false,
    "private": false,
    "visibility": "everyone",
    "flagged": false,
    "gear_id": "b3541914",
    "from_accepted_tag": false,
    "average_speed": 6.867,
    "max_speed": 13.1,
    "average_watts": 130.6,
    "kilojoules": 444.6,
    "device_watts": false,
    "has_heartrate": true,
    "average_heartrate": 145.2,
    "max_heartrate": 177,
    "heartrate_opt_out": false,
    "display_hide_heartrate_option": true,
    "elev_high": 63.3,
    "elev_low": -0.6,
    "pr_count": 1,
    "total_photo_count": 0,
    "has_kudoed": false
  },
  {
    "resource_state": 2,
    "athlete": {
      "id": -1,
      "resource_state": 1
    },
    "name": "Afternoon Ride",
    "distance": 22035,
    "moving_time": 3462,
    "elapsed_time": 3682,
    "total_elevation_gain": 246.4,
    "type": "Ride",
    "workout_type": null,
    "id": 2,
    "external_id": "28d5a6760feb29430acd72af81682770",
    "upload_id": 2533530253,
    "start_date": "2019-05-20T08:28:45Z",
    "start_date_local": "2019-05-20T16:28:45Z",
    "timezone": "(GMT+08:00) Australia/Perth",
    "utc_offset": 28800,
    "start_latlng": [
      -31.954505,
      115.857057
    ],
    "end_latlng": [
      -32.071379,
      115.787408
    ],
    "location_city": null,
    "location_state": null,
    "location_country": "",
    "start_latitude": -31.954505,
    "start_longitude": 115.857057,
    "achievement_count": 1,
    "kudos_count": 0,
    "comment_count": 0,
    "athlete_count": 1,
    "photo_count": 0,
    "map": {
      "id": "a2382270064",
      "summary_polyline": "tb`bEqicbUDD{@Co@RCFFRjAtA\\GrDjBh@r@l@@Fa@f@F^YTCx@Lz@XbATXCT}@JCjFrBHLEZ]dAQlAJtEN~BPjAt@|C\\z@CPe@d@u@RY`@@ZVjBb@jA^n@z@^H^Zb@|@`@pALnBj@d@^`BxB`@PtHn@~JdAjAOVYBaAVYAWPOhCi@xBs@t@ItCmAr@IlBu@ZCX]~@Ox@[lBUtCw@nCmAjIoCrEmBlBc@hE}A`Do@|As@`QmFbCy@`CcArA[tAk@vCy@|QiGvKeDNCLHB\\EpQDV\\L`JgDxBeAp@OxJkB~BBrEIjHD~ALnNvBvIxA~F`Bv@JnABvAOh@_@XDFNERQBQECGJOLN@t@J`@@d@EXE?GC@Oz@@`Ac@pE{@pAc@xEqAxBE`CUb@O|@m@p@S\\BvBz@~B`Cp@b@j@HNw@?sBNoAPu@h@mAj@g@|BsAhHqB~Ei@|DOzCFvFAlEj@bADn@L~D]lA?~By@v@MvBm@l@c@vAc@j@g@\\MdAgAbC_B\\IlBoAlBeA~Bq@VSbBK~D?hD_@~ANjAXfAj@lBzArCp@d@TPBVc@r@Tn@l@p@V~@t@b@Nh@^bALp@r@f@NbBdA`Cz@vA~@PUVVTBbDtBpAp@IRHRtBrAdAHzAd@`Er@b@TrARxAn@xBX`@d@FG@cAFMpIbAn@RdFl@^KbCPZTT\\ZFZA`Ay@p@CtBn@jJrB|A|@rB@j@Nt@p@XCx@P|ErAj@`@tDt@jBb@zJ~@dGBjDx@~CRz@LdDFv@PpBJn@GvDJf@L|DRj@NlBV`An@|@z@`AIn@Y`BC\\IVMb@m@TMTAbCP`@JlA@hCZvCRf@nAd@n@r@V~@@RFJRIv@CpAB`GQp@k@fA?^rAvAjAlBLVJt@AzBF~FG~ANdFSdCFz@CbAPdCKdLKbAUz@i@d@QVWl@EXMhTPjBZpAvDlKj@z@XnAx@`C|AtDb@\\ZlAZl@f@`@fAb@|EAb@OBD]pEIjEEVRdCb@pCv@xBv@zAbFfFP^f@`@x@hAh@fAVx@~@xDPlAWhAOLoElBeB`@Gd@wAn@ERDPIPDvBElH@`\\DbFCjMPhR?dJDrBAhBIzAHr@D~KOrMFx@GpAFrBKl\\",
      "resource_state": 2
    },
    "trainer": false,
    "commute": false,
    "manual": false,
    "private": false,
    "visibility": "everyone",
    "flagged": false,
    "gear_id": "b3541914",
    "from_accepted_tag": false,
    "average_speed": 6.365,
    "max_speed": 11.9,
    "average_watts": 116.7,
    "kilojoules": 404.1,
    "device_watts": false,
    "has_heartrate": true,
    "average_heartrate": 131.5,
    "max_heartrate": 166,
    "heartrate_opt_out": false,
    "display_hide_heartrate_option": true,
    "elev_high": 63.3,
    "elev_low": -1.1,
    "pr_count": 1,
    "total_photo_count": 0,
    "has_kudoed": false
  },
  {
    "resource_state": 2,
    "athlete": {
      "id": -1,
      "resource_state": 1
    },
    "name": "Morning Ride",
    "distance": 22064.2,
    "moving_time": 3271,
    "elapsed_time": 3584,
    "total_elevation_gain": 255.3,
    "type": "Ride",
    "workout_type": null,
    "id": 3,
    "external_id": "74a362c4ddf1fe12182d009bab28d28b",
    "upload_id": 2532991190,
    "start_date": "2019-05-19T23:00:42Z",
    "start_date_local": "2019-05-20T07:00:42Z",
    "timezone": "(GMT+08:00) Australia/Perth",
    "utc_offset": 28800,
    "start_latlng": [
      -32.071285,
      115.787239
    ],
    "end_latlng": [
      -31.954279,
      115.856939
    ],
    "location_city": null,
    "location_state": null,
    "location_country": "",
    "start_latitude": -32.071285,
    "start_longitude": 115.787239,
    "achievement_count": 0,
    "kudos_count": 0,
    "comment_count": 0,
    "athlete_count": 3,
    "photo_count": 0,
    "map": {
      "id": "a2381748942",
      "summary_polyline": "rqwbEg_vaU?qBFuACgBQ_DFcACuDDeD?{BCc@DiA@aCGaEB]@}CIiBH}AE}DD{@IoBCwGBiFNgGGeD?qBGeEH{GEwABqBEg@F{BGaC?kABsCCiADiBAmGFoCIaHIW@q@LWf@[dLcFTQPa@gB}H[_AkBqCiDcD_AkAkAyBy@_CWeAWqBIcCDiENmBTsAAKO?}@XiEEgAUm@]c@w@UmA}@o@}@mBkAoDg@}@]iBwBmF}AkEQuBHcO?gEZ_Av@cA^sABkCRuFA{A_@gDd@qEGw@[mBGk@JKCsLWuAa@qAwBmCAYv@cBF[BqAEoBDiCQg@gAU}@aAa@k@Gg@Sa@sDUeFg@WIcAEm@BYTy@z@aCBwAf@WEc@g@iAy@sAa@uCSu@OcFGyFWqCWkFUe@GmBi@iBWmDJ}LwAcBc@uCi@kAi@kGuAmB_AuBE_@K}@m@uCm@{Bo@gCg@mA_@[CWFmAz@sAu@_@GkBOc@L{Dk@_@Ac@Q}@MaHu@M@AbAGFOAIc@_CYiAa@g@EsFyAqDo@]O{BqACYDSWm@UMeCcAk@a@c@Iy@[wAM]_@iBkA_Bi@_Ao@uAm@_AiAm@_@oASe@[Um@q@PARONQAUM{D_AcAy@uAu@kA[wBSsARk@@]LkF?}Ah@cAVsAt@_DtBs@V]b@}A|@qAz@k@f@yAj@w@p@wDz@aAZeEVeC@cACcC]iEQ}BCg@HyECuGh@sIrBq@TwAx@]Xg@p@c@vAKv@Cx@B~@Cb@Uh@a@HkAs@mBcB_Bw@w@Mk@Hs@f@]Ly@J_DBg@JoI`CqEv@m@TSBc@IKH?HF@JWEiBESMIGHHLb@MM][Di@\\UA]JcABsAIsGmBwEi@}E{@gMeBgMKkBN_BGoC^yBn@wD|@wGfCqEvAWKCICm@DaBGgCFsDEcDBECBUOiO`FoHvBcBr@mCr@{CnAoErAcBp@}F|AqI|CkBf@mFlB_I`CkCd@aExAUJFHMGkEbBmNnE}Ar@iBb@uFnBuBj@WV_@@o@b@KPDd@EPOPc@Li@BoBYmFk@uAGaGy@s@WcAeB]_@_@Wc@I{@]wAMgAa@k@aAkA{@i@sA[wBPi@`BgAHU_@s@_AkES{CO{Ep@sDEOiFkBSDKj@MJQ?{DuA}AEoAYIMq@E}@]c@Yc@Go@]y@Qr@M",
      "resource_state": 2
    },
    "trainer": false,
    "commute": false,
    "manual": false,
    "private": false,
    "visibility": "everyone",
    "flagged": false,
    "gear_id": "b3541914",
    "from_accepted_tag": false,
    "average_speed": 6.745,
    "max_speed": 13.8,
    "average_watts": 130,
    "kilojoules": 425.3,
    "device_watts": false,
    "has_heartrate": true,
    "average_heartrate": 144.5,
    "max_heartrate": 167,
    "heartrate_opt_out": false,
    "display_hide_heartrate_option": true,
    "elev_high": 63.3,
    "elev_low": -1.1,
    "pr_count": 0,
    "total_photo_count": 0,
    "has_kudoed": false
  }
];

export default rides;