import { Component } from 'react';
import * as strava from '../../api/strava';
import RideList from '../../components/ride-list';
import { LoadingView, InfoView } from '../../views';
import store from '../../store';
import { setLoading } from '../../store/actions';

export class RidesView extends Component {
  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      rides: state.strava.rides,
      hasRides: true,
      updatedAt: null,
    };
    this.mounted = null;
    this.gettingRides = false;

    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      this.setState({
        rides: state.strava.rides,
        hasRides: state.strava.rides.length,
      });
    });
  }

  componentDidMount() {
    this.mounted = true;
    // get the rides
    this.getRides();
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.getRides();
    }
  }

  getRides = () => {
    if (!this.gettingRides) {
      this.gettingRides = true;
      const rides = strava.getRides();
      // rides.cached.then(rides => {
      //   this.gotRides(rides);
      // });
      rides.latest
        .then(rides => {
          this.gotRides(rides);
        })
        .finally(() => {
          this.gettingRides = false;
          this.setState({ updatedAt: Date.now() });
        });
    }
  };

  gotRides = rides => {
    // for testing entry anim of new rides
    // if (this.state.rides.length !== 0) {
    //   const n = { ...rides[rides.length - 1] };
    //   n.id = 'test';
    //   n.name = `Test Ride ${rides.length}`;
    //   rides.unshift(n);
    // }
    // if (!this.mounted) {
    //   return false;
    // }
    // this.setState({ rides, hasRides: rides.length });
    setLoading(false);
  };

  render() {
    if (!this.state.hasRides) {
      return (
        <InfoView tabs weatherSummary title="No Rides Found">
          Looks like you haven't recorded any rides yet. Get on your bike and record some rides on Strava to see them
          appear here.
        </InfoView>
      );
    } else if (this.state.rides.length > 0) {
      return (
        <RideList
          rides={this.state.rides}
          active={this.props.active}
          onRefresh={this.getRides}
          updatedAt={this.state.updatedAt}
        />
      );
    } else {
      return <LoadingView message="Loading Rides..." tabs weatherSummary />;
    }
  }
}
