/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "headwindapp",
            "endpoint": "https://9w9i9znjf9.execute-api.ap-southeast-1.amazonaws.com/production",
            "region": "ap-southeast-1"
        }
    ],
    "aws_content_delivery_bucket": "headwindapp-production",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d3t5m1y18lvgm8.cloudfront.net",
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Currency-production",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Rides-production",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Routes-production",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Segments-production",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Starred-production",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Users-production",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Weather-production",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
