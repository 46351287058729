import React, { Component } from 'react';
import * as strava from '../../api/strava';
import store from '../../store';
import { setTitle } from '../../components/header/actions';
import styles from './login.module.scss';
import stravaButton from '../../assets/images/strava-login-orange.svg';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import GradientImage from '../../components/gradient-image';
import bgImage from '../../assets/images/login-bg.jpg';
import logo from '../../assets/images/headwind-logo.svg';
import { initDemo } from '../../api/demo';
import { setLoading } from '../../store/actions';
import { installApp } from '../../utils';
import { FiArrowDownCircle } from 'react-icons/fi';
import Footer from '../../components/footer';
import { withRouter } from 'react-router-dom';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMe: false,
      prompt: false,
    };
  }

  componentDidMount() {
    strava.logout(false);
    if (this.props.history.location.pathname !== '/') {
      this.props.history.replace('/');
    }
    store.dispatch(setTitle('HEADWIND'));
    if (!sessionStorage.getItem('remember_me')) {
      sessionStorage.setItem('remember_me', this.state.rememberMe.toString());
    }
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      this.setState({ prompt: state.global.installPrompt });
    });
    setTimeout(() => setLoading(false));
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div className={styles.login}>
        <div className={styles.content}>
          <GradientImage src={bgImage} color="#000" height="45vh" className={styles.backgroundImage} />
          <div className={styles.innerContent}>
            <div className={styles.logo}>
              <img src={logo} width="150" alt="Headwind" />
            </div>
            <h1>Login to your account</h1>
            <Button className={styles.stravaButton} onClick={strava.initAuth}>
              <img src={stravaButton} alt="Login with Strava" />
            </Button>
            {this.state.prompt && (
              <Button full onClick={this.install} icon={<FiArrowDownCircle />}>
                Install App
              </Button>
            )}
            <Checkbox
              name="remember_me"
              checked={this.state.rememberMe}
              className={styles.rememberMe}
              onChange={this.rememberMe}
              label="Remember me on this device"
            />
            <p>
              Haven't got a Strava account or want to see what headwind is all about before committing?{' '}
              <button className={styles.link} onClick={initDemo}>
                Click here
              </button>{' '}
              to view the demo.
            </p>
          </div>
          <div className={styles.desktopLoginImage} />
        </div>
        <Footer className={styles.footer} />
      </div>
    );
  }

  install = () => {
    installApp(this.state.prompt);
  };

  rememberMe = checked => {
    const rememberMe = checked;
    sessionStorage.setItem('remember_me', rememberMe ? 'true' : 'false');
    this.setState({ rememberMe });
  };
}

export const LoginView = withRouter(Login);
