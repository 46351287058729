import React from 'react';
import styles from './footer.module.scss';
import { Link } from 'react-router-dom';
import poweredByStrava from '../../assets/images/powered-by-strava.svg';
import classnames from 'classnames';

export const Footer = props => {
  const classNames = classnames(styles.footer, props.className);
  return (
    <footer className={classNames}>
      <div className={styles.footerLeft}>
        &copy; Headwind |{' '}
        <Link className={styles.link} to="/privacy">
          Privacy Policy
        </Link>
      </div>
      <div className={styles.footerRight}>
        <img src={poweredByStrava} width="150" alt="Powered by Strava" />
      </div>
    </footer>
  );
};

export default Footer;
