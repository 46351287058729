const routes = [
  {
    "athlete": {
      "id": -1,
      "username": "jimbo",
      "resource_state": 2,
      "firstname": "Jimmy",
      "lastname": "Jones",
      "city": "",
      "state": "",
      "country": "",
      "sex": "M",
      "premium": false,
      "summit": false,
      "created_at": "2017-01-19T07:14:01Z",
      "updated_at": "2019-02-18T13:54:57Z",
      "badge_type_id": 0,
      "profile_medium": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c49",
      "profile": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c4",
      "friend": null,
      "follower": null
    },
    "description": "",
    "distance": 21500.29163854121,
    "elevation_gain": 223.69779971552973,
    "id": 0,
    "map": {
      "id": "r17109180",
      "summary_polyline": "lm`bEcccbUfNbG^jMxAlFaCvBl@pDfD`EzM~Es@nDiE_@fh@jFnTuFnx@mGv[sQ|n@_T~Y}@jk@pIv`@uInHvEdCaKbGiC~PiCj^l@pMeEpQaLjOsAjPzFbAsB~`@~Ri@jB|BfBxU`GJsAdYbEnDcAdl@|Nlt@|EzJnDjI_CnOl@tFhEQ|KgA`ClE|GHra@WzLwBhFBnWfPla@lJ^GxS~CzHtHbIrCtKi@xAsIdCoAnCJ`kD",
      "resource_state": 2
    },
    "name": "Saved route 1",
    "private": false,
    "resource_state": 2,
    "starred": false,
    "sub_type": 1,
    "timestamp": 1549965073,
    "created_at": "2019-02-12T09:51:13Z",
    "updated_at": "2019-02-12T09:51:13Z",
    "type": 1,
    "estimated_moving_time": 3224
  },
  {
    "athlete": {
      "id": -1,
      "username": "jimbo",
      "resource_state": 2,
      "firstname": "Jimmy",
      "lastname": "Jones",
      "city": "",
      "state": "",
      "country": "",
      "sex": "M",
      "premium": false,
      "summit": false,
      "created_at": "2017-01-19T07:14:01Z",
      "updated_at": "2019-02-18T13:54:57Z",
      "badge_type_id": 0,
      "profile_medium": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c4",
      "profile": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c4",
      "friend": null,
      "follower": null
    },
    "description": "",
    "distance": 29495.695503415773,
    "elevation_gain": 214.90443638886492,
    "id": 1,
    "map": {
      "id": "r11731777",
      "summary_polyline": "v|vbEwwuaUfAbCbRzAk@gvDrOyIgDuLsLiQo@kN|AcLvD_K`UsW|CoJJci@qEsf@i^gGyd@qC_LKaI|BiJmD_u@_Fel@}NoDbAeYcEKrAaVgGgd@eWsOiEkQlAqQ`LqMdE_i@DoN~EeC`KoHwEw`@tIki@iIa\\t@{n@~Sy[rQmx@lG}RnF}q@qHuRoHqa@nDS}AqKwD_MOgPrn@sOoPcK`U_NjL}PnF_W|Pwf@lj@oNbF\\iBx@U|FlVQdk@aAlEuIlH}Uv@mOpFoK`LmGjNmAw@c@jA",
      "resource_state": 2
    },
    "name": "Saved route 2",
    "private": false,
    "resource_state": 2,
    "starred": false,
    "sub_type": 1,
    "timestamp": 1516665266,
    "created_at": "2018-01-22T23:54:26Z",
    "updated_at": "2018-01-22T23:54:27Z",
    "type": 1,
    "estimated_moving_time": 4424
  },
  {
    "athlete": {
      "id": -1,
      "username": "jimbo",
      "resource_state": 2,
      "firstname": "Jimmy",
      "lastname": "Jones",
      "city": "",
      "state": "",
      "country": "",
      "sex": "M",
      "premium": false,
      "summit": false,
      "created_at": "2017-01-19T07:14:01Z",
      "updated_at": "2019-02-18T13:54:57Z",
      "badge_type_id": 0,
      "profile_medium": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c4",
      "profile": "https://avatars.dicebear.com/api/male/jimbojones.svg?background=%2335b8c4",
      "friend": null,
      "follower": null
    },
    "description": "",
    "distance": 26568.708983742737,
    "elevation_gain": 217.31000000000003,
    "id": 2,
    "map": {
      "id": "r11058441",
      "summary_polyline": "j~pbEuspaUTiPal@cBcS{DsDeEc@wUvEyI?qU{FaoBL{FdJyRaDqf@wHuMnHaP}GqMhDaDg@uL`AsFc@wAwDGsHsLeJ_ZwLySm^yMig@}ECmEqNeAnKaaC}BaLaHoEw`@tIki@iIa\\t@{n@~Sy[rQmx@lGoTtFkp@wHuRoHqa@nDS}AqKwD_MOgPrn@sOoPcK`U_NjL}PnF_W|Pwf@lj@oNbF\\iBx@U|FlVQdk@aAlEuIlH}Uv@mOpFoK`LyLhW",
      "resource_state": 2
    },
    "name": "Saved route 3",
    "private": false,
    "resource_state": 2,
    "starred": false,
    "sub_type": 1,
    "timestamp": 1509459638,
    "created_at": "2017-10-31T14:20:38Z",
    "updated_at": "2017-10-31T14:26:08Z",
    "type": 1,
    "estimated_moving_time": 3985
  }
];

export default routes;