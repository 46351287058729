import React, { Component } from 'react';
import store from '../../store';
import { setTitle } from '../../components/header/actions';
import { setLoading } from '../../store/actions';
import styles from './privacy.module.scss';

export class PrivacyView extends Component {

  componentDidMount() {
    store.dispatch(setTitle('Privacy'));
    setTimeout(() => setLoading(false));
  }

  render() {
    return (
      <div className={styles.container}>

        <h1>Privacy Policy</h1>

        <p>If you require any more information or have any questions about our privacy policy,
        please feel free to contact us by email at contact@headwind.app.</p>

        <p>At headwind.app we consider the privacy of our visitors to be extremely important.
        This privacy policy document describes in detail the types of personal information
        is collected and recorded by headwind.app and how we use it.</p>

        <h2>Log Files</h2>
        <p>Like many other Web sites, headwind.app makes use of log files. These files merely
        logs visitors to the site - usually a standard procedure for hosting companies and
        a part of hosting services's analytics. The information inside the log files includes
        internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date/time
        stamp, referring/exit pages, and possibly the number of clicks. This information is used
        to analyze trends, administer the site, track user's movement around the site, and gather
        demographic information. IP addresses, and other such information are not linked to any
        information that is personally identifiable.</p>

        <h2>Cookies and Web Beacons</h2>
        <p>headwind.app uses cookies to store information about visitors' preferences, to record user-specific
        information on which pages the site visitor accesses or visits, and to personalize or customize
        our web page content based upon visitors' browser type or other information that the visitor sends
        via their browser.</p>

        <p>headwind.app has no access to or control over these cookies that are used by third-parties.</p>

        <h2>Third Party Privacy Policies</h2>
        <p>You should consult the respective privacy policies of these third-parties for more detailed
        information on their practices as well as for instructions about how to opt-out of certain practices.
        headwind.app's privacy policy does not apply to, and we cannot control the activities of, such other
        advertisers or web sites. You may find a comprehensive listing of these privacy policies and their
        links on their websites.</p>

        <p>If you wish to disable cookies, you may do so through your individual browser options.
        More detailed information about cookie management with specific web browsers can be found at the
        browsers' respective websites.</p>

        <h2>Children's Information</h2>
        <p>We believe it is important to provide added protection for children online.
        We encourage parents and guardians to spend time online with their children to observe, participate
        in and/or monitor and guide their online activity. headwind.app does not knowingly collect any personally
        identifiable information from children under the age of 13. If a parent or guardian believes that
        headwind.app has in its database the personally-identifiable information of a child under the age of 13,
        please contact us immediately (using the contact in the first paragraph) and we will use our best
        efforts to promptly remove such information from our records.</p>

        <h2>Online Privacy Policy Only</h2>
        <p>This privacy policy applies only to our online activities and is valid for visitors to our
        website and regarding information shared and/or collected there. This policy does not apply
        to any information collected offline or via channels other than this website.</p>

        <h2>Consent</h2>
        <p>By using our website, you hereby consent to our privacy policy and agree to its terms.</p>

        <p><em>This Privacy Policy was last updated on: Tuesday, September 24th, 2019.
        Should we update, amend or make any changes to our privacy policy, those changes will be posted here.</em></p>

      </div>
    );
  }
}
