import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import * as strava from './api/strava';
import store from './store';
import { setLoading, setInstallPrompt, setFontLoaded, updateShared } from './store/actions';
import { Route, Switch, withRouter } from 'react-router-dom';
import * as Message from './components/message';
import Header from './components/header';
import MenuDrawer from './components/menu-drawer';
import { updateStarredRideIds } from './api/strava/actions';
import Loading2 from './components/loading2';
import LoadingBar from './components/loading-bar';
import Splash from './components/splash';
import RouteMask from './components/route-mask';
import { SETTINGS } from './config';
import { trackPageView, trackEvent } from './utils/ga';
import { isInstalled, isEmbedded, hasUserSettings, stateChanged } from './utils';
import WebFont from 'webfontloader';
import IosInstallPrompt from './components/ios-install-prompt';
import { MainContainer, DesktopContainer } from './components/containers';
import MobileDetect from 'mobile-detect';
import MQ from './components/media-query';
import SideBar from './components/sidebar';
import Footer from './components/footer';
import SurveyModal from './components/survey-modal';
import { UpdateToast } from './components/update-toast';
import classnames from 'classnames';

// import the views
import {
  LoginView,
  DashboardView,
  LoadingView,
  RideView,
  ForecastView,
  LogoutView,
  PrivacyView,
  FourZeroFourView,
  SettingsView,
  InitDemoView,
  AdminDashboardView,
  RainRadarView,
  TourDeFranceView,
} from './views';

// configure amplify
if (Array.isArray(awsmobile.aws_cloud_logic_custom)) {
  let endpoint = 'https://api.headwind.app';
  if (window.location.hostname.includes('development') || window.location.hostname.includes('localhost')) {
    endpoint = 'https://api.development.headwind.app';
  }
  awsmobile.aws_cloud_logic_custom[0].endpoint = endpoint;
}
Amplify.configure(awsmobile);

// mobile detect
const md = new MobileDetect(window.navigator.userAgent);

class App extends Component {
  constructor(props) {
    super(props);

    this.defaultTitle = 'HEADWIND';
    this.isEmbedded = isEmbedded();

    const state = store.getState();

    this.state = {
      messages: [],
      token: state.strava.token,
      requestingToken: state.strava.requestingToken,
      title: this.defaultTitle,
      loading: false,
      loadingLong: false,
      loadingBar: false,
      splash: true,
      lastViewRide: false,
      version: state.global.version,
      layout: state.global.layout,
      shared: state.global.shared,
      user: state.strava.user,
    };

    WebFont.load({
      google: {
        families: ['Roboto:300,400,500', 'Raleway:300,400,600'],
      },
      active: () => {
        store.dispatch(setFontLoaded(true));
      },
    });

    // check for a token in the url and save for use with api
    strava.checkForAuthToken().then(() => {
      if (!hasUserSettings()) {
        this.goToSettings();
      }
    });

    strava.clearExpiredCacheItems();

    if (this.state.token && !this.state.shared) {
      strava.getStarredRideIds().then(response => {
        store.dispatch(updateStarredRideIds(response.rides));
      });
    }

    this.listener = this.props.history.listen(location => {
      trackPageView(location);
      this.navigationStart();
    });

    if (!navigator.onLine) {
      // @NOTE: this fucks up in chrome on android
      // this.triggerOfflineMessage();
    }

    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (!state.global.loading && this.state.loadingLong) {
        clearTimeout(this.loadingTimer);
        this.setState({
          loadingLong: false,
        });
      }
      // Make sure everything isnt rerendered just because the store updated
      const oldState = {
        loading: this.state.loading,
        token: this.state.token,
        requestingToken: this.state.requestingToken,
        messages: this.state.messages,
        title: this.state.title,
        loadingBar: this.state.loadingBar,
        layout: this.state.layout,
        shared: this.state.shared,
        version: this.state.version,
        user: this.state.user,
      };
      const newState = {
        loading: state.global.loading,
        token: state.strava.token,
        requestingToken: state.strava.requestingToken,
        messages: state.messages.messages,
        title: this.props.history.location.pathname === '/' ? this.defaultTitle : state.header.title,
        loadingBar: state.global.loadingBar,
        layout: state.global.layout,
        shared: state.global.shared,
        version: state.global.version,
        user: state.strava.user,
      };
      if (stateChanged(oldState, newState)) {
        this.setState(newState);
      }
    });

    window.addEventListener('beforeinstallprompt', this.beforeInstallPrompt);
    window.addEventListener('online', this.online);
    window.addEventListener('offline', this.triggerOfflineMessage);

    const installed = isInstalled();
    const loggedInstall = localStorage.getItem('installed');
    if (installed && !loggedInstall) {
      localStorage.setItem('installed', true);
      trackEvent('App Installed', md.os() || 'Desktop', window.navigator.userAgent);
    }

    // update user in case they donated on another device and dont see the updated donation amount
    // also send to the settings page if for some reason they dont have settings
    if (this.state.token && !this.state.shared) {
      strava.getUser(false, localStorage.getItem('strava_user') ? true : false).then(() => {
        if (!hasUserSettings()) {
          this.goToSettings();
        }
      });
    }

    if (this.isEmbedded) {
      // this is disabled due to security issues and errors when embedding
      // trackEvent('Embedded', window.top.location.origin, window.top.location.href);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    window.removeEventListener('beforeinstallprompt', this.beforeInstallPrompt);
    window.removeEventListener('online', this.online);
    window.removeEventListener('offline', this.triggerOfflineMessage);
  }

  goToSettings = () => {
    if (this.props.history.location.pathname !== '/settings') {
      this.props.history.push('/settings');
    }
  };

  online = () => {
    store.dispatch(Message.remove('offline'));
  };

  triggerOfflineMessage = () => {
    clearTimeout(this.offlineMessageTimeout);
    this.offlineMessageTimeout = setTimeout(() => {
      if (!navigator.onLine) {
        store.dispatch(
          Message.add({
            type: 'warning',
            text: 'Currently offline. Some features may not work correctly.',
            id: 'offline',
            showFor: 10000,
          }),
        );
      }
    }, 2000);
  };

  beforeInstallPrompt = e => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // save the prompt for later use
    store.dispatch(setInstallPrompt(e));
  };

  hideSplash = () => {
    // hide splash after x seconds
    clearTimeout(this.hideSplashTimeout);
    setTimeout(() => {
      this.hideSplashTimeout = setTimeout(() => {
        this.setState({
          splash: false,
        });
      }, 2000);
    });
  };

  navigationStart = () => {
    setLoading(true, 0);
    clearTimeout(this.navigationTimeout);
    clearTimeout(this.checkForUpdatesTimer);
    store.dispatch(updateShared());
    this.navigationTimeout = setTimeout(() => {
      window.scrollTo(0, 0);
      this.navigationEnd();
    }, SETTINGS.pageTransitionSpeed);
  };

  navigationEnd = () => {
    if (this.state.loading) {
      this.loadingTimer = setTimeout(() => {
        this.setState({
          loadingLong: true,
        });
      }, 100);
    }
  };

  render() {
    const showLoadingBar = this.state.loadingBar || this.state.loading;
    const classNames = classnames('app', {
      unauth: !this.state.token,
    });
    return (
      <Route
        render={({ location }) => (
          <MainContainer className={classNames}>
            {showLoadingBar && <LoadingBar />}
            {this.state.token && <UpdateToast />}
            <Header title={this.state.title} slim={this.state.title === this.defaultTitle} />
            {Message.render(this.state.messages)}
            <MQ mobile={this.mobileView(location)} desktop={this.desktopView(location)} />
          </MainContainer>
        )}
      />
    );
  }

  mobileView = location => {
    return (
      <>
        {this.routerContent(location)}
        {this.state.token && !this.isEmbedded && <MenuDrawer />}
        {!isInstalled() && !this.isEmbedded && (
          <>
            {!this.state.splash && this.state.token && <SurveyModal {...SETTINGS.survey} />}
            <Splash version={this.state.version} onReady={this.hideSplash} pose={this.state.splash ? 'show' : 'hide'} />
          </>
        )}
        {isInstalled() && !this.isEmbedded && this.state.token && !this.state.loading && (
          <SurveyModal {...SETTINGS.survey} />
        )}
        {!this.isEmbedded && <RouteMask color="dark" loading={this.state.loading} />}
        <Loading2 pose={this.state.loadingLong ? 'show' : 'hide'} />
        <IosInstallPrompt />
      </>
    );
  };

  desktopView = location => {
    return (
      <>
        <DesktopContainer full={true}>
          {this.state.token && !this.isEmbedded && !this.state.loading && <SurveyModal {...SETTINGS.survey} />}
          {this.state.token && !this.isEmbedded && <SideBar className="sidebar" />}
          {this.routerContent(location)}
        </DesktopContainer>
        <Footer />
      </>
    );
  };

  routerContent = location => {
    const classNames = classnames('routeContainer', {
      noAuth: this.isEmbedded,
    });
    if (this.state.token) {
      return (
        <div className={classNames}>
          <Switch location={location}>
            <Route exact={true} path="/" component={DashboardView} />
            <Route exact={true} path="/logout" component={LogoutView} />
            <Route exact={true} path="/settings" component={SettingsView} />
            <Route exact={true} path="/forecast/:type/:id" component={ForecastView} />
            <Route exact={true} path="/forecast" component={ForecastView} />
            <Route exact={true} path="/:type/:id/:time?" component={RideView} />
            <Route exact={true} path="/share/:type/:uid/:id/:time/:meta/:sid?" component={RideView} />
            <Route exact={true} path="/embed/:type/:uid/:id/:time/:meta/:sid?" component={RideView} />
            <Route exact={true} path="/rain-radar" component={RainRadarView} />
            <Route exact={true} path="/admin-dashboard" component={AdminDashboardView} />
            <Route exact={true} path="/privacy" component={PrivacyView} />
            <Route exact={true} path="/tour-de-france" component={TourDeFranceView} />
            <Route component={FourZeroFourView} />
          </Switch>
        </div>
      );
    } else if (this.state.requestingToken) {
      return <LoadingView message="Connecting to Strava..." />;
    } else {
      return (
        <div className="routeContainer noAuth">
          <Switch location={location}>
            <Route exact={true} path="/demo" component={InitDemoView} />
            <Route exact={true} path="/privacy" component={PrivacyView} />
            <Route exact={true} path="/share/:type/:uid/:id/:time/:meta" component={RideView} />
            <Route exact={true} path="/embed/:type/:uid/:id/:time/:meta" component={RideView} />
            <Route path="/" component={LoginView} />
          </Switch>
        </div>
      );
    }
  };
}

export default withRouter(App);
