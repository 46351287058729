import React, { Component } from 'react';
import store from '../../store';
import { setTitle } from '../../components/header/actions';
import { setLoading } from '../../store/actions';
import styles from './admin-dashboard.module.scss';
import { withRouter } from 'react-router-dom';
import * as strava from '../../api/strava';
import { getCachedUser } from '../../api/strava/getCachedUser';
import CountUp from 'react-countup';
import { IoMdRefresh } from 'react-icons/io';
import { isAdmin } from '../../utils';

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: 0,
      user: getCachedUser(),
    };
  }

  componentDidMount() {
    store.dispatch(setTitle('Admin Dashboard'));
    setTimeout(() => setLoading(false));
    this.getUsers(200);
  }

  getUsers = (timeout = 0) => {
    this.setState({ users: 0 });
    if (isAdmin(this.state.user)) {
      strava
        .queryApi(`/strava/get/info/Users/${this.state.user.id}`)
        .then(data => {
          setTimeout(() => {
            this.setState({ users: data.Table.ItemCount || 0 });
          }, timeout);
        })
        .catch(error => {
          throw new Error(error);
        });
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <h2 className={styles.title}>
            Admin Dashboard{' '}
            <button className={styles.refresh} onClick={this.getUsers}>
              <IoMdRefresh />
            </button>
          </h2>
          <div className={styles.content}>
            <p className={styles.label}>Total users signed up</p>
            <CountUp className={styles.value} end={this.state.users} duration={2} decimals={0} separator="," />
            <p className={styles.info}>Updated every 6 hours or so.</p>
          </div>
        </div>
      </div>
    );
  }
}

export const AdminDashboardView = withRouter(AdminDashboard);
