import React from 'react';
import PaymentRequestButton from '../payment-request-button';
import { FiCoffee } from 'react-icons/fi';
import { SETTINGS } from '../../config';
import styles from './donate-button.module.scss';

export const DonateButton = props => {
  let info;
  let buttonTxt;

  if (props.donated && props.donated > 0) {
    info = (
      <>
        Thank you for donating <span className={styles.heart}>&hearts;</span>. You will now be among the first to
        recieve new updates. You have currently donated {props.donated} {props.donated > 1 ? 'coffees' : 'coffee'}.
      </>
    );
    buttonTxt = 'Donate Another';
  } else {
    info = (
      <>
        If you <span className={styles.heart}>&hearts;</span> headwind, then be the first to try out new features by
        donating the price of a coffee and helping to keep headwind.app free for everyone.
      </>
    );
    buttonTxt = 'Donate';
  }

  return (
    <PaymentRequestButton
      {...props}
      className={props.className}
      icon={<FiCoffee />}
      successText="
        You Superstar! Thank you for helping to keep headwind free for all who want to use it.
        Honestly, your donation is much appreciated and will go to developing bigger and better features.
        You will now be among the first to try those features out. Enyoy!.
      "
      title={props.title || 'Donate a coffee'}
      amount={SETTINGS.stripe.donate.amount}
      currency={SETTINGS.stripe.donate.currency}
      label="1 Coffee"
      info={props.info !== undefined ? props.info : info}
    >
      {buttonTxt}
    </PaymentRequestButton>
  );
};
