import * as polyline from 'google-polyline';
import { km2m, mapboxPolyline, normalizeDate } from '../../utils';
import length from '@turf/length';
import { lineString } from '@turf/helpers';

export class TourDeFrance {

  apiURL = `${process.env.PUBLIC_URL}/tour-de-france`;

  get = (path) => {
    return new Promise((resolve, reject) => {
      fetch(`${this.apiURL}/${path}`)
        .then(resp => resp.json())
        .then(data => {
          if (data) {
            resolve(data);
          } else {
            reject(`Error: no data found for "${path}`);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  parseRides = (rawStages) => {
    const rides = [];
    Object.keys(rawStages).forEach((stage, i) => {
      rawStages[stage].forEach(part => {
        const dateParts = (part.date).split('/');
        let date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0], 12);
        date = normalizeDate(date);
        const distance = length(lineString(part.polyline));
        if (distance > 1) {
          const mapboxLine = mapboxPolyline(part.polyline);
          const encodedLine = polyline.encode(mapboxLine);
          const name = part.name;
          rides.push({
            id: part.id,
            type: 'route',
            name: name,
            start_date: date,
            start_date_local: date,
            //timezone: "(GMT+08:00) Australia/Perth",
            distance: km2m(distance),
            map: {
              polyline: encodedLine,
              summary_polyline: encodedLine
            },
            start_latlng: mapboxLine[0],
            end_latlng: mapboxLine[mapboxLine.length  - 1],
            start_latitude: mapboxLine[0][1],
            start_longitude: mapboxLine[0][0],
            athlete: {
              sex: 'M'
            }
          });
        }
      });
    });
    return rides;
  }

  getRides = () => {
    return new Promise((resolve, reject) => {
      this.get('stages.json').then((rides) => {
        resolve(this.parseRides(rides));
      }).catch(e => {
        reject(e);
      });
    });
  }

  getRide = (path) => {
    return new Promise((resolve, reject) => {
      this.get(`${path}.json`).then((ride) => {
        const rides = this.parseRides([[ride]]);
        resolve(rides[0]);
      }).catch(e => {
        reject(e);
      });
    });
  }
}

export default TourDeFrance;