import getStravaToken from './getStravaToken';
import { getRideID } from '../../utils';
import { getCachedUser } from './getCachedUser';
import * as query from 'query-string';

const token = getStravaToken();
const params = query.parse(window.location.search);

const initialState = {
  token,
  requestingToken: params.code && params.scope,
  auth: token ? true : false,
  user: getCachedUser ? getCachedUser() : null,
  starredRides: [],
  starredRideIds: [],
  rides: [],
  routes: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_STARRED_RIDE_IDS':
      if (action.payload) {
        const ids = action.payload.map(id => {
          return String(id);
        });
        return {
          ...state,
          starredRideIds: ids,
        };
      } else {
        return state;
      }
    case 'UPDATE_AUTH_TOKEN':
      const auth = action.payload ? true : false;
      return {
        ...state,
        token: action.payload,
        requestingToken: false,
        auth,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'UPDATE_STARRED_RIDES':
      const starredRideIds = action.payload.map(ride => {
        return getRideID(ride);
      });
      return {
        ...state,
        starredRides: action.payload,
        starredRideIds,
      };
    case 'UPDATE_RIDES':
      return {
        ...state,
        rides: action.payload,
      };
    case 'UPDATE_ROUTES':
      return {
        ...state,
        routes: action.payload,
      };
    case 'REQUESTING_AUTH_TOKEN':
      return {
        ...state,
        requestingToken: action.payload,
        auth: false,
      };
    default:
      return state;
  }
};

export default reducer;
