import React, { useState } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import classnames from 'classnames';
import styles from './range-slider.module.scss';
import { oneDecPlace } from '../../utils';

export const RangeSlider = props => {
  const [value, setValue] = useState(props.defaultValue || props.value || props.min || 0);

  const classNames = classnames(styles.container, {
    [styles.vertical]: props.vertical,
    [styles.dark]: props.dark,
    [styles.disabled]: props.disabled,
  });

  const onChange = value => {
    setValue(value);
    props.onChange && props.onChange(value);
  };

  const formatLabel = value => {
    let val = oneDecPlace(value);
    if (props.labelValue) {
      val = props.labelValue;
    }
    if (props.labelSuffix) {
      val = `${val}${props.labelSuffix}`;
    }
    return val;
  };

  return (
    <div className={classNames}>
      <Slider
        min={props.min ? Number(props.min) : 0}
        max={props.max ? Number(props.max) : undefined}
        value={value}
        onChange={onChange}
        step={props.step ? Number(props.step) : undefined}
        format={formatLabel}
        orientation={props.vertical ? 'vertical' : 'horizontal'}
      />
    </div>
  );
};

export default RangeSlider;
