import React from 'react';
import ReactDOM from 'react-dom';
import './global.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { initTracking } from './utils/ga';
import { initStripe } from './utils/stripe';
import { SETTINGS } from './config';
import { Elements } from '@stripe/react-stripe-js';

if (process.env.REACT_APP_VERSION && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SETTINGS.sentry,
    release: process.env.REACT_APP_VERSION,
    whitelistUrls: ['headwind.app/static/js'],
    beforeSend: (event, hint) => {
      const error = hint.originalException.message || hint.originalException;
      if (error && String(error).toLowerCase().includes('network error')) {
        return null;
      }
      return event;
    },
  });
}

initTracking();

ReactDOM.render(
  <Elements stripe={initStripe()}>
    <Router>
      <App />
    </Router>
  </Elements>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
