import React from 'react';
import styles from './sidebar.module.scss';
import classnames from 'classnames';
import Menu from '../menu';

export const SideBar = props => {
  const classNames = classnames(styles.sidebar, props.className);

  return (
    <div className={classNames}>
      <Menu />
    </div>
  );
};

// export class Sidebar extends Component {

//   render() {
//     return (
//       <div className={styles.sidebar}>
//         <h1>Sidebar</h1>
//       </div>
//     );
// }

// render() {
//   const stats = this.state.stats;
//   if (this.state.user) {
//     return (
//       <div>
//         <img src={this.state.user.profile} alt={this.getFullName()} />
//         <div>{this.getFullName()}</div>
//         {this.state.stats && <>commented out stuff in this file</>
//         // <ListItemText primary={`${m2km(stats.all_ride_totals.distance)}km`} secondary="Total kms" />
//         // <ListItemText primary={`${m2km(stats.biggest_ride_distance)}km`} secondary="Longest ever ride" />
//         // <ListItemText primary={`${stats.all_ride_totals.count}`} secondary="Total rides" />
//         // <ListItemText primary={`${stats.biggest_climb_elevation_gain}m`} secondary="Highest elevation gain" />
//         }
//       </div>
//     );
//   } else {
//     return null;
//   }
// }

// private getFirstName() {
//   if (this.state.user) {
//     return this.state.user.firstname || this.state.user.username;
//   }
//   return '';
// }

// getFullName() {
//   if (this.state.user) {
//     return `${this.state.user.firstname} ${this.state.user.lastname}`;
//   }
//   return '';
// }

// getUser() {
//   strava.getUser().then(user => {
//     console.log(user);
//     this.setState({ user });
//     strava.getUserStats(user.id).then(stats => {
//       this.setState({ stats });
//       console.log(stats);
//     });
//   });
// }
// }

export default SideBar;
