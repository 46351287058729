import store from '../../store';
import { updateRides, updateRoutes, updateToken, updateUser } from '../strava/actions';
import demoUser from './user';
import demoRides from './rides';
import demoRoutes from './routes';
import demoRide from './ride';
import * as Message from '../../components/message';

export const demoToken = {
  access_token: 'demo',
  refresh_token: 'demo',
  expires_at: 'demo',
};

export const initDemo = () => {
  sessionStorage.setItem('strava_token', JSON.stringify(demoToken));
  sessionStorage.setItem('strava_user', JSON.stringify(demoUser));
  store.dispatch(updateToken(demoToken));
  store.dispatch(updateUser(demoUser));
  store.dispatch(
    Message.add({
      type: 'success',
      text: 'Successfully logged into demo.',
      id: 'login',
    }),
  );
};

export const queryDemoApi = endpoint => {
  const state = store.getState();

  return new Promise(resolve => {
    let response;

    switch (endpoint) {
      case '/strava/get/user':
        response = demoUser;
        break;

      case '/strava/get/starred-ride-ids/-1':
        response = {
          rides: state.strava.starredRideIds,
        };
        break;

      case '/strava/get/starred-rides/-1':
        response = state.strava.starredRides;
        break;

      case '/strava/get/rides':
        response = demoRides;
        // NOTE: Temp fix for bug with rides not updating
        store.dispatch(updateRides(response));
        break;

      case '/strava/get/ride/0':
        response = demoRide;
        break;

      case '/strava/get/ride/1':
      case '/strava/get/ride/2':
      case '/strava/get/ride/3':
        const rideId = endpoint.split('/').pop();
        response = demoRides[rideId] || {};
        break;

      case '/strava/get/route/0':
      case '/strava/get/route/1':
      case '/strava/get/route/2':
        const routeId = endpoint.split('/').pop();
        response = demoRoutes[routeId] || {};
        break;

      case '/strava/get/routes/-1':
        response = demoRoutes;
        store.dispatch(updateRoutes(response));
        break;

      default:
        response = {};
    }

    setTimeout(() => {
      resolve(response);
    }, 500);
  });
};
