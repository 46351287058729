import React, { Component } from 'react';
import FullScreen from '../../components/full-screen';
import Info from '../../components/info';
import posed from 'react-pose';
import styles from './info.module.scss';

const InfoHolder = posed.div({
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
  },
  hidden: {
    y: '100px',
    opacity: 0,
    scale: 0.5,
  },
});

export class InfoView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pose: 'hidden',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ pose: 'visible' });
    }, 1000);
  }

  render() {
    return (
      <FullScreen tabs={this.props.tabs ? true : null} weatherSummary={this.props.weatherSummary ? true : null}>
        <InfoHolder pose={this.state.pose} className={styles.infoHolder}>
          <Info title={this.props.title}>{this.props.children}</Info>
        </InfoHolder>
      </FullScreen>
    );
  }
}
