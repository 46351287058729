import { Component } from 'react';
import * as strava from '../../api/strava';
import { setLoading } from '../../store/actions';
import { SETTINGS } from '../../config';
import { Redirect } from 'react-router-dom';

export class LogoutView extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    setLoading(true);
    setTimeout(() => {
      strava.logout();
      this.setState({
        redirect: true,
      });
    }, SETTINGS.pageTransitionSpeed);
  }

  render() {
    return this.state.redirect ? <Redirect to="/" /> : null;
  }
}
