import {
  SETTINGS
} from '../../config';
import {
  API
} from 'aws-amplify';
import store from '../../store';
import * as Message from '../../components/message';

const defaultCurrentWeather = {
  apparentTemperature: 0,
  apparentTemperatureHigh: 0,
  apparentTemperatureHighTime: 0,
  apparentTemperatureLow: 0,
  apparentTemperatureLowTime: 0,
  cloudCover: 0,
  dewPoint: 0,
  humidity: 0,
  icon: 'clear-day',
  nearestStormBearing: null,
  nearestStormDistance: 0,
  ozone: 0,
  precipIntensity: 0,
  precipIntensityMax: 0,
  precipIntensityMaxTime: 0,
  precipProbability: 0,
  precipType: null,
  pressure: 0,
  summary: '',
  uvIndex: 0,
  visibility: 0,
  windBearing: null,
  windGust: 0,
  windSpeed: 0,
};

export const getWeather = props => {
  return new Promise((resolve, reject) => {
    let path = `/weather/get/${props.lat}/${props.lng}`;
    if (props.hourly) {
      path += `/null/null/true`;
    } else {
      if (props.time) {
        path += `/${props.time}`;
      }
      if (props.units) {
        path += `/${props.units}`;
      }
    }
    API.get(SETTINGS.dynamodb.apiName, path, null)
      .then(response => {
        if (response.error) {
          store.dispatch(
            Message.add({
              type: 'error',
              text: response.error.toString(),
            }),
          );
        } else {
          const weather = response.data;
          weather.currently = {
            ...defaultCurrentWeather,
            ...weather.currently
          };
          resolve(weather);
        }
      })
      .catch(error => {
        store.dispatch(
          Message.add({
            type: 'error',
            text: error.toString(),
          }),
        );
        reject(error);
      });
  });
};