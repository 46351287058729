import React, { Component } from 'react';
import styles from './tabs.module.scss';
import posed from 'react-pose';
import { Swipeable } from 'react-swipeable';

const TabContentAnimated = posed.div({
  active: {
    transform: 'translateX(0%)',
    // transition: { type: 'spring', stiffness: 100 },
    transition: { duration: 100 },
    applyAtStart: { visibility: 'visible' },
  },
  next: {
    transform: 'translateX(100%)',
    // transition: { type: 'spring', stiffness: 100 },
    transition: { duration: 100 },
    applyAtEnd: { visibility: 'hidden' },
  },
  prev: {
    transform: 'translateX(-100%)',
    // transition: { type: 'spring', stiffness: 100 },
    transition: { duration: 100 },
    applyAtEnd: { visibility: 'hidden' },
  },
});

export const TabContent = React.forwardRef((props, ref) => {
  if (props.hidden) {
    return null;
  }
  return <div ref={ref}>{props.children}</div>;
});

class Tabs extends Component {
  activeIndicator = React.createRef();

  static defaultProps = {
    swipable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      active: props.active || 0,
      loaded: [props.active || 0],
    };
  }

  componentDidMount = () => {
    this.props.children.forEach((child, i) => {
      if (child.props.hidden && i === 0 && !this.props.active) {
        this.setState({
          active: 1,
          loaded: [1],
        });
      }
      if (child.type !== TabContent) {
        console.error(`Element of type '${child.type}' is not allowed in a Tab container`);
      }
    });
    this.updateActiveIndicator();
    window.addEventListener('resize', this.afterResize);
  };

  componentWillReceiveProps(props) {
    if (props.active !== this.props.actve) {
      this.goToTab(props.active, false);
    }
  }

  componentDidUpdate = () => {
    this.updateActiveIndicator();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.afterResize);
  }

  render = () => {
    const stretch = this.props.stretch ? styles.tabNavStretch : '';
    const slim = this.props.slim ? styles.tabNavSlim : '';
    const navClass = this.props.navClass || '';
    const containerClass = this.props.className || '';
    return (
      <div className={`${containerClass}`}>
        <div className={`${navClass}`}>
          <ul className={`${styles.tabNav} ${stretch} ${slim}`}>
            {this.props.children.map((child, i) => {
              const active = this.state.active === i ? styles.tabNavItemActive : '';
              if (child.props.hidden) {
                return <li key={i} className={styles.tabNavItemHidden}></li>;
              }
              return (
                <li className={`${styles.tabNavItem} ${active}`} onClick={this.goToTab.bind(this, i)} key={i}>
                  {child.props.icon && <span className={styles.tabIcon}>{child.props.icon}</span>}
                  <span>{child.props.title || `tab ${i + 1}`}</span>
                </li>
              );
            })}
            <li className={styles.activeIndicator} ref={this.activeIndicator} />
          </ul>
        </div>
        <div className={styles.tabContentContainer}>
          {this.props.children.map((child, i) => {
            if (child.props.hidden) {
              return <div key={i} className={styles.tabContentHidden}></div>;
            }
            let state = 'next';
            if (i === this.state.active) {
              state = 'active';
            } else if (i < this.state.active) {
              state = 'prev';
            }
            return (
              <TabContentAnimated key={i} pose={state} className={`${styles.tabContent} ${styles[state]}`}>
                <Swipeable onSwipedLeft={this.swipedLeft} onSwipedRight={this.swipedRight} trackMouse={true}>
                  {this.state.loaded.includes(i) && <>{child}</>}
                </Swipeable>
              </TabContentAnimated>
            );
          })}
        </div>
      </div>
    );
  };

  afterResize = () => {
    clearTimeout(this.afterResizeTimer);
    this.afterResizeTimer = setTimeout(() => {
      this.updateActiveIndicator();
    }, 500);
  };

  swipedLeft = () => {
    if (this.props.swipable && this.props.children) {
      const active =
        this.state.active === this.props.children.length - 1 ? this.props.children.length - 1 : this.state.active + 1;
      this.goToTab(active);
    }
  };

  swipedRight = () => {
    if (this.props.swipable && this.props.children) {
      const active = this.state.active === 0 ? 0 : this.state.active - 1;
      this.goToTab(active);
    }
  };

  updateActiveIndicator = () => {
    const active = document.querySelector(
      `.${styles.tabNav} .${styles.tabNavItem}:nth-child(${this.state.active + 1})`,
    );
    const indicator = this.activeIndicator.current;
    if (active && indicator) {
      indicator.style.width = `${active.clientWidth}px`;
      indicator.style.left = `${active.offsetLeft}px`;
    }
  };

  goToTab = (tab, triggerOnChange = true) => {
    let loaded = this.state.loaded.slice(0);
    loaded.push(tab);
    loaded = [...new Set(loaded)];

    this.setState({
      active: tab,
      loaded,
    });

    if (typeof this.props.onChange === 'function' && triggerOnChange) {
      this.props.onChange(tab);
    }
  };
}

export default Tabs;
