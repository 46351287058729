import React from 'react';
import LoadingCircle from '../../components/loading-circle';
import FullScreen from '../../components/full-screen';
import styles from './loading.module.scss';

export const LoadingView = props => {
  return (
    <FullScreen
      className={styles.loadingView}
      tabs={props.tabs ? true : null}
      weatherSummary={props.weatherSummary ? true : null}
    >
      <LoadingCircle text={props.message} color="dark" />
    </FullScreen>
  );
};
