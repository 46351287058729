import React, { useEffect, useState } from 'react';
import breakpoints from '../../style-utils/breakpoints.module.scss';
import store from '../../store';
import { setLayout } from '../../store/actions';
import { isEmbedded } from '../../utils';

let resizeTimer = 0;

export const MQ = props => {
  const [mobile, setMobile] = useState(true);

  const detect = () => {
    if (isEmbedded()) {
      setMobile(true);
      store.dispatch(setLayout('mobile'));
    } else if (window.matchMedia(`(min-width: ${breakpoints.tablet})`).matches) {
      setMobile(false);
      store.dispatch(setLayout('desktop'));
    } else {
      setMobile(true);
      store.dispatch(setLayout('mobile'));
    }
  };

  const resize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      detect();
    }, 500);
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    detect();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  if (mobile && props.mobile) {
    return <>{props.mobile}</>;
  } else if (!mobile && props.desktop) {
    return <>{props.desktop}</>;
  } else {
    return null;
  }
};

export default MQ;
