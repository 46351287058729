import React from 'react';
import styles from './checkbox.module.scss';
import { MdCheck } from 'react-icons/md';

export const Checkbox = props => {
  const className = props.className ? props.className : '';
  const light = props.light ? styles.light : '';
  const name = props.name ? props.name : '';
  const label = props.label ? props.label : '';
  const checked = props.checked ? true : false;

  const onChange = e => {
    const el = e.currentTarget.parentNode;
    el.classList.remove(styles.pulse);
    el.classList.add(styles.pulse);
    setTimeout(() => {
      el.classList.remove(styles.pulse);
    }, 1100);
    if (typeof props.onChange === 'function') {
      props.onChange(e.target.checked);
    }
  };

  return (
    <span className={`${className} ${styles.checkbox} ${light}`}>
      {checked && <input type="checkbox" name={name} onChange={onChange} id={name} checked />}
      {!checked && <input type="checkbox" name={name} onChange={onChange} id={name} />}
      <label htmlFor={name}>
        {label}
        <span>
          <MdCheck />
        </span>
      </label>
    </span>
  );
};

export default Checkbox;
