import React from 'react';
import styles from './containers.module.scss';
import classnames from 'classnames';

export const MainContainer = props => {
  const classNames = classnames(styles.mainContainer, props.className);
  return <div className={classNames}>{props.children}</div>;
};

export const DesktopContainer = props => {
  const classNames = classnames(styles.desktopContainer, {
    [props.className]: props.className,
    [styles.full]: props.full,
  });
  return <div className={classNames}>{props.children}</div>;
};
