import {
  SETTINGS
} from '../../config';
import {
  API
} from 'aws-amplify';
import store from '../../store';
import {
  storage
} from '../../utils';
import * as Message from '../../components/message';

export const getExchangeRates = props => {
  return new Promise((resolve, reject) => {
    let path = `/currency`;
    API.get(SETTINGS.dynamodb.apiName, path, null)
      .then(response => {
        if (response.error) {
          store.dispatch(
            Message.add({
              type: 'error',
              text: response.error.info || response.error.message || 'Exchange rate API error',
            }),
          );
        } else {
          resolve(response);
        }
      })
      .catch(error => {
        store.dispatch(
          Message.add({
            type: 'error',
            text: error.toString(),
          }),
        );
        reject(error);
      });
  });
};

export const getCurrency = () => {
  const defaultCurrency = {
    code: 'AUD',
    name: 'Australian Dollar',
    symbol: 'A$',
    default: true,
  };
  return new Promise((resolve, reject) => {
    let currency = storage.getItem('currency');
    if (currency) {
      currency = JSON.parse(currency);
    }
    if (currency && !currency.default) {
      resolve(currency);
    } else {
      fetch(SETTINGS.ipgeolocation)
        .then(resp => resp.json())
        .then(data => {
          if (data.currency) {
            storage.setItem('currency', JSON.stringify(data.currency));
            resolve(data.currency);
          } else {
            resolve(null);
          }
        })
        .catch(error => {
          // want this to fail silently and default to AUD in case ad
          // blockers block ip location service
          storage.setItem('currency', JSON.stringify(defaultCurrency));
          resolve(defaultCurrency);
          //reject(error);
        });
    }
  });
};