import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker';
import styles from './datepicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, endOfHour, startOfHour, isBefore, format, addSeconds } from 'date-fns';
import posed from 'react-pose';
import Button from '../button';
import { normalizeDate, validDate, onModalOpen, onModalClose } from '../../utils';
import classnames from 'classnames';

const Overlay = posed.div({
  pickerOpen: {
    applyAtStart: { pointerEvents: 'all' },
    opacity: 1,
  },
  pickerClosed: {
    applyAtStart: { pointerEvents: 'none' },
    opacity: 0,
  },
});

const Picker = posed.div({
  pickerOpen: {
    y: '0%',
  },
  pickerClosed: {
    y: '50%',
  },
});

class DatePickerModal extends Component {
  constructor(props) {
    super(props);
    this.picker = React.createRef();
    this.now = new Date();
    this.futureDays = 5;
    this.state = {
      open: props.open,
    };
  }

  componentDidMount = () => {
    let selected = normalizeDate(this.now);
    if (this.props.selected && isBefore(this.props.selected, this.now)) {
      const date = format(this.now, 'yyyy-MM-dd');
      const time = format(this.props.selected, "'T'HH:mm:ss");
      selected = `${date}${time}`;
    } else if (this.props.selected) {
      selected = this.props.selected;
    }
    const valid = validDate(new Date(selected));
    if (valid) {
      this.setState({ selected: addSeconds(endOfHour(selected), 1) });
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open });
      this.props.open && this.onOpen();
    }
    if (this.props.selected !== prevProps.selected) {
      this.setState({ selected: startOfHour(this.props.selected) });
    }
  };

  render = () => {
    const classNames = classnames(styles.overlay, {
      [styles.timeOnly]: this.props.timeOnly,
    });
    return ReactDOM.createPortal(
      <Overlay
        onClick={this.onClose}
        pose={this.state.open ? 'pickerOpen' : 'pickerClosed'}
        className={classNames}
        ref={this.picker}
      >
        <Picker className={styles.picker}>
          <DatePicker
            inline
            selected={this.state.selected}
            onChange={this.onChange}
            minDate={this.now}
            maxDate={addDays(this.now, this.futureDays)}
            showTimeSelect
            showTimeSelectOnly={this.props.timeOnly}
            timeFormat="HH:mm"
            timeIntervals={this.props.timeIntervals || 60}
            // dateFormat="MMMM d, yyyy h:mm aa"
            timeCaption="Time"
          />
          <div className={styles.actions}>
            <Button primary onClick={this.onSelect}>
              Select
            </Button>
          </div>
        </Picker>
      </Overlay>,
      document.body,
    );
  };

  onChange = date => {
    this.setState({
      selected: date,
    });
  };

  onSelect = () => {
    this.setState({
      open: false,
    });
    setTimeout(() => {
      if (typeof this.props.onChange === 'function') {
        this.props.onChange(this.state.selected);
      }
    }, 500);
    onModalClose();
  };

  onOpen = () => {
    setTimeout(() => {
      if (!this.picker.current) {
        return false;
      }
      const timeContainer = this.picker.current.querySelector('.react-datepicker__time-box');
      const selectedTime = timeContainer.querySelector('.react-datepicker__time-list-item--selected');
      if (timeContainer && selectedTime) {
        const scrollTop = selectedTime.offsetTop - timeContainer.clientHeight / 2 + selectedTime.clientHeight / 2;
        timeContainer.scrollTop = scrollTop;
      }
      this.props.onOpen && this.props.onOpen();
    });
    onModalOpen();
  };

  onClose = e => {
    if (typeof this.props.onClose === 'function' && e.target.classList.contains(styles.overlay)) {
      this.props.onClose();
      onModalClose();
    }
  };
}

export default DatePickerModal;
