import { getUnixTime } from 'date-fns';

const getStravaToken = () => {
  let token = sessionStorage.getItem('strava_token') || localStorage.getItem('strava_token') || null;
  if (token) {
    try {
      token = JSON.parse(token);
      const validTime = token.expires_at - getUnixTime(new Date());
      if (validTime <= 0) {
        token.expired = true;
      }
      return token;
    } catch {
      return null;
    }
  } else {
    return null;
  }
};

export default getStravaToken;
