import React, { Component } from 'react';
import styles from './gradient-image.module.scss';
import posed from 'react-pose';

const Img = posed.div({
  visible: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
});

class GradientImage extends Component {

  constructor(props) {
    super(props);

    this.height = props.height ? props.height : 'null';
    this.className = props.className ? props.className : '';
    this.greyscale = props.greyscale ? styles.greyscale : '';

    this.state = {
      ready: false
    }

    this.img = new Image();
    this.img.onload = () => {
      this.setState({ ready: true });
    }
    this.img.src = props.src;
  }

  componentWillUnmount() {
    this.img.onload = null;
  }

  render() {
    return (
      <div className={`${styles.gradient} ${this.className}`} style={{ color: this.props.color, height: this.height }}>
        <Img
          className={`${styles.gradientImage} ${this.greyscale}`}
          style={{ backgroundImage: `url(${this.props.src})` }}
          pose={this.state.ready ? 'visible' : 'hidden'}
        >
          {this.props.children &&
            <div className={styles.content}>
              {this.props.children}
            </div>
          }
        </Img>
      </div>
    );
  }

}

export default GradientImage;