export const SETTINGS = {
  adminStravaID: [19454260],
  stripe: {
    apikey: {
      live: 'pk_live_jyGuaBhIbE8ZrQK5dy9Jx4jB00A2r9Av2q',
      test: 'pk_test_Pf9hreIAluyblvnmnA7MkUDu006xY2OVxs',
    },
    // If you update these make sure you update the api options in headwind payments as well
    donate: {
      currency: 'aud',
      amount: 500,
    },
  },
  mapbox: {
    apikey: 'pk.eyJ1IjoiaGVhZHdpbmRhcHAiLCJhIjoiY2praWFlcWdkMDE5czNya2p0YWE5bzZ6aSJ9.q7cs1FIhhDtVYK_LyGefqw',
    style: 'headwindapp/cjkidvmd53o0w2spilirxuk7i',
  },
  dynamodb: {
    apiName: 'headwindapp',
  },
  strava: {
    endpoint: 'https://www.strava.com/api/v3',
    clientSecret: 'a9ddbd4b9fa9abdb253bcd589d080a39182de342',
    clientId: '17224',
    redirectUri: `${window.location.protocol}//${window.location.host}`,
    scope: 'read_all,profile:read_all,activity:read_all',
    get tokenEndpoint() {
      return `https://www.strava.com/oauth/token?client_id=${this.clientId}&client_secret=${this.clientSecret}`;
    },
    get authEndpoint() {
      return `https://www.strava.com/oauth/authorize?client_id=${this.clientId}&redirect_uri=${
        this.redirectUri
      }&scope=${this.scope}&response_type=code`;
    },
    get deauthEndpoint() {
      return `https://www.strava.com/oauth/deauthorize`;
    },
  },
  ga: {
    live: 'UA-141153181-1',
    dev: 'UA-141153181-2',
    local: 'UA-141153181-3',
  },
  sentry: 'https://785da116ccd242b8a5cd60108fecba4f@sentry.io/1260534',
  ipgeolocation: 'https://api.ipgeolocation.io/ipgeo?apiKey=f221ddd5b31c41fb9471c8a6f6b8505e',
  hardness: {
    maxWindSpeed: 30, // the max hardness. 0 is easiest. this is hardest so will equal the outof value.
    outof: 5,
    getsHardAt: 3, // used for alert color on ride hardness UI display only
    windGetsHardAt: 30, // used for wind color
    windGetsEasyAt: 10, // used for wind color
  },
  heatmap: {
    pitch: 0,
    bearing: 0,
    zoom: 10,
  },
  rainRadar: {
    pitch: 0,
    bearing: 0,
    zoom: 10,
  },
  maxAverageSpeed: 60,
  defaultAverageSpeed: 20,
  pageTransitionSpeed: 300,
  defaultUserSettings: {
    unitPrefs: {
      distance: 'kilometers',
      rainfall: 'millimeters',
      temperature: 'celsius',
      speed: 'KPH'
    },
    commute: {
      morning: '07:00',
      afternoon: '17:00',
    },
    notifications: false,
    email: null,
  },
  calorieCalculation: {
    averageFemaleWeight: 70,
    averageMaleWeight: 85,
    averageBikeWeight: 9,
    averageSpeed: 19,
    realityAdjustment: -50,
  },
  ios: {
    visitsBeforePrompt: 3,
    maxPromptTimes: 3,
  },
  messages: {
    timeBeforeDismiss: 3000,
  },
  modalSpeed: 200,
  survey: {
    // title: 'Become part of the team. Help pick Headwinds next feature.',
    // src: null,
    // showAfter: 5000,
    // afterVisits: 2,
    // prev: null,
    // results: null,
    // implemented: null,
    title: 'Get weekly notifiactions about the hardness of your rides',
    src: 'notifications',
    showAfter: 3000,
    afterVisits: 2,
    prev: 'segments',
    results: [{
        key: 'Segment Breakdown',
        value: 30.8,
      },
      {
        key: 'Rain Radar',
        value: 41.4,
      },
      {
        key: 'Hourly Forecast',
        value: 27.8,
      },
    ],
    implemented: {
      description: `
        Weekly notifications are now available for your starred rides.
        Simply enter your email on the settings page to recieve a personalised
        update on how hard the week ahead will be and which days are the best to ride.
      `,
    },
    donateButton: false,
    actionButton: {
      text: 'Go to settings',
      link: '/settings',
    },
  },
};