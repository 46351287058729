import React, { Component } from 'react';
import TourDeFrance from '../../api/tour-de-france';
import RideList from '../../components/ride-list';
import { LoadingView, InfoView } from '..';
import { updateRides } from '../../api/strava/actions';
import store from '../../store';
import { setLoading } from '../../store/actions';
import { setTitle } from '../../components/header/actions';

export class TourDeFranceView extends Component {
  constructor(props) {
    super(props);
    const state = store.getState();
    this.state = {
      rides: state.strava.rides,
      hasRides: true,
    };
    this.mounted = null;
  }

  componentDidMount() {
    this.mounted = true;
    store.dispatch(setTitle('Tour de France'));
    // get the rides
    this.api = new TourDeFrance();
    this.api.getRides().then(rides => {
      this.gotRides(rides);
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  gotRides = rides => {
    if (!this.mounted) {
      return false;
    }
    this.setState({ rides, hasRides: rides.length });
    store.dispatch(updateRides(rides));
    setLoading(false);
  };

  render() {
    if (!this.state.hasRides) {
      return (
        <InfoView tabs weatherSummary title="No Stages Found">
          No stages found. Please check back later.
        </InfoView>
      );
    } else if (this.state.rides.length > 0) {
      return <RideList rides={this.state.rides} type="stage" />;
    } else {
      return <LoadingView message="Loading Stages..." tabs weatherSummary />;
    }
  }
}
