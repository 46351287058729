import { useRef, useEffect } from 'react';
import * as polyline from 'google-polyline';
import store from '../store';
import { setInstallPrompt } from '../store/actions';
import * as Message from '../components/message';
import { SETTINGS } from '../config';
import * as DS from '../api/darksky';
import Color from 'color';
import colors from '../style-utils/colors.module.scss';
import { trackEvent } from '../utils/ga';
import { distance } from '@turf/turf';
import { demoToken } from '../api/demo';
import * as shared from './shared';
import variables from '../style-utils/variables.module.scss';
import MobileDetect from 'mobile-detect';
import simplify from '@turf/simplify';
import { lineString } from '@turf/helpers';
import rgb2Hex from 'rgb2hex';
const md = new MobileDetect(window.navigator.userAgent);
export * from './shared';

export const installApp = prompt => {
  prompt.prompt();
  trackEvent('Install App Button', 'Clicked install button');
  // wait for the user to respond to the prompt
  prompt.userChoice.then(choiceResult => {
    if (choiceResult.outcome === 'accepted') {
      store.dispatch(
        Message.add({
          type: 'success',
          text: 'Headwind installed successfully',
        }),
      );
      trackEvent('Install App Button', 'Success');
    } else {
      store.dispatch(
        Message.add({
          type: 'warning',
          text: 'Headwind install cancelled',
        }),
      );
      trackEvent('Install App Button', 'Cancelled');
    }
    store.dispatch(setInstallPrompt(false));
  });
};

export const getWindHardnessColor = windSpeed => {
  const easy = Color(colors.tertiaryLight);
  const hard = Color(colors.primary);
  const range = SETTINGS.hardness.windGetsHardAt - SETTINGS.hardness.windGetsEasyAt;
  const speed =
    Math.min(Math.max(SETTINGS.hardness.windGetsEasyAt, windSpeed), SETTINGS.hardness.windGetsHardAt) -
    SETTINGS.hardness.windGetsEasyAt;
  let hardness = (Math.min(100, (speed / range) * 100) / 100).toPrecision(2);
  const color = easy.mix(hard, hardness);
  return color;
};

export const parseDarkSkyTimestamp = stamp => {
  return stamp * 1000;
};

export const isInstalled = () => {
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone ||
    window.location.search.includes('installed=1')
  );
};

export const isBase64Encoded = string => {
  let success = true;
  try {
    atob(string);
  } catch (e) {
    success = false;
  }
  return success;
};

export const hasUserSettings = () => {
  const state = store.getState();
  if (
    state.strava.user &&
    state.strava.user.headwind &&
    state.strava.user.headwind.unitPrefs &&
    state.strava.user.headwind.commute
  ) {
    return true;
  }
  return false;
};

export const getUserSettings = () => {
  const state = store.getState();
  if (state.strava.user && state.strava.user.headwind) {
    return state.strava.user.headwind;
  } else {
    return SETTINGS.defaultUserSettings;
  }
};

export const userPrefUnit = (type, settings) => {
  if (!settings) {
    settings = getUserSettings().unitPrefs;
  }
  return shared.userPrefUnit(type, settings);
};

export const convertToUserPref = (number, type, settings) => {
  if (!settings) {
    settings = getUserSettings().unitPrefs;
  }
  return shared.convertToUserPref(number, type, settings);
};

export const getRideDistance = (distance, settings) => {
  if (!settings) {
    settings = getUserSettings().unitPrefs;
  }
  const dist = distance || 0;
  return convertToUserPref(shared.m2km(dist), 'distance', settings).toFixed(1);
};

export const kj2cal = kj => {
  return Math.round(kj / 4.184);
};

export const mps2kph = mps => {
  return (mps * 18) / 5;
};

export const kph2mps = kph => {
  return kph / 3.6;
};

export const calculateCalories = (sex, speed, wind, hours) => {
  const riderWeight =
    sex === 'M' ? SETTINGS.calorieCalculation.averageMaleWeight : SETTINGS.calorieCalculation.averageFemaleWeight;
  const weight =
    riderWeight + SETTINGS.calorieCalculation.averageBikeWeight - SETTINGS.calorieCalculation.realityAdjustment;
  return ((9.8 * weight * speed * (0.0053 + 1) + 0.185 * Math.pow(wind, 2) * speed) * 1.16 * hours) / 100;
};

export const calculateWindCalories = (cal, hardness = 2.5) => {
  if (isNaN(hardness)) {
    hardness = 2.5;
  }
  let percentage = (hardness * 100) / 5 / 400;
  const modifier = percentage * cal;
  return Math.round(cal + modifier);
};

export const storage = {
  setItem: (key, value, remember = false) => {
    if (localStorage.getItem('strava_token') || remember) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  },
  getItem: (key, value, remember = false) => {
    if (localStorage.getItem('strava_token') || remember) {
      return localStorage.getItem(key, value);
    } else {
      return sessionStorage.getItem(key, value);
    }
  },
  removeItem: (key, remember = false) => {
    if (localStorage.getItem('strava_token') || remember) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  },
  getAll: (remember = false) => {
    if (localStorage.getItem('strava_token') || remember) {
      return localStorage;
    } else {
      return sessionStorage;
    }
  },
  clear: (remember = false) => {
    if (localStorage.getItem('strava_token') || remember) {
      return localStorage.clear();
    } else {
      return sessionStorage.clear();
    }
  },
  clearAll: (exclude = [], type) => {
    const clear = (s, exclude) => {
      Object.keys(s).map(key => !exclude.includes(key) && s.removeItem(key));
    };
    if (type) {
      clear(type, exclude);
    } else {
      clear(localStorage, exclude);
      clear(sessionStorage, exclude);
    }
  },
};

export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position.coords);
        },
        () => {
          const error = 'Unable to fetch current position, geolocation permission denied';
          store.dispatch(
            Message.add({
              type: 'error',
              text: error,
            }),
          );
          reject(error);
        },
      );
    } else {
      const error = 'Sorry, Geolocation is not availabale on this device';
      store.dispatch(
        Message.add({
          type: 'error',
          text: error,
        }),
      );
      reject(error);
    }
  });
};

export const getCurrentWeather = (hourly = false) => {
  return new Promise((resolve, reject) => {
    getCurrentPosition()
      .then(position => {
        DS.getWeather({
          lat: position.latitude,
          lng: position.longitude,
          hourly,
        }).then(weather => {
          resolve(weather);
        });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getPolylineGeoJson = (line, color, key = 0) => {
  return {
    id: `line-${key}`,
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {
          stroke: rgb2Hex(color).hex,
        },
        geometry: {
          type: 'LineString',
          coordinates: line,
        },
      },
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color,
      'line-width': 3,
    },
  };
};

export const canShareFile = file => {
  if (!file) {
    file = new File([], 'test.png');
  }
  if (
    navigator.canShare &&
    navigator.canShare({
      files: [file],
    })
  ) {
    return true;
  } else {
    return false;
  }
};

export const getRideID = ride => {
  return ride.id_str ? String(ride.id_str) : String(ride.id);
};

export const getAppVersion = () => {
  return new Promise(resolve => {
    fetch(`${process.env.PUBLIC_URL}/version.json`)
      .then(resp => resp.json())
      .then(data => {
        resolve(data.version || null);
      });
  });
};

export const getEnv = () => {
  const host = window.location.host;
  if (host.includes('localhost')) {
    return 'dev';
  } else if (host.includes('development') || host.includes('staging')) {
    return 'staging';
  } else {
    return 'prod';
  }
};

export const getFirstSymbolLayer = map => {
  const layers = map.getStyle().layers;
  // Find the index of the first symbol layer in the map style
  // useful for adding layers below map labels
  let id = null;
  for (var i = 0; i < layers.length; i++) {
    if (layers[i].type === 'symbol') {
      id = layers[i].id;
      break;
    }
  }
  return id;
};

export const isAdmin = user => {
  let curUser;
  if (user) {
    curUser = user;
  } else {
    const state = store.getState();
    curUser = state.strava.user;
  }
  if (curUser) {
    return SETTINGS.adminStravaID.includes(curUser.id);
  } else {
    return false;
  }
};

export const hasDonated = user => {
  let curUser;
  if (user) {
    curUser = user;
  } else {
    const state = store.getState();
    curUser = state.strava.user;
  }
  if (curUser) {
    return curUser.headwind && curUser.headwind.donated ? true : false;
  } else {
    return false;
  }
};

export const isDemo = () => {
  const state = store.getState();
  if (state.strava.token) {
    return state.strava.token.access_token === demoToken.access_token;
  }
  return false;
};

export const isEmbedded = () => {
  if (window.location.pathname.includes('/embed/')) {
    return true;
  }
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getSegmentPolylineFromRide = (ride, segment) => {
  const line = polyline.decode(ride.map.polyline || ride.map.summary_polyline);
  let startIndex, endIndex;
  let lastStartDist = Infinity;
  let lastEndDist = Infinity;

  line.forEach((step, i) => {
    const startDist = distance(step, segment.start_latlng);
    const endDist = distance(step, segment.end_latlng);
    if (startDist < lastStartDist) {
      startIndex = i;
      lastStartDist = startDist;
    }
    if (endDist < lastEndDist) {
      endIndex = i;
      lastEndDist = endDist;
    }
  });
  let segmentLine = [];
  if (startIndex !== undefined && endIndex !== undefined) {
    segmentLine = [...line].slice(startIndex, endIndex);
  }
  if (!segmentLine.length) {
    segmentLine = [segment.start_latlng, segment.end_latlng];
  }
  return polyline.encode(segmentLine);
};

export const getSegmentsFromRideOrRoute = ride => {
  let segments = [];
  if (ride.segment_efforts) {
    segments = ride.segment_efforts.map(effort => effort.segment);
  } else if (ride.segments) {
    segments = ride.segments;
  }
  return segments;
};

export const validDate = d => {
  return d instanceof Date && !isNaN(d);
};

export const onModalOpen = (disablePointerEvents = true) => {
  document.body.style.top = `-${window.scrollY}px`;
  if (!md.phone() && !md.tablet()) {
    document.body.style.paddingRight = variables.scrollbarWidth;
  }
  document.body.classList.add('modalOpen');
  if (disablePointerEvents) {
    document.body.classList.add('modalOpenNoPointer');
  }
};

export const onModalClose = () => {
  const scrollY = document.body.style.top;
  document.body.style.top = null;
  document.body.style.paddingRight = null;
  document.body.classList.remove('modalOpen');
  document.body.classList.remove('modalOpenNoPointer');
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

export const simplifyPolyline = (line, tolerance) => {
  const simplified = simplify(lineString(line), {
    tolerance,
    highQuality: true,
  });
  if (simplified.geometry) {
    return simplified.geometry.coordinates;
  } else {
    return line;
  }
};

export const getUserEmail = user => {
  if (!user) {
    user = store.getState().strava.user;
  }
  if (user) {
    const email = user.headwind && user.headwind.email ? user.headwind.email : null;
    return email;
  }
  return null;
};

export const getUserFullName = user => {
  if (!user) {
    user = store.getState().strava.user;
  }
  if (user) {
    const name = `${user.firstname || ''} ${user.lastname || ''}`;
    return name;
  }
  return null;
};

export const secondsToNiceTime = (seconds, includeSeconds = false) => {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  const hours = seconds / 3600;
  const minutes = (seconds % 3600) / 60;
  if (includeSeconds) {
    return [hours, minutes, seconds % 60].map(format).join(':');
  } else {
    return [hours, minutes].map(format).join(':');
  }
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const stateChanged = (oldState, newState) => {
  return JSON.stringify(oldState) !== JSON.stringify(newState);
};
