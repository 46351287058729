import * as query from 'query-string';
import store from '../../store';
import { API } from 'aws-amplify';
import { SETTINGS } from '../../config';
import * as Message from '../../components/message';
import { updateToken, requestingToken, updateUser, updateRides, updateRoutes } from './actions';
import getStravaToken from './getStravaToken';
import getCachedUser from './getCachedUser';
import { queryDemoApi, demoToken } from '../demo';
import { addDays, isAfter } from 'date-fns';
import { isBase64Encoded, storage } from '../../utils';

export const initAuth = () => {
  storage.removeItem('strava_token');
  window.location.href = SETTINGS.strava.authEndpoint;
};

export const checkForAuthToken = () => {
  return new Promise((resolve, reject) => {
    const params = query.parse(window.location.search);
    if (params.code && params.scope) {
      store.dispatch(updateToken(null));
      storage.clearAll(['remember_me', 'version']);
      const requestedScope = SETTINGS.strava.scope.split(',');
      const grantedScope = params.scope.split(',').filter(s => s !== 'read');
      const match = requestedScope.filter(s => grantedScope.includes(s));
      if (match.length === requestedScope.length) {
        saveAuth(params.code).then(() => {
          resolve();
        });
      } else {
        const error = `
          Please allow all requested permissions to use this app.
          By default strava sets all your rides and routes to private. We require
          these permissions so we can fetch your ride and route details to calculate
          the hardness of your rides and show you in depth heatmaps.
          Don't worry we will never share these details with anyone else.
        `;
        store.dispatch(
          Message.add({
            type: 'error',
            text: error,
            showFor: 15000,
          }),
        );
        reject(error);
      }
    } else if (params.error && params.error === 'access_denied') {
      const error = 'Sorry there was an error authorizing Strava.';
      store.dispatch(
        Message.add({
          type: 'error',
          text: error,
        }),
      );
      reject(error);
    }
    if (params.code || params.scope) {
      window.history.pushState({}, document.title, window.location.pathname);
    }
  });
};

export const saveAuth = code => {
  return new Promise((resolve, reject) => {
    store.dispatch(requestingToken(true));
    const endpoint = `${SETTINGS.strava.tokenEndpoint}&code=${code}`;
    fetch(endpoint, {
      method: 'post',
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.access_token && data.refresh_token) {
          const rememberMe = sessionStorage.getItem('remember_me') === 'true';
          sessionStorage.removeItem('remember_me');
          getUser(data.access_token, rememberMe).then(() => {
            const token = {
              access_token: data.access_token,
              refresh_token: data.refresh_token,
              expires_at: data.expires_at,
            };
            const tokenString = JSON.stringify(token);
            storage.setItem('strava_token', tokenString, rememberMe);
            store.dispatch(
              Message.add({
                type: 'success',
                text: 'Successfully connected to Strava.',
                id: 'login',
              }),
            );
            store.dispatch(updateToken(token));
            resolve();
          });
        } else {
          const error = 'Error retrieving access token from Strava.';
          store.dispatch(requestingToken(false));
          store.dispatch(
            Message.add({
              type: 'error',
              text: error,
            }),
          );
          reject(error);
        }
      })
      .catch(error => {
        store.dispatch(requestingToken(false));
        store.dispatch(
          Message.add({
            type: 'error',
            text: error,
          }),
        );
        reject(error);
      });
  });
};

export const refreshAuth = () => {
  let token = getRefreshToken();
  return new Promise((resolve, reject) => {
    const endpoint = `${SETTINGS.strava.tokenEndpoint}&grant_type=refresh_token&refresh_token=${token}`;
    fetch(endpoint, {
      method: 'post',
    })
      .then(resp => resp.json())
      .then(data => {
        token = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expires_at: data.expires_at,
        };
        const tokenString = JSON.stringify(token);
        storage.setItem('strava_token', tokenString);
        resolve(token);
      })
      .catch(error => {
        store.dispatch(
          Message.add({
            type: 'error',
            text: error,
          }),
        );
        reject(error);
      });
  });
};

export const getAuthToken = () => {
  const token = store.getState().strava.token;
  if (token && token.expired) {
    return 'expired';
  }
  return token && token.access_token ? token.access_token : null;
};

export const getRefreshToken = () => {
  const token = getStravaToken();
  return token && token.refresh_token ? token.refresh_token : null;
};

// Use de auth when implement delete account functionality
export const deAuth = () => {
  const token = getStravaToken();
  if (!token) {
    return false;
  }
  const endpoint = `${SETTINGS.strava.deauthEndpoint}?access_token=${token.access_token}`;
  fetch(endpoint, {
    method: 'post',
  })
    .then(resp => resp.json())
    .then(data => {
      logout();
    })
    .catch(error => {
      store.dispatch(
        Message.add({
          type: 'error',
          text: 'Error deauthorizing token.',
        }),
      );
    });
};

export const logout = (showMessage = true) => {
  store.dispatch(updateToken(null));
  storage.clearAll(['version']);
  if (showMessage) {
    store.dispatch(
      Message.add({
        type: 'success',
        text: 'Successfully logged out.',
        id: 'logout',
      }),
    );
  }
};

export const queryApi = (endpoint, cacheFor, token = false) => {
  const key = token ? token : getAuthToken();
  if (key === demoToken.access_token) {
    return queryDemoApi(endpoint);
  } else if (key === 'expired') {
    return new Promise(resolve => {
      refreshAuth().then(newToken => {
        resolve(queryApi(endpoint, cacheFor, newToken.access_token));
      });
    });
  } else {
    return new Promise((resolve, reject) => {
      const shared = store.getState().global.shared;
      if (!key && !shared) {
        const error = 'No strava access key found.';
        initAuth();
        reject(error);
        store.dispatch(
          Message.add({
            type: 'error',
            text: error,
          }),
        );
      } else {
        const path = shared ? endpoint : `${endpoint}/${key}`;
        API.get(SETTINGS.dynamodb.apiName, path, null)
          .then(response => {
            if (response.error) {
              store.dispatch(
                Message.add({
                  type: 'error',
                  text: response.error.message || response.error,
                }),
              );
              reject(`${response.error} - ${path}`);
            } else {
              // for testing of adding new items
              // if (Array.isArray(response) && endpoint.includes('/rides')) {
              //   if (!window.moreRides) {
              //     window.moreRides = [];
              //   }
              //   const id = `test-item-${window.moreRides.length}`;
              //   const n = { ...response[0], name: id, id: id };
              //   window.moreRides.push(n);
              //   response = [...window.moreRides.reverse(), ...response];
              // }
              if (cacheFor) {
                if (cacheFor !== Infinity) {
                  storage.setItem(btoa(`${endpoint}/expires`), addDays(new Date(), cacheFor));
                }
                storage.setItem(btoa(endpoint), JSON.stringify(response));
              }
              // NOTE: Temp fix for bug with rides not updating
              if (endpoint.includes('/strava/get/rides')) {
                store.dispatch(updateRides(response));
              } else if (endpoint.includes('/strava/get/routes')) {
                store.dispatch(updateRoutes(response));
              }
              resolve(response);
            }
          })
          .catch(error => {
            store.dispatch(
              Message.add({
                type: 'error',
                text: error.message,
              }),
            );
            reject(`${error.message} - ${path}`);
          });
      }
    });
  }
};

export const queryCachedApi = endpoint => {
  return new Promise((resolve, reject) => {
    const key = btoa(endpoint);
    const cached = storage.getItem(key);
    if (cached) {
      resolve(JSON.parse(cached));
    }
  });
};

export const clearExpiredCacheItems = () => {
  const allStorage = {
    ...storage.getAll(),
  };
  Object.keys(allStorage).forEach(key => {
    if (isBase64Encoded(key)) {
      if (atob(key).includes('/expires')) {
        const now = new Date();
        const then = new Date(allStorage[key]);
        if (isAfter(now, then)) {
          storage.removeItem(key);
          const itemKey = btoa(atob(key).replace('/expires', ''));
          storage.removeItem(itemKey);
        }
      }
    }
  });
};

export const getUser = (token, rememberMe = false) => {
  return new Promise((resolve, reject) => {
    queryApi('/strava/get/user', null, token)
      .then(user => {
        const userString = JSON.stringify(user);
        storage.setItem('strava_user', userString, rememberMe);
        store.dispatch(updateUser(user));
        resolve(user);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getUserStats = () => {
  const user = getCachedUser();
  if (user) {
    return queryApi(`/strava/get/user/stats/${user.id}`);
  } else {
    return null;
  }
};

export const getRoutes = () => {
  const user = getCachedUser();
  if (user) {
    return {
      cached: queryCachedApi(`/strava/get/routes/${user.id}`),
      latest: queryApi(`/strava/get/routes/${user.id}`, Infinity),
    };
  } else {
    return null;
  }
};

export const getRides = () => {
  return {
    cached: queryCachedApi('/strava/get/rides'),
    latest: queryApi('/strava/get/rides', Infinity),
  };
};

export const getRide = id => {
  return {
    cached: queryCachedApi(`/strava/get/ride/${id}`),
    latest: queryApi(`/strava/get/ride/${id}`, 14),
  };
};

export const getRoute = id => {
  return {
    cached: queryCachedApi(`/strava/get/route/${id}`),
    latest: queryApi(`/strava/get/route/${id}`, 14),
  };
};

export const getSegment = id => {
  return {
    cached: queryCachedApi(`/strava/get/segment/${id}`),
    latest: queryApi(`/strava/get/segment/${id}`, 14),
  };
};

export const getShared = (type, uid, id) => {
  if (id && type && uid) {
    return {
      cached: queryCachedApi(`/strava/share/${type}/${uid}/${id}`),
      latest: queryApi(`/strava/share/${type}/${uid}/${id}`, 14),
    };
  } else {
    return null;
  }
};

export const getStarredRides = () => {
  const user = getCachedUser();
  if (user) {
    return {
      cached: queryCachedApi(`/strava/get/starred-rides/${user.id}`),
      latest: queryApi(`/strava/get/starred-rides/${user.id}`, Infinity),
    };
  } else {
    return null;
  }
};

export const getStarredRideIds = () => {
  const user = getCachedUser();
  if (user) {
    return queryApi(`/strava/get/starred-ride-ids/${user.id}`);
  } else {
    return new Promise((resolve, reject) => {
      reject(null);
    });
  }
};

export const starRide = (rid, uid) => {
  return queryApi(`/strava/star/ride/${rid}/${uid}`);
};

export const unStarRide = (rid, uid) => {
  return queryApi(`/strava/unstar/ride/${rid}/${uid}`);
};

export const donate = (email = false) => {
  return new Promise((resolve, reject) => {
    let endpoint = '/strava/donate';
    if (email) {
      endpoint = `${endpoint}/${email}`;
    }
    queryApi(endpoint)
      .then(user => {
        storage.setItem('strava_user', JSON.stringify(user));
        store.dispatch(updateUser(user));
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const updateUserSettings = settings => {
  return new Promise((resolve, reject) => {
    queryApi(`/strava/update/user-settings/${JSON.stringify(settings)}`)
      .then(user => {
        storage.setItem('strava_user', JSON.stringify(user));
        store.dispatch(updateUser(user));
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
};
