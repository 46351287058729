export const ride = {
  "resource_state": 3,
  "athlete": {
    "id": 19454260,
    "resource_state": 1
  },
  "name": "Afternoon Ride",
  "distance": 21875.2,
  "moving_time": 3229,
  "elapsed_time": 3392,
  "total_elevation_gain": 238.3,
  "type": "Ride",
  "workout_type": null,
  "id": 2384621287,
  "external_id": "d2626ffeeed9e9943c5c1aad489b52b7",
  "upload_id": 2535943214,
  "start_date": "2019-05-21T08:24:06Z",
  "start_date_local": "2019-05-21T16:24:06Z",
  "timezone": "(GMT+08:00) Australia/Perth",
  "utc_offset": 28800,
  "start_latlng": [
    -31.954505,
    115.857057
  ],
  "end_latlng": [
    -32.071363,
    115.7874
  ],
  "location_city": null,
  "location_state": null,
  "location_country": "",
  "start_latitude": -31.954505,
  "start_longitude": 115.857057,
  "achievement_count": 5,
  "kudos_count": 1,
  "comment_count": 0,
  "athlete_count": 1,
  "photo_count": 0,
  "map": {
    "id": "a2384621287",
    "polyline": "tb`bEqicbUCKy@DaAIFNh@`@PHv@h@JBJID??DBCMCd@RVPbAf@j@jABd@N?^a@XWJ?DD?DtBGnAb@`@Hx@VNEPq@LI~@b@TFL@h@TvAf@NJ@NWdAK\\Ox@BzCHnBD^Bx@TzAb@xAR`ALXHVKRgB|ACZB^Rz@\\fAn@~@x@f@HZTVj@ZVF\\@^BXCpA^LHXRx@`Ad@z@ZXRLbAF|APPFrAHjCV`CZV?fCZv@Cf@SJ_@AYDSNMD_@RK^GtBi@dBo@|Aa@t@Yz@QpBs@~Aa@bAa@`Bk@t@_@XIdAUT@JDZC~@e@hAUh@UXQRGL?l@UPDNRHZ@x@DPPRp@rCLx@J\\^b@XHv@GpDe@LEjCUzDm@dAIx@AtBBlAMjALZ?x@EtAMfAQpAm@fCcAhAk@lBoAfAu@fAo@`Ao@hAi@tAc@xDuBfBy@VS~CyAfBs@XQv@Yj@KNI\\K^Cn@ItA_@VELGJa@?MFEl@LL?XIrIgDZOdBm@n@ShEq@`Cg@^C^@pBCpCMtBJ`CApA@z@FhEj@zB`@l@DxATzANvARdCd@z@Fj@L`B`@pCz@tAL^?Z@`AET@LAb@YTALHO\\IBQI?KHGJJBfCETK?AMZIRBrAa@`BSbBY`AYjBq@pCs@`AKhCE~@KfAq@n@O`@D`@JlAh@h@`@p@v@hAt@j@NPE\\aAEc@?{ATcBZ}@RWf@c@pBaAj@S\\ExCu@jBi@\\G^E^A|@GpCYrDC~@Bj@C\\B\\C`DDfBLZH|@P`CFpB@lCQ^I\\Ez@_@hAWlA]rAw@jAe@d@]x@c@ZSTWv@e@VSrAq@xA_Aj@g@bBeA~@W|@UZM\\Er@A~ABpBS`@?~AOb@?\\F^BlBZ~@l@ZTd@h@XPxBl@h@Fd@NNIRYLJn@TPPd@Td@\\RJ|@\\RLpAj@`@NjB|@nBx@h@^VJl@^TRtGrCVRh@X@r@xB~AbALr@Tf@LZBx@VdANt@T~AVtBj@bBRNNDPLHFG@WCSBUZIvHbApDn@dBTJ@VOZ?pAL^LZTJLXJNBZQl@e@ZIZA|Bl@dAR~Bl@j@PfALf@LVJlAv@r@?d@C`@Fh@TTN`A^jAR|Ad@fARXL^Xb@Nb@Hn@T|@PdBTz@B|B`@R?\\FpCZtA@`BC\\BVF~ANrA`@XDdCL`@FbEPxAJXAhAPVAHB`BFj@Dn@?p@FpADd@F`AFp@JV@j@Fz@XbAt@Zd@L@\\Cf@YXK\\CvA@f@Ql@u@`@I~@Dj@Hb@@\\AfE`@pCPRJFNh@|@XZNJLFR@j@CXBLBHNFb@Mt@?\\@rACt@@fDC\\o@lAIV@NLNb@ZVXf@z@HHf@dAXz@@l@GzC?~DHvEHhBUjCDtAAf@Bd@LlAA|CIbDCxBQpAg@|@UXQ`@MZIf@GfD?jCEvEB~@C`AFnAF\\p@lCfBtERp@b@pANZ`@l@ZpAtAlDp@nBv@z@Ln@\\v@TVVRh@Pj@D|DCr@OBD@La@|EGpCGr@HvAPxAZfB?LZx@nAlC^h@RLNRrBlBf@h@Vb@z@z@DVXTHX^h@j@`B^~A\\jBHj@AFIHG`@Q\\w@^cAZa@Tc@N_@Va@HSHMBQACHB\\_Bp@AFD\\OPBLBtBC|BBjCCnEBhGCjA@|BAbCDvAA|A@bBCdB@j@?bJA`@HdJ?~@FrMBxB@fF@fAJ\\?LARMb@DbFEdHBzAKvEDz@Ct@A`@FtCCr@?~BCjC@bCChB@xCKnIGNY?q@QOIkDq@uA]cDo@_@Gm@CKDkAlAM\\FVb@r@DJCJEBCC",
    "resource_state": 3,
    "summary_polyline": "tb`bEqicbUCKy@DaAIFNh@`@hAr@JBJID??DBCMC`ClAj@jABd@N?x@y@J?DJtBGjDdANEPq@LIbBl@pChA@Ns@|CBzCHnBHxATzAb@xAR`AVp@KRgB|ACZB^Rz@\\fAn@~@x@f@HZTVj@Zt@Hx@?~Ah@XRx@`Ad@z@n@f@tTzBv@Cf@SJ_@AYDSNMD_@RKtCq@dBo@nEmApEuAtFwBdAU`@FZC~@e@hAUbAg@`@Gl@UPDNRHZ@x@Vd@p@rCXvA^b@XHv@G~Dk@jCUzDm@~BKtBBlAMfBLnCSfAQbH}C~HeFhAi@tAc@xHcE`H_DpC{@nAMlBe@LGJo@FEz@LXItMeFzJmBbIStBJ`CAlCHdIlAbFj@xG`A~GjBtALrCAp@[TALHO\\IBQI?KHGJJBfCETK?AMZIRBrAa@dEm@lDkApCs@`AKhCE~@KfAq@n@ObAPlAh@h@`@p@v@hAt@j@NPE\\aAEc@?{ATcBZ}@z@{@|CuA`ImBnGi@|N@fBLxAZ`CFpB@lCQ|@Oz@_@vCu@rAw@jAe@`FgDlDqBj@g@bBeAvDaArC@rFc@`BJlBZ~@l@zApAxBl@nAVNIRY|@`@PP~A~@`L~EdC~AtGrC`Al@@r@xB~AbALpD~@dANtCl@tBj@bBRNNDPLHFGAk@BUZInQhCJ@VOlBL^Lf@b@h@NhAw@v@KnJ`CnBZdBbAxAC`@F~@d@`A^pFlAx@f@b@NpCp@`DX|B`@bEb@tE@vBVlBf@~Mp@hAPbJ\\pD\\j@Fz@XbAt@Zd@L@\\C`Ae@tBAf@Ql@u@`@IjBN`A?xIr@RJp@lAh@f@`@HdA?LBHNFb@Mt@?\\?pHC\\y@dB@NhAdAp@dA`A`CEhE?~DHvEHhBUjCB|BPrBOzLQpAoAxBMZIf@MjO?`CNlBp@lCfBtEv@bCp@hAZpAfC|Gv@z@Ln@\\v@l@j@h@Pj@D|DCr@OBD_@jFOdEHvAl@nEjBfE^h@~DxDVb@z@z@DVXTHX^h@j@`B^~Af@vCKPY~@{Bz@eB|@u@R_@@?f@_Bp@Bd@OPFbCC|BBjCCnEBhGCjA?`GDvACxUHdJLtZ@fAJj@ARMb@DbFEdHBzAKvEDz@EvAFtCKzZ"
  },
  "trainer": false,
  "commute": false,
  "manual": false,
  "private": false,
  "visibility": "everyone",
  "flagged": false,
  "gear_id": "b3541914",
  "from_accepted_tag": false,
  "average_speed": 6.775,
  "max_speed": 10.9,
  "average_watts": 132.4,
  "kilojoules": 427.7,
  "device_watts": false,
  "has_heartrate": true,
  "average_heartrate": 154.5,
  "max_heartrate": 190,
  "heartrate_opt_out": false,
  "display_hide_heartrate_option": true,
  "elev_high": 63.3,
  "elev_low": -0.6,
  "pr_count": 3,
  "total_photo_count": 0,
  "has_kudoed": false,
  "description": null,
  "calories": 476.8,
  "segment_efforts": [{
      "id": 60198115014,
      "resource_state": 2,
      "name": "Downhill dash",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 253,
      "moving_time": 137,
      "start_date": "2019-05-21T08:25:16Z",
      "start_date_local": "2019-05-21T16:25:16Z",
      "distance": 530.5,
      "start_index": 15,
      "end_index": 67,
      "device_watts": false,
      "average_watts": 106.7,
      "average_heartrate": 73.2,
      "max_heartrate": 79,
      "segment": {
        "id": 1146565,
        "resource_state": 2,
        "name": "Downhill dash",
        "activity_type": "Ride",
        "distance": 367.34,
        "average_grade": -2.8,
        "maximum_grade": -1.4,
        "elevation_high": -0.2,
        "elevation_low": -10.6,
        "start_latlng": [
          -31.95456650108099,
          115.8562624733895
        ],
        "end_latlng": [
          -31.95807583630085,
          115.85506721399724
        ],
        "start_latitude": -31.95456650108099,
        "start_longitude": 115.8562624733895,
        "end_latitude": -31.95807583630085,
        "end_longitude": 115.85506721399724,
        "climb_category": 0,
        "city": "Perth",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115085,
      "resource_state": 2,
      "name": "Shirley Finn Memorial Segment 2019",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 355,
      "moving_time": 355,
      "start_date": "2019-05-21T08:37:28Z",
      "start_date_local": "2019-05-21T16:37:28Z",
      "distance": 2891.9,
      "start_index": 296,
      "end_index": 470,
      "device_watts": false,
      "average_watts": 132.9,
      "average_heartrate": 165,
      "max_heartrate": 176,
      "segment": {
        "id": 20191853,
        "resource_state": 2,
        "name": "Shirley Finn Memorial Segment 2019",
        "activity_type": "Ride",
        "distance": 2874.1,
        "average_grade": 0,
        "maximum_grade": 11.9,
        "elevation_high": -46.4,
        "elevation_low": -49.8,
        "start_latlng": [
          -31.978005,
          115.848908
        ],
        "end_latlng": [
          -32.002501,
          115.854168
        ],
        "start_latitude": -31.978005,
        "start_longitude": 115.848908,
        "end_latitude": -32.002501,
        "end_longitude": 115.854168,
        "climb_category": 0,
        "city": "South Perth",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": 2,
      "achievements": [{
        "type_id": 3,
        "type": "pr",
        "rank": 2
      }],
      "hidden": false
    },
    {
      "id": 60198115032,
      "resource_state": 2,
      "name": "Golf Course Roubaix (South)",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 118,
      "moving_time": 118,
      "start_date": "2019-05-21T08:38:06Z",
      "start_date_local": "2019-05-21T16:38:06Z",
      "distance": 932.4,
      "start_index": 314,
      "end_index": 374,
      "device_watts": false,
      "average_watts": 97,
      "average_heartrate": 158.8,
      "max_heartrate": 163,
      "segment": {
        "id": 6917475,
        "resource_state": 2,
        "name": "Golf Course Roubaix (South)",
        "activity_type": "Ride",
        "distance": 924.2,
        "average_grade": -0.2,
        "maximum_grade": 4.9,
        "elevation_high": 6.3,
        "elevation_low": 0.2,
        "start_latlng": [
          -31.980647,
          115.849531
        ],
        "end_latlng": [
          -31.988123,
          115.853663
        ],
        "start_latitude": -31.980647,
        "start_longitude": 115.849531,
        "end_latitude": -31.988123,
        "end_longitude": 115.853663,
        "climb_category": 0,
        "city": "South Perth",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115047,
      "resource_state": 2,
      "name": "Melville Short",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 128,
      "moving_time": 128,
      "start_date": "2019-05-21T08:40:49Z",
      "start_date_local": "2019-05-21T16:40:49Z",
      "distance": 1133.3,
      "start_index": 397,
      "end_index": 457,
      "device_watts": false,
      "average_watts": 181,
      "average_heartrate": 172.8,
      "max_heartrate": 176,
      "segment": {
        "id": 14347617,
        "resource_state": 2,
        "name": "Melville Short",
        "activity_type": "Ride",
        "distance": 1133.1,
        "average_grade": -0.1,
        "maximum_grade": 0.5,
        "elevation_high": -4.4,
        "elevation_low": -6,
        "start_latlng": [
          -31.990614,
          115.8549
        ],
        "end_latlng": [
          -32.000641,
          115.854801
        ],
        "start_latitude": -31.990614,
        "start_longitude": 115.8549,
        "end_latitude": -32.000641,
        "end_longitude": 115.854801,
        "climb_category": 0,
        "city": "Como",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": 1,
      "achievements": [{
        "type_id": 3,
        "type": "pr",
        "rank": 1
      }],
      "hidden": false
    },
    {
      "id": 60198115100,
      "resource_state": 2,
      "name": "detour stage 2",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 115,
      "moving_time": 115,
      "start_date": "2019-05-21T08:41:36Z",
      "start_date_local": "2019-05-21T16:41:36Z",
      "distance": 995,
      "start_index": 418,
      "end_index": 474,
      "device_watts": false,
      "average_watts": 125.9,
      "average_heartrate": 173.4,
      "max_heartrate": 176,
      "segment": {
        "id": 7008405,
        "resource_state": 2,
        "name": "detour stage 2",
        "activity_type": "Ride",
        "distance": 983.1,
        "average_grade": -0.2,
        "maximum_grade": 0,
        "elevation_high": 3,
        "elevation_low": 1,
        "start_latlng": [
          -31.994336,
          115.855749
        ],
        "end_latlng": [
          -32.003029,
          115.854108
        ],
        "start_latitude": -31.994336,
        "start_longitude": 115.855749,
        "end_latitude": -32.003029,
        "end_longitude": 115.854108,
        "climb_category": 0,
        "city": "Como",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115065,
      "resource_state": 2,
      "name": "MPD Reverse Short",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 41,
      "moving_time": 41,
      "start_date": "2019-05-21T08:42:23Z",
      "start_date_local": "2019-05-21T16:42:23Z",
      "distance": 348.9,
      "start_index": 441,
      "end_index": 460,
      "device_watts": false,
      "average_watts": 115,
      "average_heartrate": 171.8,
      "max_heartrate": 174,
      "segment": {
        "id": 20022019,
        "resource_state": 2,
        "name": "MPD Reverse Short",
        "activity_type": "Ride",
        "distance": 351.8,
        "average_grade": -0.3,
        "maximum_grade": 2.9,
        "elevation_high": 6.4,
        "elevation_low": 3.3,
        "start_latlng": [
          -31.997957,
          115.855411
        ],
        "end_latlng": [
          -32.001058,
          115.854702
        ],
        "start_latitude": -31.997957,
        "start_longitude": 115.855411,
        "end_latitude": -32.001058,
        "end_longitude": 115.854702,
        "climb_category": 0,
        "city": "South Perth",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": 3,
      "achievements": [{
        "type_id": 3,
        "type": "pr",
        "rank": 3
      }],
      "hidden": false
    },
    {
      "id": 60198115136,
      "resource_state": 2,
      "name": "Scout hut to Canning Hwy",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 63,
      "moving_time": 63,
      "start_date": "2019-05-21T08:44:34Z",
      "start_date_local": "2019-05-21T16:44:34Z",
      "distance": 592.4,
      "start_index": 506,
      "end_index": 538,
      "device_watts": false,
      "average_watts": 208.3,
      "average_heartrate": 172.5,
      "max_heartrate": 180,
      "segment": {
        "id": 9502196,
        "resource_state": 2,
        "name": "Scout hut to Canning Hwy",
        "activity_type": "Ride",
        "distance": 606.7,
        "average_grade": -0.3,
        "maximum_grade": 2.9,
        "elevation_high": -1.4,
        "elevation_low": -6.6,
        "start_latlng": [
          -32.004564,
          115.8537
        ],
        "end_latlng": [
          -32.009765,
          115.854661
        ],
        "start_latitude": -32.004564,
        "start_longitude": 115.8537,
        "end_latitude": -32.009765,
        "end_longitude": 115.854661,
        "climb_category": 0,
        "city": "Applecross",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115119,
      "resource_state": 2,
      "name": "Safety First",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 37,
      "moving_time": 37,
      "start_date": "2019-05-21T08:44:41Z",
      "start_date_local": "2019-05-21T16:44:41Z",
      "distance": 348.2,
      "start_index": 510,
      "end_index": 528,
      "device_watts": false,
      "average_watts": 214.6,
      "average_heartrate": 170.7,
      "max_heartrate": 176,
      "segment": {
        "id": 12140223,
        "resource_state": 2,
        "name": "Safety First",
        "activity_type": "Ride",
        "distance": 358,
        "average_grade": -0.4,
        "maximum_grade": 0.2,
        "elevation_high": -7.6,
        "elevation_low": -9.2,
        "start_latlng": [
          -32.005092,
          115.853791
        ],
        "end_latlng": [
          -32.008213,
          115.854656
        ],
        "start_latitude": -32.005092,
        "start_longitude": 115.853791,
        "end_latitude": -32.008213,
        "end_longitude": 115.854656,
        "climb_category": 0,
        "city": "Como",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115209,
      "resource_state": 2,
      "name": "Canning Bridge to Mt Henry Bridge",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 287,
      "moving_time": 287,
      "start_date": "2019-05-21T08:45:58Z",
      "start_date_local": "2019-05-21T16:45:58Z",
      "distance": 2538.9,
      "start_index": 548,
      "end_index": 690,
      "device_watts": false,
      "average_watts": 178.7,
      "average_heartrate": 182.6,
      "max_heartrate": 190,
      "segment": {
        "id": 948161,
        "resource_state": 2,
        "name": "Canning Bridge to Mt Henry Bridge",
        "activity_type": "Ride",
        "distance": 2562.85,
        "average_grade": 0.1,
        "maximum_grade": 7,
        "elevation_high": 23.7,
        "elevation_low": 18.7,
        "start_latlng": [
          -32.010915,
          115.854108
        ],
        "end_latlng": [
          -32.031386,
          115.859389
        ],
        "start_latitude": -32.010915,
        "start_longitude": 115.854108,
        "end_latitude": -32.031386,
        "end_longitude": 115.859389,
        "climb_category": 0,
        "city": "Applecross",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": 1,
      "achievements": [{
        "type_id": 3,
        "type": "pr",
        "rank": 1
      }],
      "hidden": false
    },
    {
      "id": 60198115149,
      "resource_state": 2,
      "name": "Canning Br -> horseshoe",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 86,
      "moving_time": 86,
      "start_date": "2019-05-21T08:46:04Z",
      "start_date_local": "2019-05-21T16:46:04Z",
      "distance": 751.1,
      "start_index": 551,
      "end_index": 594,
      "device_watts": false,
      "average_watts": 189.3,
      "average_heartrate": 178.4,
      "max_heartrate": 180,
      "segment": {
        "id": 3535228,
        "resource_state": 2,
        "name": "Canning Br -> horseshoe",
        "activity_type": "Ride",
        "distance": 732.055,
        "average_grade": -0.1,
        "maximum_grade": 1.5,
        "elevation_high": 2.4,
        "elevation_low": 1,
        "start_latlng": [
          -32.01093865,
          115.85470197
        ],
        "end_latlng": [
          -32.01658092,
          115.85726119
        ],
        "start_latitude": -32.01093865,
        "start_longitude": 115.85470197,
        "end_latitude": -32.01658092,
        "end_longitude": 115.85726119,
        "climb_category": 0,
        "city": "Applecross",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115449,
      "resource_state": 2,
      "name": "Canning Bridge to Murdoch Station",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 865,
      "moving_time": 865,
      "start_date": "2019-05-21T08:46:32Z",
      "start_date_local": "2019-05-21T16:46:32Z",
      "distance": 6447.5,
      "start_index": 565,
      "end_index": 1001,
      "device_watts": false,
      "average_watts": 142.5,
      "average_heartrate": 169.8,
      "max_heartrate": 190,
      "segment": {
        "id": 636445,
        "resource_state": 2,
        "name": "Canning Bridge to Murdoch Station",
        "activity_type": "Ride",
        "distance": 6442.18,
        "average_grade": 0.3,
        "maximum_grade": 6.9,
        "elevation_high": 38.7,
        "elevation_low": 13.9,
        "start_latlng": [
          -32.012174,
          115.856422
        ],
        "end_latlng": [
          -32.06637,
          115.850273
        ],
        "start_latitude": -32.012174,
        "start_longitude": 115.856422,
        "end_latitude": -32.06637,
        "end_longitude": 115.850273,
        "climb_category": 0,
        "city": "Como",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115195,
      "resource_state": 2,
      "name": "Horseshoe Henry hike",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 178,
      "moving_time": 178,
      "start_date": "2019-05-21T08:47:39Z",
      "start_date_local": "2019-05-21T16:47:39Z",
      "distance": 1607.7,
      "start_index": 599,
      "end_index": 687,
      "device_watts": false,
      "average_watts": 165.8,
      "average_heartrate": 184.7,
      "max_heartrate": 190,
      "segment": {
        "id": 3138365,
        "resource_state": 2,
        "name": "Horseshoe Henry hike",
        "activity_type": "Ride",
        "distance": 1735.17,
        "average_grade": 0,
        "maximum_grade": 4.6,
        "elevation_high": 4.8,
        "elevation_low": -3.3,
        "start_latlng": [
          -32.01744979,
          115.85721085
        ],
        "end_latlng": [
          -32.03093542,
          115.85934652
        ],
        "start_latitude": -32.01744979,
        "start_longitude": 115.85721085,
        "end_latitude": -32.03093542,
        "end_longitude": 115.85934652,
        "climb_category": 0,
        "city": "Salter Point",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115242,
      "resource_state": 2,
      "name": "Mt Henry Sprint (South)",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 132,
      "moving_time": 132,
      "start_date": "2019-05-21T08:50:12Z",
      "start_date_local": "2019-05-21T16:50:12Z",
      "distance": 951.9,
      "start_index": 675,
      "end_index": 740,
      "device_watts": false,
      "average_watts": 150.4,
      "average_heartrate": 182,
      "max_heartrate": 188,
      "segment": {
        "id": 19274076,
        "resource_state": 2,
        "name": "Mt Henry Sprint (South)",
        "activity_type": "Ride",
        "distance": 371.5,
        "average_grade": 2.1,
        "maximum_grade": 42.9,
        "elevation_high": 9.7,
        "elevation_low": 0.8,
        "start_latlng": [
          -32.029072,
          115.860377
        ],
        "end_latlng": [
          -32.036778,
          115.856415
        ],
        "start_latitude": -32.029072,
        "start_longitude": 115.860377,
        "end_latitude": -32.036778,
        "end_longitude": 115.856415,
        "climb_category": 0,
        "city": "Salter Point",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115353,
      "resource_state": 2,
      "name": "Bull Creek Underpass",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 288,
      "moving_time": 288,
      "start_date": "2019-05-21T08:52:05Z",
      "start_date_local": "2019-05-21T16:52:05Z",
      "distance": 1962.8,
      "start_index": 731,
      "end_index": 879,
      "device_watts": false,
      "average_watts": 142.2,
      "average_heartrate": 162.7,
      "max_heartrate": 176,
      "segment": {
        "id": 11575877,
        "resource_state": 2,
        "name": "Bull Creek Underpass",
        "activity_type": "Ride",
        "distance": 2022.7,
        "average_grade": 0.9,
        "maximum_grade": 6,
        "elevation_high": 21.6,
        "elevation_low": 1.3,
        "start_latlng": [
          -32.035475,
          115.857118
        ],
        "end_latlng": [
          -32.05165,
          115.852018
        ],
        "start_latitude": -32.035475,
        "start_longitude": 115.857118,
        "end_latitude": -32.05165,
        "end_longitude": 115.852018,
        "climb_category": 0,
        "city": "Mount Pleasant",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115276,
      "resource_state": 2,
      "name": "Cranford Climb",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 56,
      "moving_time": 56,
      "start_date": "2019-05-21T08:52:41Z",
      "start_date_local": "2019-05-21T16:52:41Z",
      "distance": 393.8,
      "start_index": 749,
      "end_index": 778,
      "device_watts": false,
      "average_watts": 117.2,
      "average_heartrate": 165.5,
      "max_heartrate": 169,
      "segment": {
        "id": 10433261,
        "resource_state": 2,
        "name": "Cranford Climb",
        "activity_type": "Ride",
        "distance": 388.6,
        "average_grade": 1.3,
        "maximum_grade": 4.5,
        "elevation_high": 8.4,
        "elevation_low": -0.2,
        "start_latlng": [
          -32.037418,
          115.855526
        ],
        "end_latlng": [
          -32.040803,
          115.854482
        ],
        "start_latitude": -32.037418,
        "start_longitude": 115.855526,
        "end_latitude": -32.040803,
        "end_longitude": 115.854482,
        "climb_category": 0,
        "city": "Salter Point",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115340,
      "resource_state": 2,
      "name": "Mt Henry Reserve to Leach",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 151,
      "moving_time": 151,
      "start_date": "2019-05-21T08:52:44Z",
      "start_date_local": "2019-05-21T16:52:44Z",
      "distance": 1052.8,
      "start_index": 750,
      "end_index": 829,
      "device_watts": false,
      "average_watts": 86.4,
      "average_heartrate": 161.5,
      "max_heartrate": 169,
      "segment": {
        "id": 16635340,
        "resource_state": 2,
        "name": "Mt Henry Reserve to Leach",
        "activity_type": "Ride",
        "distance": 1102.1,
        "average_grade": 0.4,
        "maximum_grade": 6.6,
        "elevation_high": 16.2,
        "elevation_low": 10.2,
        "start_latlng": [
          -32.037579,
          115.855564
        ],
        "end_latlng": [
          -32.046397,
          115.853965
        ],
        "start_latitude": -32.037579,
        "start_longitude": 115.855564,
        "end_latitude": -32.046397,
        "end_longitude": 115.853965,
        "climb_category": 0,
        "city": null,
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115323,
      "resource_state": 2,
      "name": "Selway Sprint - Don't forget to brake!",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 67,
      "moving_time": 67,
      "start_date": "2019-05-21T08:53:55Z",
      "start_date_local": "2019-05-21T16:53:55Z",
      "distance": 491.2,
      "start_index": 786,
      "end_index": 822,
      "device_watts": false,
      "average_watts": 67.4,
      "average_heartrate": 158.1,
      "max_heartrate": 164,
      "segment": {
        "id": 9186132,
        "resource_state": 2,
        "name": "Selway Sprint - Don't forget to brake!",
        "activity_type": "Ride",
        "distance": 498.6,
        "average_grade": -0.3,
        "maximum_grade": 3.6,
        "elevation_high": 15.9,
        "elevation_low": 12.6,
        "start_latlng": [
          -32.041242,
          115.854739
        ],
        "end_latlng": [
          -32.045574,
          115.853761
        ],
        "start_latitude": -32.041242,
        "start_longitude": 115.854739,
        "end_latitude": -32.045574,
        "end_longitude": 115.853761,
        "climb_category": 0,
        "city": "Mount Pleasant",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115287,
      "resource_state": 2,
      "name": "Sensible Selway Sprint",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 39,
      "moving_time": 39,
      "start_date": "2019-05-21T08:53:57Z",
      "start_date_local": "2019-05-21T16:53:57Z",
      "distance": 289.2,
      "start_index": 787,
      "end_index": 807,
      "device_watts": false,
      "average_watts": 103.1,
      "average_heartrate": 159.5,
      "max_heartrate": 164,
      "segment": {
        "id": 8935540,
        "resource_state": 2,
        "name": "Sensible Selway Sprint",
        "activity_type": "Ride",
        "distance": 304.4,
        "average_grade": -0.5,
        "maximum_grade": 3.1,
        "elevation_high": 1.6,
        "elevation_low": -0.4,
        "start_latlng": [
          -32.041319,
          115.854736
        ],
        "end_latlng": [
          -32.043991,
          115.854158
        ],
        "start_latitude": -32.041319,
        "start_longitude": 115.854736,
        "end_latitude": -32.043991,
        "end_longitude": 115.854158,
        "climb_category": 0,
        "city": "Brentwood",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115464,
      "resource_state": 2,
      "name": "Leach to South St ",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 357,
      "moving_time": 357,
      "start_date": "2019-05-21T08:55:13Z",
      "start_date_local": "2019-05-21T16:55:13Z",
      "distance": 2429.4,
      "start_index": 828,
      "end_index": 1007,
      "device_watts": false,
      "average_watts": 137.7,
      "average_heartrate": 160.4,
      "max_heartrate": 171,
      "segment": {
        "id": 5292547,
        "resource_state": 2,
        "name": "Leach to South St ",
        "activity_type": "Ride",
        "distance": 2433,
        "average_grade": 0.5,
        "maximum_grade": 5.5,
        "elevation_high": 28.1,
        "elevation_low": 9.4,
        "start_latlng": [
          -32.046326,
          115.854162
        ],
        "end_latlng": [
          -32.067211,
          115.849965
        ],
        "start_latitude": -32.046326,
        "start_longitude": 115.854162,
        "end_latitude": -32.067211,
        "end_longitude": 115.849965,
        "climb_category": 0,
        "city": "Bateman",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115402,
      "resource_state": 2,
      "name": "Leach to Parry Climb",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 190,
      "moving_time": 190,
      "start_date": "2019-05-21T08:55:15Z",
      "start_date_local": "2019-05-21T16:55:15Z",
      "distance": 1181.4,
      "start_index": 829,
      "end_index": 924,
      "device_watts": false,
      "average_watts": 139.8,
      "average_heartrate": 162.7,
      "max_heartrate": 171,
      "segment": {
        "id": 4110444,
        "resource_state": 2,
        "name": "Leach to Parry Climb",
        "activity_type": "Ride",
        "distance": 1168,
        "average_grade": 1.4,
        "maximum_grade": 7.5,
        "elevation_high": 21.8,
        "elevation_low": 3.4,
        "start_latlng": [
          -32.046468,
          115.853953
        ],
        "end_latlng": [
          -32.056554,
          115.851112
        ],
        "start_latitude": -32.046468,
        "start_longitude": 115.853953,
        "end_latitude": -32.056554,
        "end_longitude": 115.851112,
        "climb_category": 0,
        "city": "Bateman",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115364,
      "resource_state": 2,
      "name": "Arcacia Climb",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 122,
      "moving_time": 122,
      "start_date": "2019-05-21T08:56:07Z",
      "start_date_local": "2019-05-21T16:56:07Z",
      "distance": 670.5,
      "start_index": 857,
      "end_index": 916,
      "device_watts": false,
      "average_watts": 82.6,
      "average_heartrate": 166.1,
      "max_heartrate": 171,
      "segment": {
        "id": 14318846,
        "resource_state": 2,
        "name": "Arcacia Climb",
        "activity_type": "Ride",
        "distance": 662.1,
        "average_grade": 2.3,
        "maximum_grade": 6,
        "elevation_high": 3.2,
        "elevation_low": -12.4,
        "start_latlng": [
          -32.049721,
          115.852915
        ],
        "end_latlng": [
          -32.055429,
          115.85117
        ],
        "start_latitude": -32.049721,
        "start_longitude": 115.852915,
        "end_latitude": -32.055429,
        "end_longitude": 115.85117,
        "climb_category": 0,
        "city": "Bateman",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115511,
      "resource_state": 2,
      "name": "Horses, horses everywhere",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 172,
      "moving_time": 172,
      "start_date": "2019-05-21T09:03:47Z",
      "start_date_local": "2019-05-21T17:03:47Z",
      "distance": 1491,
      "start_index": 1078,
      "end_index": 1161,
      "device_watts": false,
      "average_watts": 148.7,
      "average_heartrate": 173.8,
      "max_heartrate": 181,
      "segment": {
        "id": 16407857,
        "resource_state": 2,
        "name": "Horses, horses everywhere",
        "activity_type": "Ride",
        "distance": 1486.2,
        "average_grade": 0,
        "maximum_grade": 3.1,
        "elevation_high": 21.2,
        "elevation_low": 15.6,
        "start_latlng": [
          -32.069992,
          115.842235
        ],
        "end_latlng": [
          -32.071449,
          115.827349
        ],
        "start_latitude": -32.069992,
        "start_longitude": 115.842235,
        "end_latitude": -32.071449,
        "end_longitude": 115.827349,
        "climb_category": 0,
        "city": "Murdoch",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": 1,
      "achievements": [{
        "type_id": 3,
        "type": "pr",
        "rank": 1
      }],
      "hidden": false
    },
    {
      "id": 60198115558,
      "resource_state": 2,
      "name": "Farro crest",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 149,
      "moving_time": 149,
      "start_date": "2019-05-21T09:07:21Z",
      "start_date_local": "2019-05-21T17:07:21Z",
      "distance": 866.9,
      "start_index": 1185,
      "end_index": 1261,
      "device_watts": false,
      "average_watts": 128.8,
      "average_heartrate": 150.8,
      "max_heartrate": 166,
      "segment": {
        "id": 17506564,
        "resource_state": 2,
        "name": "Farro crest",
        "activity_type": "Ride",
        "distance": 895.3,
        "average_grade": 1.4,
        "maximum_grade": 7.3,
        "elevation_high": 35.6,
        "elevation_low": 21,
        "start_latlng": [
          -32.074132,
          115.826558
        ],
        "end_latlng": [
          -32.076985,
          115.818466
        ],
        "start_latitude": -32.074132,
        "start_longitude": 115.826558,
        "end_latitude": -32.076985,
        "end_longitude": 115.818466,
        "climb_category": 0,
        "city": "Murdoch",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115530,
      "resource_state": 2,
      "name": "Farro Drag",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 28,
      "moving_time": 28,
      "start_date": "2019-05-21T09:07:58Z",
      "start_date_local": "2019-05-21T17:07:58Z",
      "distance": 207.7,
      "start_index": 1206,
      "end_index": 1221,
      "device_watts": false,
      "average_watts": 120,
      "average_heartrate": 145.1,
      "max_heartrate": 149,
      "segment": {
        "id": 8043056,
        "resource_state": 2,
        "name": "Farro Drag",
        "activity_type": "Ride",
        "distance": 230.9,
        "average_grade": -1,
        "maximum_grade": 0,
        "elevation_high": 1.6,
        "elevation_low": -0.6,
        "start_latlng": [
          -32.073366,
          115.824055
        ],
        "end_latlng": [
          -32.074262,
          115.822112
        ],
        "start_latitude": -32.073366,
        "start_longitude": 115.824055,
        "end_latitude": -32.074262,
        "end_longitude": 115.822112,
        "climb_category": 0,
        "city": "Kardinya",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115546,
      "resource_state": 2,
      "name": "real farro drag",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 42,
      "moving_time": 42,
      "start_date": "2019-05-21T09:08:00Z",
      "start_date_local": "2019-05-21T17:08:00Z",
      "distance": 305.9,
      "start_index": 1207,
      "end_index": 1229,
      "device_watts": false,
      "average_watts": 124.4,
      "average_heartrate": 147.1,
      "max_heartrate": 152,
      "segment": {
        "id": 8855624,
        "resource_state": 2,
        "name": "real farro drag",
        "activity_type": "Ride",
        "distance": 285.7,
        "average_grade": 3.3,
        "maximum_grade": 7.5,
        "elevation_high": 25.1,
        "elevation_low": 15.7,
        "start_latlng": [
          -32.073874,
          115.823971
        ],
        "end_latlng": [
          -32.074975,
          115.821315
        ],
        "start_latitude": -32.073874,
        "start_longitude": 115.823971,
        "end_latitude": -32.074975,
        "end_longitude": 115.821315,
        "climb_category": 0,
        "city": "North Lake",
        "state": "Western Australia",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115572,
      "resource_state": 2,
      "name": "Winterfold Wonderland",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 191,
      "moving_time": 191,
      "start_date": "2019-05-21T09:10:16Z",
      "start_date_local": "2019-05-21T17:10:16Z",
      "distance": 658,
      "start_index": 1275,
      "end_index": 1361,
      "device_watts": false,
      "average_watts": 125.9,
      "average_heartrate": 158.6,
      "max_heartrate": 170,
      "segment": {
        "id": 3320901,
        "resource_state": 2,
        "name": "Winterfold Wonderland",
        "activity_type": "Ride",
        "distance": 661.828,
        "average_grade": 4.9,
        "maximum_grade": 8.2,
        "elevation_high": 65.3,
        "elevation_low": 32.7,
        "start_latlng": [
          -32.077321741092454,
          115.81715636189281
        ],
        "end_latlng": [
          -32.07468854663976,
          115.812117653358
        ],
        "start_latitude": -32.077321741092454,
        "start_longitude": 115.81715636189281,
        "end_latitude": -32.07468854663976,
        "end_longitude": 115.812117653358,
        "climb_category": 0,
        "city": "North Lake",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115610,
      "resource_state": 2,
      "name": "Winterfold Breakaway",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 354,
      "moving_time": 354,
      "start_date": "2019-05-21T09:11:40Z",
      "start_date_local": "2019-05-21T17:11:40Z",
      "distance": 2029.5,
      "start_index": 1310,
      "end_index": 1482,
      "device_watts": false,
      "average_watts": 121.7,
      "average_heartrate": 153.1,
      "max_heartrate": 169,
      "segment": {
        "id": 734512,
        "resource_state": 2,
        "name": "Winterfold Breakaway",
        "activity_type": "Ride",
        "distance": 2055.32,
        "average_grade": 1.2,
        "maximum_grade": 8.3,
        "elevation_high": 60.2,
        "elevation_low": 32.8,
        "start_latlng": [
          -32.075027944520116,
          115.81584267318249
        ],
        "end_latlng": [
          -32.07480029202998,
          115.79439262859523
        ],
        "start_latitude": -32.075027944520116,
        "start_longitude": 115.81584267318249,
        "end_latitude": -32.07480029202998,
        "end_longitude": 115.79439262859523,
        "climb_category": 0,
        "city": "North Lake",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    },
    {
      "id": 60198115594,
      "resource_state": 2,
      "name": "Winter whining",
      "activity": {
        "id": 2384621287,
        "resource_state": 1
      },
      "athlete": {
        "id": 19454260,
        "resource_state": 1
      },
      "elapsed_time": 134,
      "moving_time": 134,
      "start_date": "2019-05-21T09:11:58Z",
      "start_date_local": "2019-05-21T17:11:58Z",
      "distance": 529.3,
      "start_index": 1317,
      "end_index": 1381,
      "device_watts": false,
      "average_watts": 127,
      "average_heartrate": 158,
      "max_heartrate": 169,
      "segment": {
        "id": 6807224,
        "resource_state": 2,
        "name": "Winter whining",
        "activity_type": "Ride",
        "distance": 530.2,
        "average_grade": 3.5,
        "maximum_grade": 8.6,
        "elevation_high": 65.3,
        "elevation_low": 42.7,
        "start_latlng": [
          -32.074661,
          115.815359
        ],
        "end_latlng": [
          -32.074708,
          115.809764
        ],
        "start_latitude": -32.074661,
        "start_longitude": 115.815359,
        "end_latitude": -32.074708,
        "end_longitude": 115.809764,
        "climb_category": 0,
        "city": "Kardinya",
        "state": "WA",
        "country": "Australia",
        "private": false,
        "hazardous": false,
        "starred": false
      },
      "kom_rank": null,
      "pr_rank": null,
      "achievements": [],
      "hidden": false
    }
  ],
  "splits_metric": [{
      "distance": 1003.8,
      "elapsed_time": 368,
      "elevation_difference": -30.7,
      "moving_time": 220,
      "split": 1,
      "average_speed": 4.56,
      "average_heartrate": 71.94545454545455,
      "pace_zone": 0
    },
    {
      "distance": 1000.8,
      "elapsed_time": 149,
      "elevation_difference": 6.5,
      "moving_time": 149,
      "split": 2,
      "average_speed": 6.72,
      "average_heartrate": 119.6241610738255,
      "pace_zone": 0
    },
    {
      "distance": 1000.1,
      "elapsed_time": 146,
      "elevation_difference": 2.8,
      "moving_time": 146,
      "split": 3,
      "average_speed": 6.85,
      "average_heartrate": 150.37671232876713,
      "pace_zone": 0
    },
    {
      "distance": 998.9,
      "elapsed_time": 166,
      "elevation_difference": -7.1,
      "moving_time": 166,
      "split": 4,
      "average_speed": 6.02,
      "average_heartrate": 150.86363636363637,
      "pace_zone": 0
    },
    {
      "distance": 1007.4,
      "elapsed_time": 127,
      "elevation_difference": -3.4,
      "moving_time": 127,
      "split": 5,
      "average_speed": 7.93,
      "average_heartrate": 159,
      "pace_zone": 0
    },
    {
      "distance": 998.5,
      "elapsed_time": 122,
      "elevation_difference": 4.6,
      "moving_time": 122,
      "split": 6,
      "average_speed": 8.18,
      "average_heartrate": 167.45081967213116,
      "pace_zone": 0
    },
    {
      "distance": 1000.7,
      "elapsed_time": 140,
      "elevation_difference": -4.2,
      "moving_time": 140,
      "split": 7,
      "average_speed": 7.15,
      "average_heartrate": 170.22857142857143,
      "pace_zone": 0
    },
    {
      "distance": 1000.5,
      "elapsed_time": 114,
      "elevation_difference": 2.8,
      "moving_time": 114,
      "split": 8,
      "average_speed": 8.78,
      "average_heartrate": 174.9298245614035,
      "pace_zone": 0
    },
    {
      "distance": 992.2,
      "elapsed_time": 111,
      "elevation_difference": 1.9,
      "moving_time": 111,
      "split": 9,
      "average_speed": 8.94,
      "average_heartrate": 178.93693693693695,
      "pace_zone": 0
    },
    {
      "distance": 1010.8,
      "elapsed_time": 112,
      "elevation_difference": -3.3,
      "moving_time": 112,
      "split": 10,
      "average_speed": 9.03,
      "average_heartrate": 184.83928571428572,
      "pace_zone": 0
    },
    {
      "distance": 990,
      "elapsed_time": 135,
      "elevation_difference": -1.5,
      "moving_time": 135,
      "split": 11,
      "average_speed": 7.33,
      "average_heartrate": 183,
      "pace_zone": 0
    },
    {
      "distance": 999.5,
      "elapsed_time": 146,
      "elevation_difference": 10.3,
      "moving_time": 146,
      "split": 12,
      "average_speed": 6.85,
      "average_heartrate": 164.65068493150685,
      "pace_zone": 0
    },
    {
      "distance": 998.5,
      "elapsed_time": 153,
      "elevation_difference": 5.4,
      "moving_time": 153,
      "split": 13,
      "average_speed": 6.53,
      "average_heartrate": 160.98692810457516,
      "pace_zone": 0
    },
    {
      "distance": 1011.7,
      "elapsed_time": 147,
      "elevation_difference": 4.8,
      "moving_time": 147,
      "split": 14,
      "average_speed": 6.88,
      "average_heartrate": 159.42857142857142,
      "pace_zone": 0
    },
    {
      "distance": 1006.2,
      "elapsed_time": 158,
      "elevation_difference": 6.1,
      "moving_time": 158,
      "split": 15,
      "average_speed": 6.37,
      "average_heartrate": 157.58227848101265,
      "pace_zone": 0
    },
    {
      "distance": 993.5,
      "elapsed_time": 126,
      "elevation_difference": 2.3,
      "moving_time": 126,
      "split": 16,
      "average_speed": 7.88,
      "average_heartrate": 158.85714285714286,
      "pace_zone": 0
    },
    {
      "distance": 996.5,
      "elapsed_time": 119,
      "elevation_difference": -2.7,
      "moving_time": 119,
      "split": 17,
      "average_speed": 8.37,
      "average_heartrate": 176.52100840336135,
      "pace_zone": 0
    },
    {
      "distance": 990.7,
      "elapsed_time": 142,
      "elevation_difference": -4.9,
      "moving_time": 142,
      "split": 18,
      "average_speed": 6.98,
      "average_heartrate": 155.7605633802817,
      "pace_zone": 0
    },
    {
      "distance": 1004.6,
      "elapsed_time": 259,
      "elevation_difference": 37.2,
      "moving_time": 259,
      "split": 19,
      "average_speed": 3.88,
      "average_heartrate": 157.94980694980694,
      "pace_zone": 0
    },
    {
      "distance": 1000.7,
      "elapsed_time": 141,
      "elevation_difference": -17.9,
      "moving_time": 141,
      "split": 20,
      "average_speed": 7.1,
      "average_heartrate": 153.4964539007092,
      "pace_zone": 0
    },
    {
      "distance": 1000.4,
      "elapsed_time": 180,
      "elevation_difference": 4.8,
      "moving_time": 165,
      "split": 21,
      "average_speed": 6.06,
      "average_heartrate": 152.86666666666667,
      "pace_zone": 0
    },
    {
      "distance": 869.2,
      "elapsed_time": 131,
      "elevation_difference": -4.9,
      "moving_time": 131,
      "split": 22,
      "average_speed": 6.64,
      "average_heartrate": 157.4198473282443,
      "pace_zone": 0
    }
  ],
  "splits_standard": [{
      "distance": 1625.1,
      "elapsed_time": 450,
      "elevation_difference": -31.1,
      "moving_time": 302,
      "split": 1,
      "average_speed": 5.38,
      "average_heartrate": 78.20860927152317,
      "pace_zone": 0
    },
    {
      "distance": 1605.5,
      "elapsed_time": 281,
      "elevation_difference": 1.6,
      "moving_time": 281,
      "split": 2,
      "average_speed": 5.71,
      "average_heartrate": 149.34200743494424,
      "pace_zone": 0
    },
    {
      "distance": 1598.9,
      "elapsed_time": 204,
      "elevation_difference": -0.9,
      "moving_time": 204,
      "split": 3,
      "average_speed": 7.84,
      "average_heartrate": 156.34803921568627,
      "pace_zone": 0
    },
    {
      "distance": 1611.1,
      "elapsed_time": 193,
      "elevation_difference": 2.3,
      "moving_time": 193,
      "split": 4,
      "average_speed": 8.35,
      "average_heartrate": 168.05181347150258,
      "pace_zone": 0
    },
    {
      "distance": 1614,
      "elapsed_time": 209,
      "elevation_difference": 0.2,
      "moving_time": 209,
      "split": 5,
      "average_speed": 7.72,
      "average_heartrate": 172.29186602870814,
      "pace_zone": 0
    },
    {
      "distance": 1614.2,
      "elapsed_time": 180,
      "elevation_difference": -4.8,
      "moving_time": 180,
      "split": 6,
      "average_speed": 8.97,
      "average_heartrate": 180.42777777777778,
      "pace_zone": 0
    },
    {
      "distance": 1608.1,
      "elapsed_time": 210,
      "elevation_difference": 9.8,
      "moving_time": 210,
      "split": 7,
      "average_speed": 7.66,
      "average_heartrate": 181.63333333333333,
      "pace_zone": 0
    },
    {
      "distance": 1604.4,
      "elapsed_time": 240,
      "elevation_difference": 7,
      "moving_time": 240,
      "split": 8,
      "average_speed": 6.69,
      "average_heartrate": 161.18333333333334,
      "pace_zone": 0
    },
    {
      "distance": 1615,
      "elapsed_time": 232,
      "elevation_difference": 7.8,
      "moving_time": 232,
      "split": 9,
      "average_speed": 6.96,
      "average_heartrate": 160.5043103448276,
      "pace_zone": 0
    },
    {
      "distance": 1605.4,
      "elapsed_time": 230,
      "elevation_difference": 3.5,
      "moving_time": 230,
      "split": 10,
      "average_speed": 6.98,
      "average_heartrate": 158.35652173913044,
      "pace_zone": 0
    },
    {
      "distance": 1609.8,
      "elapsed_time": 211,
      "elevation_difference": -8,
      "moving_time": 211,
      "split": 11,
      "average_speed": 7.63,
      "average_heartrate": 168.13744075829385,
      "pace_zone": 0
    },
    {
      "distance": 1603.5,
      "elapsed_time": 368,
      "elevation_difference": 41.9,
      "moving_time": 368,
      "split": 12,
      "average_speed": 4.36,
      "average_heartrate": 157.59782608695653,
      "pace_zone": 0
    },
    {
      "distance": 1616.5,
      "elapsed_time": 243,
      "elevation_difference": -15.5,
      "moving_time": 228,
      "split": 13,
      "average_speed": 7.09,
      "average_heartrate": 150.94736842105263,
      "pace_zone": 0
    },
    {
      "distance": 943.7,
      "elapsed_time": 141,
      "elevation_difference": -4.9,
      "moving_time": 141,
      "split": 14,
      "average_speed": 6.69,
      "average_heartrate": 156.4822695035461,
      "pace_zone": 0
    }
  ],
  "laps": [{
    "id": 7816378542,
    "resource_state": 2,
    "name": "Lap 1",
    "activity": {
      "id": 2384621287,
      "resource_state": 1
    },
    "athlete": {
      "id": 19454260,
      "resource_state": 1
    },
    "elapsed_time": 3392,
    "moving_time": 3229,
    "start_date": "2019-05-21T08:24:06Z",
    "start_date_local": "2019-05-21T16:24:06Z",
    "distance": 21875.2,
    "start_index": 0,
    "end_index": 1520,
    "total_elevation_gain": 281.3,
    "average_speed": 6.45,
    "max_speed": 10.9,
    "device_watts": false,
    "average_watts": 132.4,
    "average_heartrate": 154.5,
    "max_heartrate": 190,
    "lap_index": 1,
    "split": 1
  }],
  "gear": {
    "id": "b3541914",
    "primary": true,
    "name": "Cannondale Caad8",
    "resource_state": 2,
    "distance": 8779597
  },
  "photos": {
    "primary": null,
    "count": 0
  },
  "device_name": "Android Wear",
  "embed_token": "8552268ead1738d0942c320ea0def0b5dc6cc05f",
  "available_zones": []
}

export default ride;