import React, { useEffect, useState } from 'react';
import Modal from '../modal';
import styles from './survey-modal.module.scss';
import classnames from 'classnames';
import LoadingCircle from '../loading-circle';
import { PieChart } from '../pie-chart';
import image from '../../assets/images/feature-implemented.png';
import { DonateButton } from '../donate-button';
import { SETTINGS } from '../../config';
import Button from '../button';

export const SurveyModal = props => {
  let id = props.src;
  if (props.implemented) {
    id = `${props.src}&implemented`;
  } else if (props.results) {
    id = `${props.src}&complete`;
  }
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [donating, setDonating] = useState(false);
  const seen = localStorage.getItem(id);
  let showTimeout = null;
  let closeTimeout = null;
  if (!seen) {
    localStorage.setItem(id, 1);
  }

  useEffect(() => {
    if (props.prev) {
      localStorage.removeItem(props.prev);
      localStorage.removeItem(`${props.prev}&implemented`);
      localStorage.removeItem(`${props.prev}&complete`);
    }
    if (seen === String(props.afterVisits)) {
      showTimeout = setTimeout(() => {
        setOpen(true);
      }, props.showAfter || 1000);
    } else if (seen && seen !== 'true') {
      localStorage.setItem(id, Number(seen) + 1);
    }
    if (props.results) {
      setTimeout(() => {
        onLoad();
      }, 1000);
    }
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(closeTimeout);
    };
  }, []);

  const onLoad = () => {
    setLoaded(true);
  };

  const onClose = () => {
    closeTimeout = setTimeout(() => {
      setDonating(false);
      localStorage.setItem(id, true);
      setOpen(false);
    }, SETTINGS.modalSpeed);
  };

  const onDonate = () => {
    setDonating(true);
  };

  const getWinner = () => {
    if (props.results) {
      const sorted = props.results.sort((a, b) => {
        return b.value - a.value;
      });
      return sorted[0];
    }
    return null;
  };

  const renderModalContents = () => {
    if (props.implemented) {
      return (
        <div className={styles.implemented}>
          <img src={`${image}?${props.src}`} alt={props.title} />
          <div className={styles.implementedInfo}>
            <p>{props.implemented.description}</p>
            {props.donateButton && (
              <div onClick={onDonate}>
                <DonateButton info={false} light onClose={onClose} onSuccess={onClose} />
              </div>
            )}
            {props.actionButton && (
              <Button light href={props.actionButton.link} onClick={onClose}>
                {props.actionButton.text}
              </Button>
            )}
          </div>
        </div>
      );
    } else if (props.results) {
      const winner = getWinner();
      return (
        <div className={styles.results}>
          <p>
            The results are in. Thank you for your input. It was close but but the users have spoken and {winner.key}{' '}
            will be Headwinds next feature.
          </p>
          <PieChart data={props.results} legend width={250} height={100} />
          <small>And remember, those who donated will be the first to try out the new feature!</small>
        </div>
      );
    } else {
      return <iframe className={styles.iframe} onLoad={onLoad} title={props.title} src={props.src} seamless />;
    }
  };

  if (!props.src) {
    return null;
  } else if (seen === String(props.afterVisits)) {
    const classNames = classnames({
      [styles.iframeHolder]: !props.results,
      [styles.loaded]: loaded,
    });
    return (
      <Modal
        open={open}
        title={props.title}
        width={500}
        onClose={onClose}
        className={donating ? styles.donating : undefined}
        overlayClassName={donating ? styles.donatingOverlay : undefined}
      >
        {!loaded && <LoadingCircle className={styles.loading} />}
        <div className={classNames}>{renderModalContents()}</div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default SurveyModal;
