import React, { Component } from 'react';
import styles from './route-mask.module.scss';

class RouteMask extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.ctx = null;
    this.width = 0;
    this.height = 0;
    this.showSpeed = 1000;
    this.hideSpeed = 50;
    this.max = 0;
    this.radius = 0;
    this.paused = false;
    this.state = {
      loading: this.props.loading,
    };
  }

  componentDidMount() {
    this.ctx = this.canvasRef.current.getContext('2d');
    this.calculateSettings();
    this.ctx.fillStyle = this.props.color === 'light' ? '#fff' : '#000';
    this.ctx.strokeStyle = this.props.color === 'light' ? '#000' : '#fff';
    this.tick();
    window.addEventListener('resize', this.resize);
  }

  componentWillReceiveProps(props) {
    this.setState({ loading: props.loading });
    this.go();
  }

  componentWillUnmount() {
    this.stop();
    window.removeEventListener('resize', this.resize);
    this.requestAnimationFrameID && window.cancelAnimationFrame(this.requestAnimationFrameID);
  }

  calculateSettings() {
    this.canvasRef.current.width = window.innerWidth;
    this.canvasRef.current.height = window.innerHeight;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.max = this.height / 2 + this.height / 4;
    this.radius = this.state.show ? 0 : this.max;
  }

  tick = () => {
    if (!this.paused) {
      this.update();
    }
    this.requestAnimationFrameID = window.requestAnimationFrame(this.tick);
  };

  update() {
    if (this.state.loading) {
      this.showMask();
    } else {
      this.hideMask();
    }
  }

  showMask() {
    this.radius -= this.showSpeed;
    if (this.radius < 0) {
      this.radius = 0;
      this.stop();
    }
    this.drawMask();
  }

  hideMask() {
    this.radius += this.hideSpeed;
    if (this.radius > this.max) {
      this.radius = this.max;
      this.stop();
    }
    this.drawMask();
  }

  drawMask() {
    this.radius = Math.round(this.radius);
    this.clear();
    this.fill();
    this.ctx.beginPath();
    this.ctx.arc(this.width / 2, this.height / 2, this.radius, 0, 2 * Math.PI);
    this.ctx.closePath();
    // this.ctx.stroke();
    this.ctx.save();
    this.ctx.clip();
    this.clear();
    this.ctx.restore();
  }

  clear() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  fill() {
    this.ctx.fillRect(0, 0, this.width, this.height);
  }

  stop() {
    this.paused = true;
  }

  go() {
    this.paused = false;
  }

  resize = () => {
    this.calculateSettings();
  };

  render() {
    return <canvas className={styles.routeMask} ref={this.canvasRef} />;
  }
}

export default RouteMask;
