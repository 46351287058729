import {
  createStore,
  combineReducers
} from 'redux';
import MessageReducer from '../components/message/reducer';
import StravaReducer from '../api/strava/reducer';
import HeaderReducer from '../components/header/reducer';
import MenuReducer from '../components/menu/reducer';
import MapReducer from '../components/map/reducer';
import WindMapReducer from '../components/wind-map/reducer';
import GlobalReducer from './reducer';

const rootReducer = combineReducers({
  messages: MessageReducer,
  strava: StravaReducer,
  header: HeaderReducer,
  menu: MenuReducer,
  map: MapReducer,
  windMap: WindMapReducer,
  global: GlobalReducer
});

const store = createStore(rootReducer);

export default store;